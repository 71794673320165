import Axios from 'Api/interceptors'

export class RoleService {
	async getAllRoles() {
		let result
		try {
			result = await Axios.get('/roles')
		} catch (error) {
			result = error
		}
		return result
	}
	async getOneRole(roleId) {
		let result
		try {
			result = await Axios.get('/roles/' + roleId)
		} catch (error) {
			result = error
		}

		return result
	}
	async createRole(payload) {
		let result
		try {
			result = await Axios.post('/roles', payload)
		} catch (error) {
			result = error
		}

		return result
	}

	async updateRole(formData, roleId) {
		let result
		try {
			result = await Axios.put('/roles/' + roleId, formData)
		} catch (error) {
			result = error
		}
		return result
	}
	async deleteRole(roleId) {
		let result
		try {
			result = await Axios.delete('/roles/' + roleId)
		} catch (error) {
			result = error
		}
		return result
	}
}
