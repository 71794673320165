import { Box, Tooltip } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import PictureModal from 'components/modals/PictureModal'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import GeneralPagination from 'components/pagination'
import BasicTable from 'components/basicTable'
import { useQueryClient } from '@tanstack/react-query'
import { modalIconSwitcher } from 'utils/wordingOrIconsSwitcher'
import { PrintTagService } from 'Api/printTag/printTagService'
import { FaPen } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import ReusedModal from './components/ReusedModal'

export default function TableContent({ data = null, isFetching = false, setPage }) {
	const [reuseModalState, setReuseModalState] = useState({ type: '', id: '' })
	const history = useHistory()
	const queryClient = useQueryClient()
	const search = history.location.search
	const params = new URLSearchParams(search)
	const fromProductId = params.get('from')

	const tableData = data?.data?.response?.data || []
	const currentPage = data?.data?.response?.page || 1
	const maxPage = data?.data?.response?.total_page || 1

	// 當從商品內容頁面按返回回來時，會自動滾動到該商品，並且highlight該商品
	// 然後clear掉fromProductId
	useEffect(() => {
		// 因為按下儲存的時候，會refetch list，所以要等fetch完才能執行scrollIntoView
		if (tableData.length === 0 || !fromProductId || isFetching) {
			return
		}

		const target = document.querySelector(`div[data-id="${fromProductId}"]`)?.parentElement?.parentElement

		if (target) {
			target.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			})

			Array.from(target.children).forEach((child) => {
				child.animate(
					[
						{
							background: 'rgba(235, 145, 145, 0.2)',
						},
						{ background: 'none' },
					],
					{
						duration: 1000,
						iterations: 2,
					}
				)
			})
		}

		history.replace({ search: '' })
	}, [tableData.length, fromProductId, history, isFetching])

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'no',
				Cell: (row) => {
					return <Box data-id={row.row?.original?.id}>{row.value}</Box>
				},
			},
			{
				Header: () => (
					<Box display="flex" alignItems="center" justifyContent="center">
						<Box>品名</Box>
						<Tooltip label="點擊商品可看商品圖片">
							<span>
								<AiOutlineInfoCircle />
							</span>
						</Tooltip>
					</Box>
				),
				accessor: 'name',
				Cell: (row) => {
					return <PictureModal imageUrl={row.row.original.image} text={row.value} />
				},
			},
			{ Header: '品牌名稱', accessor: 'brand_name' },
			{
				Header: '位置',
				accessor: 'location',
				disableGlobalFilter: true,
				Cell: (row) => (row.value ? row.value : '未設定'),
			},

			{
				Header: '商品狀態',
				accessor: 'status',
				Cell: (row) => (row.value === '已售出' ? <Box color="rgb(235, 145, 145)">{row.value}</Box> : row.value),
			},
			{
				Header: '商品類型',
				accessor: 'type',
				disableGlobalFilter: true,
				Cell: (row) => {
					const isBuyOutProduct = row.value === '買斷'
					return (
						<Box fontWeight={isBuyOutProduct && 'bold'} color={isBuyOutProduct && 'rgb(235, 145, 145)'}>
							{row.value}
						</Box>
					)
				},
			},
			{
				Header: '進價',
				accessor: 'price',
				disableGlobalFilter: true,
			},
			{
				Header: '售價',
				accessor: 'sale_price',
				disableGlobalFilter: true,
				Cell: (row) => (row.value === '未訂價' ? <Box color="rgb(235, 145, 145)">{row.value}</Box> : row.value),
			},
			{
				Header: '內容管理',
				accessor: 'management',
				disableGlobalFilter: true,
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around" fontSize="1.1rem" flexWrap="wrap">
							<Tooltip label="進價功能">
								<Box
									color="#91B0EB"
									cursor="pointer"
									onClick={() => {
										handleOpenReuseModal({ type: '進價', id: row.row.original.id })
									}}
									m="0.3rem"
								>
									{modalIconSwitcher('進價')}
								</Box>
							</Tooltip>
							<Tooltip label="庫位設定">
								<Box
									color="#91B0EB"
									cursor="pointer"
									onClick={() => {
										handleOpenReuseModal({ type: '庫位', id: row.row.original.id })
									}}
									m="0.3rem"
								>
									{modalIconSwitcher('庫位')}
								</Box>
							</Tooltip>
							<Tooltip label="定價功能">
								<Box
									color="#91B0EB"
									cursor="pointer"
									onClick={() => {
										handleOpenReuseModal({ type: '定價', id: row.row.original.id })
									}}
									m="0.3rem"
								>
									{modalIconSwitcher('定價')}
								</Box>
							</Tooltip>
							<Tooltip label="列印標籤">
								<Box
									color="#91B0EB"
									cursor="pointer"
									onClick={async function printTag() {
										const { id } = row.row.original
										const printTagService = new PrintTagService()
										await printTagService.printTag(id)
									}}
									m="0.3rem"
								>
									{modalIconSwitcher('標籤')}
								</Box>
							</Tooltip>

							<Tooltip label="保留商品">
								<Box
									color={row.row.original.reserve_status ? 'rgb(235, 145, 145)' : '#91B0EB'}
									cursor="pointer"
									onClick={() => {
										handleOpenReuseModal({
											type: row.row.original.reserve_status ? '取消保留' : '保留',
											id: row.row.original.id,
											reserve_reason: row.row.original.reserve_reason,
											reserve_status: row.row.original.reserve_status,
											no: row.row.original.no,
											name: row.row.original.name,
										})
									}}
									m="0.3rem"
								>
									{modalIconSwitcher('保留')}
								</Box>
							</Tooltip>
							<Tooltip label="設定商品">
								<Box
									m="0.3rem"
									color="#91B0EB"
									cursor="pointer"
									onClick={() => {
										history.push(`/admin/stock/merchant/${row.row.original.id}`)
									}}
								>
									<FaPen />
								</Box>
							</Tooltip>
						</Box>
					)
				},
			},
		],
		[history]
	)

	function handleOpenReuseModal(payload) {
		setReuseModalState(payload)
	}

	function refetchList() {
		queryClient.invalidateQueries({ queryKey: ['getProductList'] })

		// reset reuseModalState after refetchList
		resetModal()
	}

	const resetModal = useCallback(() => {
		setReuseModalState({ type: '', id: '' })
	}, [])

	// 因為商品table最多允許一次顯示50筆
	const MAXIMUM_PAGE_SIZE = 50

	return (
		<>
			<ReusedModal resetModal={resetModal} reuseModalState={reuseModalState} refetchList={refetchList} />
			<BasicTable columns={columns} tableData={tableData} pageSize={MAXIMUM_PAGE_SIZE} isLoading={isFetching} />
			<GeneralPagination
				defaultCurrent={currentPage}
				total={maxPage * 10}
				onChange={(page) => {
					setPage(() => page)
				}}
			/>
		</>
	)
}
