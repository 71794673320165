import React, { useContext } from 'react'
import { Box } from '@chakra-ui/react'
import styled from 'styled-components'
import { paymentListContext } from './useContext/createContext'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { MdPrint } from 'react-icons/md'
import SearchPaymentListForm from './component/SearchPaymentListForm'
import SearchVendorListForm from './component/SearchVendorListForm'
const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	border-radius: 5px;
	align-items: stretch;
	margin-bottom: 1em;
	position: relative;
	overflow: visible;
	padding-right: 1em;
	flex-wrap: wrap;
	@media print {
		display: none;
	}
`

const StyledTitle = styled(Box)`
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20px;
		top: 0;
	}
`

export default function TableTopBar() {
	const { setEditState, editState, setProductListIsNeedFetch } = useContext(paymentListContext)

	return (
		<StyledTopbar className="topbar">
			<StyledTitle fontSize="1.25em" padding=".5em 1em" fontWeight="700" d="flex" alignItems="flex-start">
				付款單管理
				{editState.editType === 'create' ? (
					<Box d="flex" alignItems="flex-start">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						新增付款單
					</Box>
				) : null}
				{editState.editType === 'print' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						付款單詳情
					</Box>
				) : null}
			</StyledTitle>
			{editState.editType === 'viewList' ? <SearchPaymentListForm setEditState={setEditState} setProductListIsNeedFetch={setProductListIsNeedFetch} /> : null}
			{editState.editType === 'create' ? <SearchVendorListForm setEditState={setEditState} /> : null}
			{editState.editType === 'print' ? (
				<Box d="flex" alignItems="center">
					<StyledButtonBox onClick={() => window.print()}>
						<MdPrint /> 列印
					</StyledButtonBox>
				</Box>
			) : null}
		</StyledTopbar>
	)
}
