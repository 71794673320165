import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Pagination from 'rc-pagination'
import { Box } from '@chakra-ui/react'
import rightArrowIcon from 'assets/images/right-arrow.svg'
import leftArrowIcon from 'assets/images/left-arrow.svg'
import ProductItem from 'components/vendorPage/ProductItem'

const StyledBox = styled(Box)`
	ul {
		list-style: none;
		display: flex;
		margin: 0rem 0;
		padding: 0;
		justify-content: center;

		li {
			color: #2250a6;
			font-size: 1.3rem;
			cursor: pointer;

			&.rc-pagination-item-active {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.rc-pagination-prev,
		.rc-pagination-next {
			width: 30px;
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
		}

		input {
			text-align: center;
		}

		.rc-pagination-prev {
			background-image: url(${leftArrowIcon});
		}

		.rc-pagination-slash {
			margin: 0 10px;
		}

		.rc-pagination-next {
			background-image: url(${rightArrowIcon});
		}
	}
`

const renderProductItem = (products, currentPage, maxPage) => {
	return (
		<Box height="350px" overflowY="scroll" py="1em">
			{products && products.length > 0 ? products.map((item, i) => <ProductItem key={i} productInfo={item} />) : null}
			{currentPage === maxPage || maxPage === 0 ? (
				<Box color="#839BBE" textAlign="center">
					已無商品
				</Box>
			) : (
				<Box color="#839BBE" textAlign="center">
					請點擊下一頁
				</Box>
			)}
		</Box>
	)
}

export default function ProductItemContainer ({ productDataList, pageSettings }){
  const { defaultCurrenyPage, pageSize,  } = pageSettings
  const [ productsData, setProductsData ] = useState(productDataList)
  const [ tablePage, setTablePage ] = useState({ currentPage: 1, maxPage: Math.ceil(productDataList.length / pageSize) })
	const { currentPage, maxPage } = tablePage


  useEffect(() => {
		const renderData = []
		for (let i = (currentPage - 1) * pageSize; i < currentPage * pageSize; i++) {
			if (i >= productDataList.length) break
			renderData.push(productDataList[i])
		}
		setProductsData(renderData)
	}, [currentPage, pageSize, productDataList])

  return(
    <>
      {renderProductItem(productsData, currentPage, maxPage)}
      <StyledBox>
        <Pagination
          simple
          defaultCurrent={defaultCurrenyPage}
          total={maxPage * pageSize}
          pageSize={pageSize}
          onChange={(page) => setTablePage((pre) => (pre = { ...pre, currentPage: page }))}
        />
      </StyledBox>
  </>

  )
}