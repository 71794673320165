import { Box, FormLabel, Text, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import AddProductForm from './addProductForm'
import styled from 'styled-components'
import { getCookie } from 'utils/cookies'
import { useRouteMatch, useHistory } from 'react-router-dom'
import BasicTable from 'components/basicTable'
import GeneralPagination from 'components/pagination'
import { getInventorySheetDetail } from 'Api/inventory'
import { useQuery } from '@tanstack/react-query'
import PictureModal from 'components/modals/PictureModal'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { StyledButtonBox } from 'utils/StyledButton'

const StyledBox = styled(Box)`
	border-bottom: 1px solid;
	height: 1.8em;
	min-width: 160px;
	padding: 0 1em;
	border-color: black;
`

function EditInventoryPage() {
	const matchEditPage = useRouteMatch('/admin/inventory/inventorySheet/edit/:id')
	const sheetId = matchEditPage?.params?.id
	const [page, setPage] = React.useState(1)
	const { data, isFetching } = useQuery(['getInventorySheetDetail'], { queryFn: getInventorySheetDetail(sheetId) })
	const [productDataOnScanned, setProductDataOnScanned] = useState([])
	const history = useHistory()

	const recordsByUsers = data?.data?.response?.records || []
	const location = data?.data?.response?.location || ''
	const expectedInventoryAmount = data?.data?.response?.expected_quantity || 0 // 盤點店家預期盤點數量
	const normalInventoryAmount = data?.data?.response?.normal_quantity || 0 // 盤點店家正常已盤點數量
	const abnormalInventoryAmount = data?.data?.response?.abnormal_quantity || 0 // 盤點店家異常已盤點數量
	const currentUsersScanData = recordsByUsers.filter((item) => item.user === getCookie('no'))

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'product_no',
			},
			{
				Header: () => (
					<Box display="flex" alignItems="center" justifyContent="center">
						<Box>品名</Box>
						<Tooltip label="點擊商品可看商品圖片">
							<span>
								<AiOutlineInfoCircle />
							</span>
						</Tooltip>
					</Box>
				),
				accessor: 'product',
				Cell: (row) => {
					const imageUrl = row.row.original.product_image
					return (
						<Box display="flex" alignItems="center" justifyContent="center">
							<PictureModal imageUrl={imageUrl} text={row.value} />
						</Box>
					)
				},
			},
			{
				Header: '品牌',
				accessor: 'product_brand_name',
			},
			{
				Header: '商品狀態',
				accessor: 'product_status',
			},
			{
				Header: '商品類型',
				accessor: 'product_type',
				Cell: (row) => {
					const { value } = row
					const isPurchasedProduct = value === '買斷'

					return (
						<Box fontWeight={isPurchasedProduct && 'bold'} color={isPurchasedProduct && 'red.300'}>
							{value}
						</Box>
					)
				},
			},
			{
				Header: '商品盤點結果',
				accessor: 'result',
			},
			{
				Header: '異常情形',
				accessor: 'abnormal_reason',
			},
		],
		[]
	)

	const handleScanInventory = (productData) => {
		setProductDataOnScanned((pre) => [...pre, productData])
	}

	const goToPage = (page) => {
		setPage(page)
	}

	const scannedList = [...recordsByUsers, ...productDataOnScanned]

	return (
		<Box>
			<Box d="flex" justifyContent="space-between" alignItems="center" mb="1.5em" mt="2em">
				<AddProductForm sheetId={sheetId} onSubmitSuccess={handleScanInventory} prevList={scannedList} />
				<Box d="flex">
					<Box mr="2em">
						<FormLabel>盤點地點</FormLabel>
						<StyledBox>{location}</StyledBox>
					</Box>
					<Box mr="2em">
						<FormLabel>員工編號</FormLabel>
						<StyledBox>{getCookie('no')}</StyledBox>
					</Box>
				</Box>
			</Box>
			<Box py="1rem">
				<Box>
					個人盤點總數：{currentUsersScanData.length} / 全體正常盤點總數：
					<Text as="samp" color="blue">
						{normalInventoryAmount}
					</Text>{' '}
					/ 預計商品總數：{expectedInventoryAmount} / 異常盤點數量：
					<Text as="samp" color="red.300">
						{abnormalInventoryAmount}
					</Text>
				</Box>
			</Box>
			<BasicTable tableData={scannedList} columns={columns} isLoading={isFetching} />
			<GeneralPagination defaultCurrent={page} total={scannedList.length} onChange={goToPage} />
			<Box display="flex" justifyContent="center" mt="2rem">
				<StyledButtonBox
					onClick={() => {
						history.push('/admin/inventory/inventorySheet')
					}}
				>
					返回
				</StyledButtonBox>
			</Box>
		</Box>
	)
}

export default EditInventoryPage
