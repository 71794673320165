import React from 'react'
import { Box } from '@chakra-ui/react'
import styled from 'styled-components'
import { MdKeyboardArrowRight } from 'react-icons/md'
import AdvancedSearch from './components/AdvancedSearch'
import PropTypes from 'prop-types'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: flex-stretch;
	margin-bottom: 1em;
	position: relative;
	padding-top: 1rem;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 80px 0 0 80px;
	}
`

TableTopBar.propTypes = {
	pageState: PropTypes.oneOf(['viewList', 'edit']),
}

export default function TableTopBar({ pageState, setAdvancedSearchParamsHandler }) {
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				商品管理
				{pageState === 'edit' && (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						內容詳情
					</Box>
				)}
			</Box>
			{pageState === 'viewList' && <AdvancedSearch setAdvancedSearchParamsHandler={setAdvancedSearchParamsHandler} />}
		</StyledTopbar>
	)
}
