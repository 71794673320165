import Axios from 'Api/interceptors'

export default class TempPurchaseOrdersService {
	async getOrderList(currentPage) {
		let result
		try {
			result = await Axios.get('/tempPurchaseOrders?&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOrderDetail(orderId, isForPrint) {
		let result
		try {
			result = await isForPrint ? Axios.get(`/tempPurchaseOrders/${orderId}?for_print=${isForPrint}`) : Axios.get('/tempPurchaseOrders/' + orderId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSelectOptionsData() {
		let result
		try {
			result = await Axios.get('/purchaseOrderOption')
		} catch (error) {
			result = error
		}
		return result
	}

	async createTempPurchaseOrder(payload) {
		let result
		try {
			result = await Axios.post('/tempPurchaseOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}


	async editTempPurchaseOrder(orderId, payload) {
		let result
		try {
			result = await Axios.put(`/tempPurchaseOrders/${orderId}`, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteTempPurchaseOrder(orderId) {
		let result
		try {
			result = await Axios.delete(`/tempPurchaseOrders/${orderId}`)
		} catch (error) {
			result = error
		}
		return result
	}
}
