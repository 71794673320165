import { Box, Checkbox, CheckboxGroup } from '@chakra-ui/react'

export default function SingleCheckboxGroup({ sub_permission, checklist, setchecklist }) {
	return (
		<>
			<CheckboxGroup>
				{sub_permission
					? sub_permission.permission.map((el, i) => (
							<Box d="flex" alignItems="center" mr="1em" key={i}>
								<Checkbox
									isChecked={checklist[el.id]}
									onChange={(e) => {
										setchecklist((pre) => {
											pre[el.id] = e.target.checked
											return { ...pre }
										})
									}}
									spacing=".2em"
									borderColor="gray.600"
								>
									<Box ml=".5em">{el.name}</Box>
								</Checkbox>
							</Box>
					  ))
					: null}
			</CheckboxGroup>
		</>
	)
}
