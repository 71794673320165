import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormLabel, Input, useDisclosure } from '@chakra-ui/react'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { SubtypeService } from 'Api/subtype/subtypeService'

export default function SubtypeModal({ setSubtypeNeedFetch }) {
	const { editState } = useContext(otherTableContext)
	const { register, handleSubmit } = useForm()
	const dispatch = useDispatch()
	const { onOpen, isOpen, onClose, onToggle } = useDisclosure()

	useEffect(() => {
		if (editState.editType === 'detail' && editState.editSubtype) {
			onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])

	async function onSubmit(formData) {
		const subtypeService = new SubtypeService()
		let result
		if (editState.editSubtype === 'edit') {
			result = await subtypeService.updateSubtype(editState.id, editState.subtypeId, formData)
		}

		if (editState.editSubtype === 'create') {
			result = await subtypeService.createSubtype(editState.id, formData)
		}

		if (editState.editSubtype === 'delete') {
			result = await subtypeService.deleteSubtype(editState.subtypeId)
		}

		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, code: data.code, status: status }))
				setSubtypeNeedFetch(true)
				onToggle()
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent py="1em" borderRadius="15px">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalHeader fontSize="2.5em" textAlign="center">
						{editState.editSubtype === 'edit' ? '編輯子類別' : null}
						{editState.editSubtype === 'create' ? '新增子類別' : null}
						{editState.editSubtype === 'delete' ? '刪除子類別' : null}
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody d="flex" justifyContent="center" px="2em" mb="1em">
						<Box mr="1em">
							<FormLabel>子類別名稱</FormLabel>
							<Input {...register('name', { value: editState.editSubtype !== 'create' ? editState.subtypeName : null })} required disabled={editState.editSubtype === 'delete' ? true : false} />
						</Box>
						<Box>
							<FormLabel>說明</FormLabel>
							<Input {...register('notes', { value: editState.editSubtype !== 'create' ? editState.subtypeNotes : null })} disabled={editState.editSubtype === 'delete' ? true : false} />
						</Box>
					</ModalBody>

					<ModalFooter justifyContent="center">
						<StyledButtonBox mr="1em" onClick={onClose}>
							取消
						</StyledButtonBox>
						<FormSubmitButton bg="white" type="submit">
							確定
						</FormSubmitButton>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
