import Pagination from 'rc-pagination'
import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import rightArrowIcon from 'assets/images/right-arrow.svg'
import leftArrowIcon from 'assets/images/left-arrow.svg'
import paginationDotsIcon from 'assets/images/pagination-dots.svg'

const StyledBox = styled(Box)`
	ul {
		list-style: none;
		display: flex;
		margin: 1rem 0;
		padding: 0;
		justify-content: center;

		li {
			color: #2250a6;
			margin: 0 0.2rem;
			font-size: 1.3rem;
			padding: 0 5px;
			cursor: pointer;

			&.rc-pagination-item-active {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.rc-pagination-prev,
		.rc-pagination-next,
		.rc-pagination-jump-prev,
		.rc-pagination-jump-next {
			width: 30px;
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
		}

		.rc-pagination-prev {
			background-image: url(${leftArrowIcon});
		}

		.rc-pagination-next {
			background-image: url(${rightArrowIcon});
		}

		.rc-pagination-jump-prev,
		.rc-pagination-jump-next {
			background-image: url(${paginationDotsIcon});
		}

		.rc-pagination-options {
			cursor: default;

			&-quick-jumper {
				input {
					width: 50px;
					outline: 1px solid #2250a6;
					margin: 0 0.3rem;
					border-radius: 5px;
					padding: 0 3px;
				}

				&-button {
					border: 1px solid #2250a6;
					padding: 0px 10px;
					margin-left: 0.3rem;
					border-radius: 5px;
				}
			}
		}
	}
`

const GeneralPagination = ({ ...rest }) => {
	return (
		<StyledBox>
			<Pagination
				{...rest}
				showQuickJumper={{ goButton: true }}
				locale={{
					jump_to: '跳至',
					jump_to_confirm: '確定',
					page: '頁',
				}}
			/>
		</StyledBox>
	)
}

export default GeneralPagination
