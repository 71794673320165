import ReportService from 'Api/report/reportService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetStockCategoryData(editState) {
	const [pieData, setPieData] = useState([])
	const [isNeedFetch, setIsNeedFetch] = useState(false)
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const reportService = new ReportService()
			const result = await reportService.getStockCategoryChartData()
			const { status, data } = result
			switch (status) {
				case 200:
					setPieData((pre) => (pre = [...data.response]))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
			setIsNeedFetch(false)
		}
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetch])
	return [pieData, setIsNeedFetch]
}
