import Axios from 'Api/interceptors'

export class VendorPageService {
	async getVendorPageData(token) {
		let result
		try {
			result = await Axios('/purchaseOrders/vendors/' + token)
		} catch (error) {
			result = error
		}
		return result
	}

	async getNotificationData(token) {
		let result
		try {
			result = await Axios(`/purchaseOrders/vendors/${token}/notices`)
		} catch (error) {
			result = error
		}
		return result
	}

	async getVendorDetail(token) {
		let result
		try {
			result = await Axios('/purchaseOrders/vendors/' + token + '/vendor')
		} catch (error) {
			result = error
		}
		return result
	}

	async updateVendorInfo(payload, token) {
		let result
		try {
			result = await Axios.put('/purchaseOrders/vendors/' + token, payload)
		} catch (error) {
			result = error
		}
		return result
	}
}
