import { Box, Select } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { merchantContext, updateModalContext } from '../../useContext/createContext'
import { useFormContext } from 'react-hook-form'

export default function TypeSelect({ typeData }) {
	const [typeSelectValue, setTypeSelectValue] = useState('') // 儲存type_id
	const [subtypeSelectValue, setSubtypeSelectValue] = useState('')
	const [subtypeSelectOption, setSubtypeSelectOption] = useState([])
	const { setValue } = useFormContext()
	const { merchantData } = useContext(merchantContext)
	const { setUpdateModalInfo } = useContext(updateModalContext)

	useEffect(() => {
		setSubtypeSelectOption((pre) => {
			let subtypeArr = []
			for (let i = 0; i < typeData.length; i++) {
				if (typeData[i].id === parseInt(typeSelectValue)) {
					subtypeArr = typeData[i].subtypes
				}
			}
			return subtypeArr
		})

		if (typeSelectValue) {
			setSubtypeSelectValue(merchantData['subtype_id'])
		}
		// 要是沒有值就送null，有值就確保是number
		setValue('type_id', parseInt(typeSelectValue))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeSelectValue])

	useEffect(() => {
		if (typeSelectValue) {
			if (subtypeSelectOption.length > 0) {
				let arr = subtypeSelectOption.filter((item) => item['id'] === merchantData['subtype_id'])
				if (arr[0]) {
					setValue('subtype_id', parseInt(subtypeSelectValue))
				}
				if (!arr[0]) {
					setValue('subtype_id', null)
				}
			}
			if (subtypeSelectValue.length === 0) {
				setValue('subtype_id', null)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtypeSelectOption])

	useEffect(() => {
		if (subtypeSelectValue) {
			setValue('subtype_id', parseInt(subtypeSelectValue))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtypeSelectValue])

	useEffect(() => {
		if (merchantData['type_id']) {
			setTypeSelectValue(merchantData['type_id'])
		}
	}, [merchantData])

	return (
		<Box d="flex" w="220px">
			<Select mr="1em" onChange={(e) => setTypeSelectValue((pre) => (pre = e.target.value))} value={typeSelectValue}>
				<option value="">主類別</option>
				{typeData.map((item, i) => (
					<option value={item.id} key={i}>
						{item.name}-{item.notes}
					</option>
				))}
			</Select>
			<Select onChange={(e) => setSubtypeSelectValue((pre) => (pre = e.target.value))} value={subtypeSelectValue}>
				<option value="">子類別</option>
				{subtypeSelectOption.map((item, i) => (
					<option value={item.id} key={i}>
						{item.name}-{item.notes}
					</option>
				))}
			</Select>
			<Box
				borderRadius="4px"
				fontSize="1.5em"
				d="flex"
				justifyContent="center"
				alignItems="center"
				bgColor="#2B64D0"
				color="white"
				cursor="pointer"
				flexShrink="0"
				w="35px"
				_hover={{ bgColor: '#265abb' }}
				onClick={() => {
					if (typeSelectValue) setUpdateModalInfo({ type: 'subtypes', type_id: typeSelectValue })
				}}
				ml=".2em"
			>
				<AiOutlinePlus />
			</Box>
		</Box>
	)
}
