import Axios from 'Api/interceptors'

export default class OtherTableService {
	async getData(apiRoute, page) {
		let result
		try {
			result = await Axios.get(apiRoute + '/?page=' + page)
		} catch (error) {
			result = error
		}
		return result
	}

	// 有type_id就是子分類要create的時候
	// 需要把子分類post到某個type_id底下
	async createData(apiRoute, payload, type_id) {
		if (type_id) {
			payload.type_id = type_id
		}

		let result
		try {
			result = await Axios.post(apiRoute, payload)
		} catch (error) {
			result = error
		}
		return result
	}
}
