import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
		margin-top: 2em;
		table-layout: fixed;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function DetailTable({ productList }) {
	return (
		<>
			<StyledTable>
				<table>
					<thead>
						<tr>
							<td>商品編號</td>
							<td>品名</td>
							<td>類別</td>
							<td>品牌名</td>
							<td>顏色</td>
							<td>尺寸</td>
							<td>狀況</td>
							<td>商品狀態</td>
							<td>進價</td>
							<td>銷貨單號</td>
						</tr>
					</thead>
					<tbody>
						{productList.length > 0
							? productList.map((item, i) => (
									<tr key={i}>
										<td>{item.product_no}</td>
										<td>{item.product_name}</td>
										<td>{item.product_type}</td>
										<td>{item.product_brand}</td>
										<td>{item.product_color}</td>
										<td>{item.product_size}</td>
										<td>{item.product_condition}</td>
										<td>{item.product_status}</td>
										<td>{item.product_price}</td>
										<td>{item.sale_order_no}</td>
									</tr>
							  ))
							: null}
					</tbody>
				</table>
			</StyledTable>
		</>
	)
}
