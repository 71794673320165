import { Box, Spinner } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import { FaPen, FaTrashAlt } from 'react-icons/fa'

export default function SubtypeTable({ subtypeList }) {
	const { setEditState } = useContext(otherTableContext)
	const data = React.useMemo(() => {
		return subtypeList.data
	}, [subtypeList])

	const columns = React.useMemo(
		() => [
			{
				Header: '序號',
				accessor: 'id',
				Cell: (row) => {
					return Number(row.row.id) + 1
				},

				disableFilters: true,
			},
			{
				Header: '子類別',
				accessor: 'name',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '說明',
				accessor: 'notes',
				disableFilters: true,
			},

			{
				Header: '管理',
				accessor: 'management',
				disableFilters: true,
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box
								color="#91B0EB"
								cursor="pointer"
								onClick={() =>
									setEditState(
										(pre) => (pre = { ...pre, editType: 'detail', editSubtype: 'edit', subtypeId: row.row.original.id, subtypeName: row.row.original.name, subtypeNotes: row.row.original.notes })
									)
								}
							>
								<FaPen />
							</Box>
							<Box
								color="#EB9191"
								cursor="pointer"
								onClick={() =>
									setEditState(
										(pre) => (pre = { ...pre, editType: 'detail', editSubtype: 'delete', subtypeId: row.row.original.id, subtypeName: row.row.original.name, subtypeNotes: row.row.original.notes })
									)
								}
							>
								<FaTrashAlt />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 25 },
		},
		useFilters,
		useSortBy,
		usePagination
	)
	return (
		<>
			{subtypeList.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column" w="100%">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{subtypeList.data ? (
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map((headerGroup, g) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={g}>
										{headerGroup.headers.map((column, k) => {
											return (
												<th key={k}>
													<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
														{
															// Render the header
															column.render('Header')
														}
													</Box>
												</th>
											)
										})}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									page.map((row, i) => {
										// Prepare the row for display
										prepareRow(row)
										return (
											// Apply the row props
											<tr {...row.getRowProps()} key={i}>
												{
													// Loop over the rows cells
													row.cells.map((cell, j) => {
														// Apply the cell props
														return (
															<td {...cell.getCellProps()} key={j}>
																{
																	// Render the cell contents
																	cell.render('Cell')
																}
															</td>
														)
													})
												}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}
				</>
			)}
		</>
	)
}
