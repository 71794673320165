import { Select, Input, Text, Box } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineSearch } from 'react-icons/ai'
import { FormSubmitButton } from 'utils/StyledButton'
import { selectOptionsContext } from '../useContext/createContext'
import styled from 'styled-components'
import ReactSelect from 'react-select'

const StyledForm = styled.form`
	display: flex;
	algn-items: center;
`

export default function AdvancedSearch({ setAdvancedSearchParamsHandler }) {
	const { selectOptionsData } = useContext(selectOptionsContext)
	const [advancedSearch, setAdvancedSearch] = useState(false)
	const [selectedTypeId, setSelectedTypeId] = useState('')
	const [brandIds, setBrandIds] = useState('')
	const { handleSubmit, register, reset, setValue } = useForm()

	function onSubmit(data) {
		setAdvancedSearchParamsHandler(data)
	}

	useEffect(() => {
		if (!advancedSearch) {
			setBrandIds('')
			reset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advancedSearch])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoSubtype = useCallback(() => {
		return subtypeArr(selectOptionsData?.types, selectedTypeId)
	}, [selectOptionsData, selectedTypeId])

	function subtypeArr(arr, typeId) {
		let tempArr = []
		if (typeId) {
			for (const item of arr) {
				if (item.id.toString() === typeId) {
					tempArr = [...item.subtypes]
				}
			}
		}
		return tempArr
	}

	const optionArray = useCallback(() => ArrayFilter(selectOptionsData?.brands), [selectOptionsData])

	function ArrayFilter(arr = []) {
		let filteredArr = []
		arr.forEach((item) => {
			filteredArr.push({ value: item.id, label: item.name })
		})
		return filteredArr
	}

	return (
		<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Box>
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<Box mr="1rem" cursor="pointer" onClick={() => setAdvancedSearch(!advancedSearch)}>
						進階搜尋條件
					</Box>
					<Input mr="1em" maxW="160px" placeholder="請輸入品名或商品編號" {...register('keyword')} defaultValue="" />
					<FormSubmitButton flexShrink="0" type="submit">
						<AiOutlineSearch />
						<Text ml="5px">搜尋</Text>
					</FormSubmitButton>
				</Box>
				{advancedSearch ? (
					<Box display="flex" mt="0.5rem" maxW="700px" flexWrap="wrap" justifyContent="flex-end">
						<Box d="flex" w="100%" justifyContent="flex-end">
							<Select mr="1em" maxW="100px" {...register('page_count')}>
								<option value="">顯示筆數</option>
								<option value={10}>10筆</option>
								<option value={30}>30筆</option>
								<option value={50}>50筆</option>
							</Select>

							<Input type="date" w="160px" {...register('start_date')} />
							<Box mx=".5em">到</Box>
							<Input type="date" w="160px" {...register('end_date')} />
						</Box>
						<Select width="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('location_id')}>
							<option value="">位置</option>
							{selectOptionsData?.locations?.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>
						<Select width="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('status')}>
							<option value="">商品狀態</option>
							<option value="0">在庫</option>
							<option value="1">已售出</option>
							<option value="2">已退貨</option>
						</Select>
						<Select w="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('type')}>
							<option value="">商品類型</option>
							<option value="0">寄賣</option>
							<option value="1">買斷</option>
						</Select>
						<Select w="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('size_id')}>
							<option value="">尺寸</option>
							{selectOptionsData?.sizes?.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>
						<Select w="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('color_id')}>
							<option value="">顏色</option>
							{selectOptionsData?.colors?.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>

						<Select w="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('condition_id')}>
							<option value="">狀況</option>
							{selectOptionsData?.conditions?.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>
						<Select
							w="130px"
							ml="1em"
							mt="0.7rem"
							{...register('type_id', {
								value: selectedTypeId,
							})}
							onChange={(e) => {
								setSelectedTypeId(e.target.value)
							}}
						>
							<option value="">類別</option>
							{selectOptionsData?.types?.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name} {item.notes}
								</option>
							))}
						</Select>
						<Select w="130px" ml="1em" mt="0.7rem" defaultValue="" {...register('subtype_id')}>
							<option value="">子類別</option>
							{selectOptionsData?.types
								? memoSubtype().map((item) => (
										<option key={item.name} value={item.id}>
											{item.name} {item.notes}
										</option>
								  ))
								: null}
						</Select>
						<Box mt="0.5rem" d="flex" alignItems={'center'}>
							<ReactSelect
								styles={{ container: (provided) => ({ ...provided, width: 400 }) }}
								options={optionArray()}
								closeMenuOnSelect={true}
								isMulti
								value={brandIds}
								placeholder="品牌"
								onChange={(value) => {
									if (value.length === 1) {
										setValue('brand_id', value[0].value)
									}
									if (value.length === 2) {
										setValue('brand_id', value[1].value)
									}
									if (value.length === 0) {
										setBrandIds([])
										setValue('brand_id', '')
									} else {
										setBrandIds((pre) => {
											if (value.length > 1) {
												value.shift()
											}
											return value
										})
									}
								}}
							/>
							<Select w="130px" ml="1em" defaultValue="" {...register('has_picture')}>
								<option value="">是否有圖片</option>
								<option value={true}>是</option>
								<option value={false}>否</option>
							</Select>
						</Box>
					</Box>
				) : null}
			</Box>
		</StyledForm>
	)
}
