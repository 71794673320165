export function priceCommaInserter(priceNum) {
	const priceStr = priceNum.toString()
	const priceStrWithComma = priceStr
		.split('')
		.reverse()
		.map((char, i) => ((i % 3 === 0) & (i !== 0) ? char + ',' : char))
		.reverse()
		.join('')
	return priceStrWithComma
}
