import VendorService from 'Api/vendor/vendorService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetVendorList() {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const [vendorPhone, setVendorPhone] = useState('')
	const [isNeedFetchList, setIsNeedFetchList] = useState(false)
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const vendorService = new VendorService()
			const getListData = await vendorService.getVendorList(currentPage, vendorPhone)
			const { status, data } = getListData

			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: [...data.response.data], isFetching: false })
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(warning({ message: data.message, code: data.code, status: status }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(error({ message: data.message, code: data.code, status: status }))
					break
			}
			setIsNeedFetchList(false)
		}

		if (isNeedFetchList) {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetchList])

	useEffect(() => {
		// 重新search的話要reset currentPage/maxPage
		// 免得會出現重新search以後，出現 89 / 10 這種 currentPage / maxPage的情況
		setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
		setIsNeedFetchList(true)
	}, [vendorPhone])

	useEffect(() => {
		setIsNeedFetchList(true)
	}, [currentPage])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}
	return [currentPage, maxPage, gotoPage, tableData, setVendorPhone, setIsNeedFetchList]
}
