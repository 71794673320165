import AuthTable from 'components/table/other/authTable/StyledTable'
import TypeTable from 'components/table/other/typeTable/StyledTable'
import SizeTable from 'components/table/other/sizeTable/StyledTable'
import ColorTable from 'components/table/other/colorTable/StyledTable'
import ConditionTable from 'components/table/other/conditionTable/StyledTable'
import BrandTable from 'components/table/other/brandTable/StyledTable'
import PositionTable from 'components/table/other/positionTable/StyledTable'
import { Provider as OtherTableProvider } from 'context/otherTableContext'
import { Route } from 'react-router-dom'
import { useGetOtherTable } from 'hook/useGetOtherTable'
import { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'

export default function OtherTableRoute() {
	const [tableData, currentPage, maxPage, gotoPage, setIsNeedFetchList, type, setTablePage] = useGetOtherTable()
	const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
	const [editState, setEditState] = useState({ editType: '', id: '', name: '', notes: '' })
	let value = {
		setIsNeedFetchList,
		tableData,
		isOpen,
		onClose,
		onOpen,
		onToggle,
		editState,
		setEditState,
		currentPage,
		maxPage,
		gotoPage,
		type,
	}
	useEffect(() => {
		// 只要type有切換，就reset editstate中的資料
		// 目前modal的寫法在type切換會自動打開modal，這部分不知道怎麼優化，暫時先在這邊寫一個onclose()
		// 在type切換的時候先關掉modal，但應該有更好的寫法
		onClose()
		setEditState({ editType: '' })
		setTablePage((pre) => (pre = { ...pre, currentPage: 1 }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type])
	return (
		<OtherTableProvider value={value}>
			<Route exact path="/admin/other/roles" component={AuthTable} />
			<Route exact path="/admin/other/types" component={TypeTable} />
			<Route exact path="/admin/other/sizes" component={SizeTable} />
			<Route exact path="/admin/other/colors" component={ColorTable} />
			<Route exact path="/admin/other/conditions" component={ConditionTable} />
			<Route exact path="/admin/other/brands" component={BrandTable} />
			<Route exact path="/admin/other/locations" component={PositionTable} />
		</OtherTableProvider>
	)
}
