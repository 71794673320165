import React, { useCallback, useContext, useState } from 'react'
import { Box, Text, useToast } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { paymentListContext } from './useContext/createContext'
import SelectTable from './component/SelectTable'
import { StyledButtonBox } from 'utils/StyledButton'
import SubmitAlert from './component/SubmitAlert'
import { PaymentService } from 'Api/payment/paymentService'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
`

const StyledTimeBox = styled(Box)`
	border-color: black !important;
	border-bottom: 1px solid;
	color: #a1a1a1;
	min-height: 1.5em;
	min-width: 80px;
	margin-top: 1em;
	text-align: center;
	padding-bottom: 0.5em;
`

const StyledBox = styled(Box)`
	border: 1px solid;
	border-radius: 10px;
	padding: 0.5em;
	text-align: center;
`

export default function SubContent() {
	const { editState, optionData, setEditState, setProductListIsNeedFetch } = useContext(paymentListContext)
	const [optionIdList, setOptionIdList] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const dispatch = useDispatch()

	async function Submit() {
		if (optionIdList.length === 0) {
			toast({ title: '系統通知', description: '請至少選取一個付款廠商', status: 'warning', isClosable: true, duration: 1500 })
		}
		if (optionIdList.length > 0) {
			const formData = {}
			formData['vendor_ids'] = optionIdList.join(',')
			formData['start_date'] = editState.date.startDate.replace(/-/gi, '/')
			formData['end_date'] = editState.date.endDate.replace(/-/gi, '/')
			setIsLoading(true)
			const paymentService = new PaymentService()
			const result = await paymentService.createPaymentOrder(formData)
			const { data, status } = result
			switch (status) {
				case 200:
					dispatch(success({ message: data.message, status: status }))
					setEditState({ editType: 'viewList', data: [], date: {} })
					setProductListIsNeedFetch(true)
					break
				case 400:
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setIsLoading(false)
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const totalPrice = useCallback(cal(optionIdList, optionData.data.vendors || []), [optionIdList, optionData])

	function cal(idList, vendorList) {
		let totalPayment = 0
		for (let i = 0; i < idList.length; i++) {
			for (const singleVendor of vendorList) {
				if (singleVendor.vendor_id === parseInt(idList[i])) {
					totalPayment += singleVendor.price
				}
			}
		}
		return totalPayment
	}

	function reset() {
		setOptionIdList([])
		setEditState({ editType: 'viewList', data: [], date: {} })
	}

	return (
		<StyledTransitionBg>
			<Box d="flex" justifyContent="space-between" pr="2em">
				<Box>
					<Text>時間區間</Text>
					<Box d="flex" alignItems="center">
						<StyledTimeBox>{editState.date.startDate || '起始時間'}</StyledTimeBox>
						<Box mx="1em">~</Box>
						<StyledTimeBox>{editState.date.endDate || '結束時間'}</StyledTimeBox>
					</Box>
				</Box>
				<Box d="flex">
					<StyledBox mr="1em">
						<Box px="1em" pb=".5em" borderBottom="1px solid">
							已選擇廠商數 / 本期總廠商數
						</Box>
						<Box minH="2.2em" d="flex" alignItems="center" justifyContent="center">
							{optionIdList.length} / {optionData.data.count || 0}
						</Box>
					</StyledBox>
					<StyledBox>
						<Box px="1.5em" pb=".5em" borderBottom="1px solid">
							已選擇金額 / 總金額
						</Box>
						<Box minH="2.2em" d="flex" alignItems="center" justifyContent="center">
							{totalPrice} / {optionData.data.total_price || 0}
						</Box>
					</StyledBox>
				</Box>
			</Box>

			<SelectTable productList={optionData.data.vendors || []} setOptionIdList={setOptionIdList} />
			<Box d="flex" alignItems="center" justifyContent="center" mt="2em">
				<StyledButtonBox mr="1em" onClick={reset}>
					取消
				</StyledButtonBox>
				<SubmitAlert submitHandler={Submit} loadingState={isLoading} />
			</Box>
		</StyledTransitionBg>
	)
}
