import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import styled from 'styled-components'
import useGetVendorPageData from 'hook/vendorPage/useGetVendorPageData'
import Main from 'components/vendorPage/Main'
import ErrorPage from 'components/vendorPage/ErrorPage'
import { useLocation } from 'react-router-dom'

const StyledBg = styled(Box)`
	width: 100%;
	max-width: 100vw;
	padding: 0.4em;
	margin: 0 auto;
	background-color: #c7d3e4;
	min-height: 100vh;
`

export default function VendorPage() {
	const { search } = useLocation()
	const code = new URLSearchParams(search).get('code')
	const [vendorData] = useGetVendorPageData(code)

	return (
		<StyledBg>
			{vendorData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>{vendorData.id ? <Main vendorData={vendorData} /> : <ErrorPage />}</>
			)}
		</StyledBg>
	)
}
