import Axios from 'Api/interceptors'

export class PaymentService {
	async getPaymentList(currentPage, date) {
		let result
		try {
			if (date.startDate) {
				let startDate = date.startDate.replace(/-/gi, '/')
				let endDate = date.endDate.replace(/-/gi, '/')
				result = await Axios.get(`/paymentOrders?page=${currentPage}&start_date=${startDate}&end_date=${endDate}`)
			}

			if (!date.startDate) {
				result = await Axios.get(`/paymentOrders?page=${currentPage}`)
			}
		} catch (error) {
			result = error
		}
		return result
	}

	async createPaymentOrder(payload) {
		let result
		try {
			result = await Axios.post('/paymentOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async getPaymentDetail(paymentId) {
		let result
		try {
			result = await Axios.get('/paymentOrders/' + paymentId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getPaymentSheetDetail(orderId, vendorId) {
		let result
		try {
			result = await Axios.get(`/paymentOrders/${orderId}/vendor/${vendorId}`)
		} catch (error) {
			result = error
		}
		return result
	}

	async getPurchaseOptionByDateAndPhone(date, vendorPhone) {
		let result
		try {
			let startDate = ''
			let endDate = ''
			if (date.startDate) {
				startDate = date.startDate.replace(/-/gi, '/')
			}
			if (date.endDate) {
				endDate = date.endDate.replace(/-/gi, '/')
			}
			if (vendorPhone) {
				result = await Axios.get(`/paymentOrderSold?vendorPhone=${vendorPhone}${startDate && `&start_date=${startDate}`}${endDate && `&end_date=${endDate}`}`)
			}

			if (!vendorPhone) {
				// result = await Axios.get(`/paymentOrderSold?start_date=${startDate}&end_date=${endDate}`)
				result = await Axios.get(`/paymentOrderSold?${startDate && `start_date=${startDate}`}${endDate && `&end_date=${endDate}`}`)
			}
		} catch (error) {
			result = error
		}
		return result
	}

	async getVendorDetailByDateAndId(date, vendorId) {
		let result
		try {
			let startDate = ''
			let endDate = ''
			if (date.startDate) {
				startDate = date.startDate.replace(/-/gi, '/')
			}
			if (date.endDate) {
				endDate = date.endDate.replace(/-/gi, '/')
			}
			result = await Axios.get(`/paymentOrderSold/${vendorId}?${startDate && `start_date=${startDate}`}${endDate && `&end_date=${endDate}`}`)
		} catch (error) {
			result = error
		}
		return result
	}
}
