import axios from 'axios'
import { getCookie, setRequestId, setToken } from 'utils/cookies'
// import { errorStorage } from 'utils/errorStorage'

let url = ''

switch (process.env.REACT_APP_ENV) {
	case 'test':
		url = process.env.REACT_APP_TEST_API_URL
		break
	case 'dev':
		url = process.env.REACT_APP_API_URL
		break
	default:
		url = process.env.REACT_APP_API_URL
		break
}

const Axios = axios.create({ baseURL: url })

Axios.interceptors.request.use(
	function (config) {
		if (getCookie('token')) {
			config.headers['Authorization'] = 'Bearer ' + getCookie('token')
		}

		if (getCookie('rid')) {
			config.headers['Request-Id'] = getCookie('rid')
		}
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)
Axios.interceptors.response.use(
	function (response) {
		// handle response success的時候置換token
		if (response.headers['authorization']) {
			console.log('token置換成功 in success')
			setToken(response.headers['authorization'].split('Bearer ')[1])
		}
		if (!getCookie('rid')) {
			// response.data.request_id
			//置換request_id，給後端做追蹤用
			setRequestId(response.data.request_id)
		}
		// 後端蠻多message沒有補上，我這邊先做一個所有API共用的
		if (!response.data.message) {
			switch (response.config.method) {
				case 'get':
					response.data.message = '資料獲取成功'
					break
				case 'post':
					response.data.message = '資料新增成功'
					break
				case 'put':
					response.data.message = '資料更新成功'
					break
				case 'delete':
					response.data.message = '資料刪除成功'
					break
				default:
					break
			}
		}

		return response
	},
	function (error) {
		// 這邊是uncaught error
		// uncaught error 會有response === 'undefined'，400和500都會有response
		if (typeof error.response === 'undefined') {
			// errorManager的功能已被移除，改由request_id 紀錄
			// if (error.message) errorStorage(error.message)
			error = { response: { data: { message: '系統異常，請聯繫維護人員' } }, status: 500 }
			return Promise.reject(error)
		}
		// 這邊會是400 和 500
		if (typeof error.response !== 'undefined') {
			if (error.response.headers['authorization']) {
				// handle response error的時候置換token
				// 但，如果出現例外的錯誤，會換不了TOKEN
				console.log('token置換成功 in error')
				setToken(error.response.headers['authorization'].split('Bearer ')[1])
			}

			if (error.response.data.request_id) {
				//置換request_id，給後端做追蹤用
				setRequestId(error.response.data.request_id)
			}

			// errorManager的功能已被移除，改由request_id 紀錄
			//axios的error message, response的status code, response的detail, response的message
			// errorStorage(error.message, error.response.status, error.response.data.detail, error.response.data.message, error.request.responseURL)
			error = error.response
			if (!error.data.message) {
				error.data.message = '系統異常，請聯繫維護人員'
			}

			return Promise.reject(error)
		}

		return Promise.reject(error)
	}
)

export default Axios
