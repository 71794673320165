import React from 'react'
import { FormLabel, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export default function StyledFormLabel({ tag, isRequired }) {
	return (
		<FormLabel d="flex">
			{tag}
			{isRequired ? <Text color="red">*</Text> : null}
		</FormLabel>
	)
}

StyledFormLabel.propTypes = {
	tag: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
}
