import { Box } from '@chakra-ui/layout'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import List from 'react-virtualized/dist/commonjs/List'
import { merchantContext } from '../useContext/createContext'

export default function VirtualizedList({ isVisible, typeData, setDataValue, dataValue, type }) {
	const [isHover, setIsHover] = useState(false)
	const [thisList, setThisList] = useState([])
	const { merchantData } = useContext(merchantContext)

	useEffect(() => {
		if (typeData) setThisList(typeData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeData])

	useEffect(() => {
		if (dataValue) {
			setThisList((pre) => (pre = typeData.filter((item) => item.name.indexOf(dataValue) > -1)))
		}
		if (!dataValue) {
			setThisList(typeData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue])

	useEffect(() => {
		// 因為ID都大於0
		// 這邊是拿到merchantData之後，先在virtualList塞值
		// 外層會還有一個useeffect監聽是否有塞值，有塞值的話會再把值塞進formdata (用setvalue)
		if (merchantData[type] >= 0) {
			setDataValue((pre) => {
				let arr = typeData.filter((item) => item['id'] === merchantData[type])
				if (arr[0]) {
					return arr[0]['name']
				}
				if (!arr[0]) {
					return ''
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantData])

	const itemHeight = 40
	const listWidth = 300
	const listHeight = thisList.length * itemHeight

	return (
		<Box d={isVisible || isHover ? 'block' : 'none'} position="relative">
			<Box
				onMouseOver={() => {
					if (!isHover) {
						setIsHover(true)
					}
				}}
				onMouseLeave={() => {
					if (isHover) {
						setIsHover(false)
					}
				}}
				maxW="100%"
				position="absolute"
				bg="white"
				zIndex="10"
				border="1px solid"
				borderRadius="5px"
				borderColor="gray.200"
			>
				<List
					style={{ maxWidth: '100%', maxHeight: '150px', overflowY: 'scroll' }}
					width={listWidth}
					height={listHeight}
					rowHeight={itemHeight}
					rowCount={thisList.length}
					rowRenderer={({ key, style, index }) => (
						<Box
							key={key}
							style={style}
							d="flex"
							justifyContent="space-between"
							_hover={{ bgColor: 'gray.200' }}
							onClick={() => {
								setIsHover(false)
								setDataValue(thisList[index]['name'])
							}}
							px="1em"
							alignItems="center"
						>
							<Box>
								{typeof index === 'number'
									? `${thisList[index]['name']}${thisList[index]['notes'] ? '-' : ''}${thisList[index]['notes'] || ''}`
									: null}
							</Box>
						</Box>
					)}
					autoHeight
					noRowsRenderer={() => <Box>目前沒有任何資料</Box>}
				/>
			</Box>
		</Box>
	)
}
