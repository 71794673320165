import { Box, Input } from '@chakra-ui/react'
import { ProductService } from 'Api/product/productService'
import React from 'react'
import { useContext } from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { FormSubmitButton } from 'utils/StyledButton'
import StyledFormLabel from 'utils/StyledFormLabel'
import { transferTableContext } from '../useContext/createContext'

export default function AddProductForm() {
	const { handleSubmit, register } = useForm()
	const { setTableData } = useContext(transferTableContext)
	const dispatch = useDispatch()
	const focusRef = useRef()

	async function onSubmit(formData) {
		const productService = new ProductService()
		const result = await productService.getOneProductForTransfer(formData.product_id)

		const { data, status } = result
		switch (status) {
			case 200:
				setTableData((pre) => (pre = [...pre, data.response]))
				dispatch(success({ message: data.message, status: status, code: data.code }))
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, code: data.code }))
				break
		}

		focusRef.current.focus()
		focusRef.current.value = ''
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<StyledFormLabel tag="調撥商品" />
			<Box d="flex">
				<Input id="increaseInput" placeholder="請刷條碼" maxW="170px" mr="1em" {...register('product_id')} isRequired ref={focusRef} />
				<FormSubmitButton type="submit">下一筆</FormSubmitButton>
			</Box>
		</form>
	)
}
