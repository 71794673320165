import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Box, Avatar, Tooltip, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import logoIcon from 'assets/images/logo.svg'
import { priceCommaInserter } from 'utils/priceInsertComma'
import { BsFillExclamationOctagonFill } from 'react-icons/bs'
import VendorInfoCannotEdit from 'components/vendorPage/VendorInfoCannotEdit'
import ProductItemContainer from 'components/vendorPage/ProductItemContainer'
import Notification from 'components/vendorPage/Notification'


const TipIcon = forwardRef(({ children, ...rest }, ref) => (
	<Box ref={ref} {...rest}>
		{children}
	</Box>
))

const StyledAvatar = styled(Avatar)`
	img {
		object-fit: fill;
	}
`

export default function Main({ vendorData }) {
	//  這個MemoPrice本來是用來計算所有寄賣商品的價格，後來被取消不用
	//  改成顯示廠商可取款金額，由後端計算後給欄位顯示而已
	// const memoTotalPrice = useMemo(() => {
	// 	let totalPrice = 0
	// 	for (const product of vendorData.products) {
	// 		totalPrice += product.product_price
	// 	}
	// 	return priceCommaInserter(totalPrice)
	// }, [vendorData])

	const pageSettings = { defaultCurrenyPage : 1, pageSize: 3 } 
	const selledProducts = vendorData.products.filter((product) => product.product_status === '已售出' && product.has_payment === false)
	const hasPayedProducts = vendorData.products.filter((product) => product.product_status === '已售出' && product.has_payment === true)
	const returnedProducts = vendorData.products.filter((product) => product.product_status === '已退貨')

	return (
		<>
			<Box maxW="420px" mx="auto">
				<Box background="white" borderRadius="10px">
					<Box textAlign="center" fontSize="1.5em" padding="1em 0" color="#2250A6">
						OPMM 廠商專區
					</Box>
					<Box display="flex" pt=".5em" pb="2em" color="#839BBE">
						<Box flex="1" height="5em" textAlign="center">
							<StyledAvatar size="xl" bg="#ffffff" color="#839BBE" objectFit="fill" src={logoIcon} />
						</Box>
						<Box flex="1" h="5em" fontSize="1.2rem">
							<Box my=".8rem">{vendorData.name}</Box>
							<Box>{vendorData.phone}</Box>
						</Box>
					</Box>
				</Box>
				<Box m="1em 0.5em" bg="white" p="1em" borderRadius="10px" display="flex">
					<VendorInfoCannotEdit />
					<Notification />

					<Box p="0.5rem 0" fontSize="0.8em" borderRadius="10px" color="#839BBE" textAlign="center" flex="1" lineHeight="21px">
						<Box>本月月結金額</Box>
						<Box>${priceCommaInserter(vendorData.current_month_total)}</Box>
						<Box>收款金額</Box>
						<Box>${priceCommaInserter(vendorData.total)}</Box>
					</Box>
				</Box>
				<Box background="white" borderRadius="10px" pb="1em" mb="2em">
					<Box textAlign="center" fontSize="1.3em" padding="1rem 0" color="#2250A6">
						我的商品
						<Box display="inline-block" ml=".5rem" fontSize="0.8em" color="#F6ACA7" cursor="pointer">
							<Tooltip label="每日晚間12點更新寄賣商品資訊" hasArrow placement="auto" fontSize="md" p="3px">
								<TipIcon>
									<BsFillExclamationOctagonFill />
								</TipIcon>
							</Tooltip>
						</Box>
					</Box>
					<Tabs isFitted variant="enclosed">
						<TabList>
							<Tab>全部商品</Tab>
							<Tab>已售出</Tab>
							<Tab>已結款</Tab>
							<Tab>已退貨</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<ProductItemContainer productDataList={vendorData.products} pageSettings={pageSettings} />
							</TabPanel>
							<TabPanel>
								<ProductItemContainer productDataList={selledProducts} pageSettings={pageSettings}/>
							</TabPanel>
							<TabPanel>
								<ProductItemContainer productDataList={hasPayedProducts} pageSettings={pageSettings}/>
							</TabPanel>
							<TabPanel>
								<ProductItemContainer productDataList={returnedProducts} pageSettings={pageSettings}/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		</>
	)
}
