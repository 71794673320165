import { Box } from '@chakra-ui/react'
import TableTopBar from 'components/tableTopBar'
import React from 'react'
import styled from 'styled-components'
import { useRouteMatch, useHistory } from 'react-router-dom'
import CreatePage from './createPage'
import { StyledButtonBox as Button } from 'utils/StyledButton'
import ListPage from './listPage'
import { HiPlusSm } from 'react-icons/hi'

const Container = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;
`

function RePurchaseTable() {
	const history = useHistory()
	const matchIndex = useRouteMatch('/admin/stock/rePurchase')
	const matchCreate = useRouteMatch('/admin/stock/rePurchase/:action')

	const isOnIndexPage = matchIndex?.isExact
	const isOnCreatePage = matchCreate?.params?.action === 'create'

	const renderPage = () => {
		if (isOnIndexPage) {
			return <ListPage />
		} else if (isOnCreatePage) {
			return <CreatePage />
		}
	}

	const subtitle = isOnCreatePage && '新增寄賣轉買斷'

	return (
		<Container>
			<TableTopBar title="寄賣轉買斷" subtitle={subtitle}>
				{!isOnCreatePage && (
					<Button onClick={() => history.push('/admin/stock/rePurchase/create')}>
						<HiPlusSm />
						新增寄賣轉買斷
					</Button>
				)}
			</TableTopBar>
			{renderPage()}
		</Container>
	)
}

export default RePurchaseTable
