import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import { accountParser } from 'utils/account'
import SheetDetailModel from './SheetDetailModal'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}

	@media print {
		.print-ignore {
			display: none !important;
		}
	}
`

export default function DisplayTable({ vendors }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>廠商電話</td>
						<td>廠商姓名</td>
						<td>寄賣總額</td>
						<td>付款方式</td>
						<td>銀行代碼</td>
						<td>銀行帳號</td>
						<td className="print-ignore">清單</td>
					</tr>
				</thead>
				<tbody>
					{vendors.length > 0
						? vendors.map((item, i) => (
								<tr key={i}>
									<td>{item.vendor_phone}</td>
									<td>{item.vendor_name}</td>
									<td>{item.price}</td>
									<td>{item.vendor_payment}</td>
									<td>{accountParser(item.vendor_account)?.code}</td>
									<td>{accountParser(item.vendor_account)?.account}</td>
									<td className="print-ignore">
										<SheetDetailModel vendorId={item.vendor_id} />
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
