import PermissionService from 'Api/permission/permissionService'
import usersTableService from 'Api/users/users'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetUserPermission(userId) {
	const [defaultPermissions, setDefaultPermissions] = useState([])
	const [userData, setUserData] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const permissionService = new PermissionService()
			const result = await permissionService.getPermissionList()
			const { data, status } = result
			switch (status) {
				case 200:
					setDefaultPermissions((pre) => (pre = [...data.response.data]))
					break
				case 400:
					dispatch(warning({ message: data.message, status: 400 }))
					break
				default:
					dispatch(error({ message: data.message, status: 500 }))
					break
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		async function fetchData() {
			const userService = new usersTableService()
			const result = await userService.getOneUser(userId)
			const { data, status } = result
			switch (status) {
				case 200:
					setUserData((pre) => (pre = { ...pre, data: data.response }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, status: 400 }))
					break
				default:
					dispatch(error({ message: data.message, status: 500 }))
					break
			}
			setUserData((pre) => (pre = { ...pre, isFetching: false }))
		}

		if (userId && defaultPermissions.length > 0) {
			setUserData((pre) => (pre = { ...pre, isFetching: true }))
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, defaultPermissions])
	return [defaultPermissions, userData]
}
