import React, { useContext } from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { storeSalesContext } from './useContext/createContext'
import { FormSubmitButton } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: flex-stretch;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
		top: 0;
		height: 100%;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
`

export default function TableTopBar() {
	const { setEditState, editState } = useContext(storeSalesContext)
	const { handleSubmit, register } = useForm()

	function onSubmit(formData) {
		setEditState((pre) => (pre = { ...pre, date: formData.date }))
	}

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex" alignItems="center">
				進貨銷貨報表
			</Box>
			{editState.editType === 'viewList' ? (
				<Box d="flex">
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Input w="170px" type="date" {...register('date.startDate')} isRequired />
						<Box mx=".5em">到</Box>
						<Input mr="1em" w="170px" type="date" {...register('date.endDate')} isRequired />
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">搜尋</Text>
						</FormSubmitButton>
					</StyledForm>
				</Box>
			) : null}
		</StyledTopbar>
	)
}
