import React, { Fragment } from 'react'
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
	Text,
	Spinner,
} from '@chakra-ui/react'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import styled from 'styled-components'
import useGetOneProduct from 'hook/productTable/useGetOneProduct'
import { modalTitleSwitcher } from 'utils/wordingOrIconsSwitcher'
import { useEffect } from 'react'
import Barcode from 'react-barcode'
import { useForm } from 'react-hook-form'
import { ProductService } from 'Api/product/productService'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import StyledFormLabel from 'utils/StyledFormLabel'
import { getCookie } from 'utils/cookies'

const StyledGridTable = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 0.5em;
	row-gap: 0.5em;
`

const StyledForm = styled.form`
	@media print {
		.modal-header {
			display: none;
		}

		.modal-content {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			max-width: none;
			max-height: none;
			height: 100%;
			margin: 0;
			z-index: 9999;

			.tag {
				width: 100%;
				height: 100%;
			}
		}

		.modal-close {
			display: none;
		}

		@page {
			size: A4 landscape;
		}
	}
`

export default function ReusedModal({ resetModal, reuseModalState, refetchList }) {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const [productData, priceLog] = useGetOneProduct(reuseModalState)
	const { handleSubmit, register, reset } = useForm()
	const dispatch = useDispatch()

	useEffect(() => {
		if (reuseModalState.type && reuseModalState.id) {
			reset()
			onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reuseModalState])

	useEffect(() => {
		return () => {
			resetModal && resetModal()
		}
	}, [resetModal])

	async function onSubmit(formData) {
		const productService = new ProductService()
		let result

		if (reuseModalState.type === '保留' || reuseModalState.type === '取消保留') {
			result = await productService.changeReserveState(reuseModalState.id, formData)
		}

		if (reuseModalState.type === '刪除') {
			result = await productService.deleteProduct(reuseModalState.id)
		}
		if (reuseModalState.type === '定價') {
			result = await productService.changeProductPrice(reuseModalState.id, formData)
		}
		if (reuseModalState.type === '進價') {
			result = await productService.changeProductPurchasePrice(reuseModalState.id, formData)
		}
		if (reuseModalState.type === '庫位') {
			result = await productService.updateProductShelf(reuseModalState.id, formData)
		}

		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, code: data.code, status: status }))
				refetchList()
				reset()
				onClose()
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalContent
						maxW={
							reuseModalState.type === '保留' || reuseModalState.type === '刪除' || reuseModalState.type === '取消保留' ? '400px' : '700px'
						}
						className="modal-content"
					>
						<ModalHeader textAlign="center" fontSize="2em" my=".5em" className="modal-header">
							{modalTitleSwitcher(reuseModalState.type)}
						</ModalHeader>
						<ModalCloseButton className="modal-close" />
						{productData.isFetching ? (
							<ModalBody textAlign="center" d="flex" flexWrap="wrap" justifyContent="center">
								<Box fontSize="2em" mb="1em" w="100%">
									資料載入中...
								</Box>
								<Spinner size="xl" mb="1em" />
							</ModalBody>
						) : (
							<ModalBody textAlign="center">
								{reuseModalState.type === '定價' || reuseModalState.type === '進價' ? (
									<>
										<StyledGridTable>
											<Box>時間</Box>
											<Box>修改者</Box>
											<Box>{(reuseModalState.type === '定價' && '售價') || reuseModalState.type}</Box>
											<Box>說明</Box>
											{priceLog.length > 0
												? priceLog.map((item, i) => (
														<Fragment key={i}>
															<Input value={item.create_time} mb=".5em" disabled />
															<Input value={item.user} mb=".5em" disabled />
															<Input value={item.price} mb=".5em" disabled />
															<Input value={item.reason} mb=".5em" disabled />
														</Fragment>
												  ))
												: null}
											<Input defaultValue="系統產生" disabled />
											<Input defaultValue={getCookie('userName')} disabled />
											<Input {...register('price')} required />
											<Input {...register('reason')} />
										</StyledGridTable>
									</>
								) : null}

								{reuseModalState.type === '標籤' ? (
									<Box d="flex" justifyContent="center" className="tag">
										<React.StrictMode>
											<Barcode fontSize={100} width={10} height={200} value={reuseModalState.sale_price.toString()} />
										</React.StrictMode>
									</Box>
								) : null}
								{reuseModalState.type === '保留' || reuseModalState.type === '取消保留' ? (
									<>
										<Box fontSize="1.3em" color="gray.700">
											<Text mb=".5em">{reuseModalState.no}</Text>
											<Text mb=".5em">{reuseModalState.name}</Text>
										</Box>
										<Box maxW="200px" mx="auto">
											{/* <FormLabel>保留說明</FormLabel> */}
											<StyledFormLabel tag="保留說明" />
											<Input
												isDisabled={reuseModalState.type === '保留' ? false : true}
												{...register('reason', { value: reuseModalState.reserve_reason })}
											/>
										</Box>
										<Input {...register('status', { value: !reuseModalState.reserve_status })} d="none" />
									</>
								) : null}
								{reuseModalState.type === '庫位' ? (
									<>
										<Box fontSize="1.3em" color="gray.700" display="flex" justifyContent="space-around">
											<Box>
												<StyledFormLabel tag="區域" />
												<Input {...register('area')} placeholder="請輸入區域" />
											</Box>
											<Box mx="1rem">
												<StyledFormLabel tag="架號" />
												<Input {...register('cabinet')} placeholder="請輸入架號" />
											</Box>
											<Box>
												<StyledFormLabel tag="件數 (僅允許數字)" />
												<Input {...register('piece')} type="number" placeholder="請輸入件數" />
											</Box>
										</Box>
									</>
								) : null}
								{reuseModalState.type === '刪除' ? (
									<>
										<Box fontSize="1.3em" color="gray.700">
											<Text mb=".5em">{reuseModalState.no}</Text>
											<Text mb=".5em">{reuseModalState.name}</Text>
										</Box>
									</>
								) : null}
							</ModalBody>
						)}
						{reuseModalState.type !== '標籤' ? (
							<ModalFooter justifyContent="center" mb="2em" mt="3em">
								<StyledButtonBox onClick={onClose} mr="1em">
									取消
								</StyledButtonBox>
								<FormSubmitButton type="submit">確定</FormSubmitButton>
							</ModalFooter>
						) : null}
					</ModalContent>
				</StyledForm>
			</Modal>
		</>
	)
}
