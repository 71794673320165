import { Box, useToast, Input } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { useForm } from 'react-hook-form'
import { saleOrderContext, saleTableContext } from './useContext/createContext'
import { useContext, useState } from 'react'
import { StyledButtonBox } from 'utils/StyledButton'
import PaymentSelect from './components/PaymentSelect'
import LocationSelect from './components/LocationSelect'
import SaleService from 'Api/sale/saleService'
import { error, success, warning } from 'reducers/toast'
import { useDispatch } from 'react-redux'
import AddSaleSheetHead from './components/AddSaleSheetHead'
import AddSaleSheet from './components/AddSaleSheet'
import Subtotal from './components/Subtotal'
import SubmitAlert from './components/SubmitAlert'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
	background-color: white;

	td,
	th {
		min-width: 80px;
		max-width: 160px;
	}
`

const StyledForm = styled.form`
	width: 100%;
`

export default function SubContent() {
	const { setEditState } = useContext(saleOrderContext)
	const { register, handleSubmit, setValue } = useForm()
	const [tableData, setTableData] = useState([])
	const [discountPrice, setDiscountPrice] = useState()
	const [discountReason, setDiscountReason] = useState('')
	// 新增銷貨單內的員工編號欄位
	const [employeeNo, setEmployeeNo] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const dispatch = useDispatch()

	const submitHandler = async (formData) => {
		// validate 流程 start
		if (tableData.length <= 0) {
			toast({ title: '請至少新增一件商品', status: 'warning' })
			return
		}
		const merchantIdArray = []
		let totalPrice = 0
		for (let i = 0; i < tableData.length; i++) {
			merchantIdArray.push(tableData[i].id)
			if (tableData[i].sale_price !== '未訂價') {
				totalPrice += tableData[i].sale_price
			}
		}
		if (discountPrice) {
			totalPrice = parseInt(discountPrice)
		}
		if (discountPrice && !discountReason && discountPrice === totalPrice) {
			toast({ title: '若有提供折扣，請輸入折扣原因', status: 'warning' })
			return
		}
		if (!discountPrice) {
			formData.reason = ''
		} else {
			formData.reason = discountReason
		}
		formData.product_ids = merchantIdArray.join(',')
		formData.sale_order_price = totalPrice

		if (!formData.payment) {
			toast({ title: '付款方式為必填', status: 'warning' })
			return
		}

		formData.payment = parseInt(formData.payment)

		if (formData.payment === 0) {
			formData.payment_detail = null
		}

		if (!formData.user_no) {
			toast({ title: '請輸入員工編號', status: 'warning' })
			return
		}

		if (discountPrice === 0) {
			toast({ title: '折扣後總價不可為0', status: 'warning' })
			return
		}

		if (discountPrice && typeof discountPrice === 'number') {
			const isSomePriceFloatInt = floatIntPriceChecker(tableData, discountPrice)
			if (isSomePriceFloatInt) {
				toast({ title: '商品售價不可含有小數點', status: 'warning' })
				return
			}
		}

		// validate 流程 end
		setIsLoading(true)
		const saleService = new SaleService()
		const result = await saleService.createSaleOrder(formData)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				setEditState({ editType: 'viewList', date: {} })
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
		setIsLoading(false)
	}

	const floatIntPriceChecker = (tableData = [], discountPrice = 0) => {
		const total = tableData.reduce((acc, cur) => {
			return acc + cur.sale_price
		}, 0)
		const discountPriceForEachItem = (total - discountPrice) / tableData.length

		return tableData.some((item) => !Number.isInteger(parseFloat(item.sale_price - discountPriceForEachItem)))
	}

	const value = {
		tableData,
		setTableData,
		discountPrice,
		setDiscountPrice,
		setDiscountReason,
		discountReason,
		employeeNo,
		setEmployeeNo,
	}
	return (
		<saleTableContext.Provider value={value}>
			<StyledTransitionBg>
				<AddSaleSheetHead />
				<AddSaleSheet />
				<StyledForm autoComplete="off">
					<Box d="flex" justifyContent="space-between" mt="3em">
						<Box d="flex">
							<PaymentSelect register={register} />
							<LocationSelect register={register} setValue={setValue} />
						</Box>
						<Subtotal />
					</Box>

					{/* 這個欄位的值，是依賴於AddSaleSheetHead內的input */}
					<Input isRequired {...register('user_no', { value: employeeNo })} visibility="hidden" display="none" />

					<Box textAlign="center" py="1em" d="flex" justifyContent="center">
						<StyledButtonBox mx=".5em" onClick={() => setEditState((pre) => (pre = { editType: 'viewList', date: {} }))}>
							取消
						</StyledButtonBox>
						<SubmitAlert handleSubmit={handleSubmit} submitHandler={submitHandler} loadingState={isLoading} />
					</Box>
				</StyledForm>
			</StyledTransitionBg>
		</saleTableContext.Provider>
	)
}
