import { LocationService } from 'Api/location/locationService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { warning } from 'reducers/toast'

export default function useGetLoginLocation() {
	const [locationOptions, setLocationOptions] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const locationService = new LocationService()
			const result = await locationService.getLoginLocation()
			const { status, data } = result
			switch (status) {
				case 200:
					setLocationOptions(data.response.data)
					break
				default:
					dispatch(warning({ message: data.message, code: data.code }))
			}
		}

		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return [locationOptions]
}
