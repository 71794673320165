import ReportService from 'Api/report/reportService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetBrandsSalesData() {
	const [defaultData, setDefaultData] = useState({})
	const [isNeedFetch, setIsNeedFetch] = useState(true)
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const reportService = new ReportService()
			const result = await reportService.getDefaultBrandsSalesData()
			const { status, data } = result
			switch (status) {
				case 200:
					setDefaultData((pre) => (pre = { ...data.response }))
					break
				case 400:
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
			setIsNeedFetch(false)
		}
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return [defaultData, setIsNeedFetch]
}
