import React, { useContext } from 'react'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { storeSalesContext } from './useContext/createContext'
import { FormSubmitButton } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
		top: 0;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
`

export default function TableTopBar() {
	const { editState, setIsNeedFetch } = useContext(storeSalesContext)
	const { handleSubmit } = useForm()

	function onSubmit() {
		setIsNeedFetch((pre) => (pre = true))
	}

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex" alignItems="center">
				庫存類別報表
			</Box>
			{editState.editType === 'viewList' ? (
				<Box d="flex">
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">獲取類別庫存資料</Text>
						</FormSubmitButton>
					</StyledForm>
					{/* <StyledButtonBox className="createSaleOrder" onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'create' }))}>
						<HiPlusSm />
						新增銷退單
					</StyledButtonBox> */}
				</Box>
			) : null}
			{/* {editState.editType === 'print' ? (
				<StyledButtonBox onClick={() => window.print()}>
					<MdPrint /> 列印
				</StyledButtonBox>
			) : null} */}
		</StyledTopbar>
	)
}
