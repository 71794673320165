import { Box, Input, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react'
import { ProductService } from 'Api/product/productService'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { FormSubmitButton } from 'utils/StyledButton'
import StyledFormLabel from 'utils/StyledFormLabel'
import { saleTableContext } from '../useContext/createContext'

export default function AddProductForm() {
	const { handleSubmit, register } = useForm()
	const { setTableData } = useContext(saleTableContext)
	const dispatch = useDispatch()
	const focusRef = useRef()
	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => {
		setIsOpen(false)
		setReserveWording('')
	}
	const [reserveWording, setReserveWording] = useState('')

	async function obSubmit(formData) {
		const productService = new ProductService()
		const result = await productService.getOneProductForSale(formData.product_id)
		const { data, status } = result
		switch (status) {
			case 200:
				setTableData((pre) => (pre = [...pre, data.response]))
				if (data.response.reserve_status) {
					// 商品如果保留的話，會變成跳alert 而不是 toast
					setReserveWording(`此倉品已保留，確定要售出嗎? (${data.response.reserve_reason})`)
					setIsOpen(true)
				} else {
					dispatch(success({ message: data.message, status: status, code: data.code }))
				}
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, code: data.code }))
				break
		}

		focusRef.current.focus()
		focusRef.current.value = ''
	}

	return (
		<>
			<form onSubmit={handleSubmit(obSubmit)} autoComplete="off">
				<StyledFormLabel tag="銷貨商品" isRequired />
				<Box d="flex">
					<Input id="increaseInput" placeholder="請刷條碼" maxW="170px" mr="1em" {...register('product_id')} isRequired ref={focusRef} />
					<FormSubmitButton type="submit">下一筆</FormSubmitButton>
				</Box>
			</form>
			<AlertDialog isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							系統保留通知
						</AlertDialogHeader>

						<AlertDialogBody>{reserveWording}</AlertDialogBody>

						<AlertDialogFooter>
							<Button colorScheme="orange" onClick={onClose} ml={3}>
								確定
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
