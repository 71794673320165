import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import TableContent from './TableContent'
import TableTopBar from './TableTopBar'
import { SelectOptionsProvider } from './useContext/createContext'
import SubContent from './SubContent'
import { useQuery } from '@tanstack/react-query'
import { getSelectOptionsData } from 'Api/purchaseOrder/purchaseOrderService'
import { getProductList } from 'Api/product/productService'

const Container = styled(Box)`
	${'' /* height: 100%; */}
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;
`

export default function Table() {
	const matchIndex = useRouteMatch('/admin/stock/merchant')
	const matchEditPage = useRouteMatch('/admin/stock/merchant/:id')

	const [page, setPage] = useState(1)
	const [advancedSearchParams, setAdvancedSearchParams] = useState({})

	const {
		data: listData,
		isFetching,
		refetch,
	} = useQuery({
		queryKey: ['getProductList', page, advancedSearchParams],
		queryFn: () => getProductList(page, advancedSearchParams),
	})

	const { data } = useQuery({ queryKey: ['getSelectOptionsData'], queryFn: getSelectOptionsData })
	const selectOptionsData = data?.data?.response?.data

	useEffect(() => {
		setPage(1)
	}, [advancedSearchParams])

	const getPageState = () => {
		const isOnListPage = matchIndex?.isExact
		const isOnEditPage = matchEditPage?.isExact && !!matchEditPage?.params?.id

		if (isOnListPage) return 'viewList'
		if (isOnEditPage) return 'edit'
	}

	const pageState = getPageState()

	const setAdvancedSearchParamsHandler = (params) => {
		setAdvancedSearchParams((pre) => (pre = { ...pre, ...params }))
	}

	const handleRefetchList = () => {
		refetch()
	}

	return (
		<SelectOptionsProvider value={{ selectOptionsData }}>
			<Container>
				<TableTopBar setAdvancedSearchParamsHandler={setAdvancedSearchParamsHandler} pageState={pageState} />
				{pageState === 'viewList' && <TableContent data={listData} setPage={setPage} isFetching={isFetching} />}
				{pageState === 'edit' && <SubContent handleRefetchList={handleRefetchList} />}
			</Container>
		</SelectOptionsProvider>
	)
}
