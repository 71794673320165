import { Box, useDisclosure } from '@chakra-ui/react'
import TableTopBar from 'components/tableTopBar'
import styled from 'styled-components'
import List from './list'
import { useQuery } from '@tanstack/react-query'
import { getInventoryList } from 'Api/inventory'
import { useState } from 'react'
import { StyledButtonBox } from 'utils/StyledButton'
import { HiPlusSm } from 'react-icons/hi'
import { useRouteMatch } from 'react-router-dom'
import EditInventoryPage from './editInventoryPage'
import DetailPage from './detailPage'
import CreateInventoryModal from './CreateInventoryModal'

const Container = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;
`

const InventoryTable = () => {
	const matchEditPage = useRouteMatch('/admin/inventory/inventorySheet/edit/:id')
	const matchDetailPage = useRouteMatch('/admin/inventory/inventorySheet/detail/:id')
	const matchListPage = useRouteMatch('/admin/inventory/inventorySheet')

	const isOnEditPage = !!matchEditPage
	const isOnDetailPage = !!matchDetailPage
	const isOnListPage = !!matchListPage.isExact

	const [page, setPage] = useState(1)
	const { data, refetch: refetchList, isFetching } = useQuery(['getInventoryList', page], { queryFn: getInventoryList(page) })
	const { isOpen, onOpen, onClose } = useDisclosure()

	const listData = data?.data?.response?.data
	const maxListPage = data?.data?.response?.total_page

	const goToPage = (page) => {
		setPage(page)
	}

	// const createInventory = () => {
	// 	const confirm = window.confirm('確定要建立盤點報表嗎？')
	// 	if (confirm) createInventoryMutation.mutate()
	// }

	const renderPage = () => {
		if (isOnListPage) {
			return (
				<List data={listData} goToPage={goToPage} maxPage={maxListPage} page={page} isFetchingList={isFetching} refetchList={refetchList} />
			)
		} else if (isOnEditPage) {
			return <EditInventoryPage />
		} else if (isOnDetailPage) {
			return <DetailPage />
		}
	}

	const getSubtitle = () => {
		if (isOnEditPage) return '編輯盤點表單'
		if (isOnDetailPage) return '盤點報表詳細資料'
	}

	return (
		<Container>
			<TableTopBar title="盤點管理報表" subtitle={getSubtitle()}>
				{isOnListPage && (
					<>
						<StyledButtonBox onClick={onOpen}>
							<HiPlusSm />
							建立盤點報表
						</StyledButtonBox>
						{isOpen && <CreateInventoryModal isOpen={isOpen} onClose={onClose} refetchList={refetchList} />}
					</>
				)}
			</TableTopBar>
			{renderPage()}
		</Container>
	)
}

export default InventoryTable
