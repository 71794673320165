import Axios from 'Api/interceptors'
import { getCookie } from 'utils/cookies'

export const getRePurchaseOrderList = (page) => async () => {
	return await Axios.get(`/renewOrders?page=${page}`)
}

export async function postRePurchaseOrder({ product_ids }) {
	return await Axios.post('/renewOrders', {
		location_id: getCookie('_l'),
		product_ids,
	})
}

export async function getSaleReturnProduct(productId) {
	return await Axios.get(`/saleReturnOrders/${productId}/product`)
}
