import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import logo from 'assets/images/logo.svg'

export default function ErrorPage() {
	return (
		<Box p="1em" borderRadius="10px" bg="white" textAlign="center" fontSize="1.5em">
			<Image src={logo} opacity=".5" w="50%" mx="auto" mb="1em" />
			<Box>查無此廠商資料</Box>
			<Box>請與來電與客服人員聯繫!</Box>
		</Box>
	)
}
