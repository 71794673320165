import React, { useCallback, useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Select from 'react-select'
import styled from 'styled-components'
import { FormSubmitButton } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import useGetLocationSelect from 'hook/saleTable/useGetLocationSelect'
const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0 1em 0 0;
	border-radius: 5px;
	align-items: flex-stretch;
	margin-bottom: 1em;
	position: relative;
`

const StyledTitle = styled(Box)`
	position: relative;
	padding-left: 0.8em;
	font-size: 1.25em;
	font-weight: 700;
	overflow: hidden;
	border-radius: 5px;
	display: flex;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
		top: 0;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
`

export default function TableTopBar({formattedDate, setLocationIdStr}) {
	const [locationIds, setLocationIds] = useState([])
	const { handleSubmit } = useForm()
	const [locationData] = useGetLocationSelect()
	const optionArray = useCallback(() => ArrayFilter(locationData), [locationData])

	function ArrayFilter(arr) {
		let filteredArr = []
		arr.forEach((item) => {
			if (item.is_on_sale_order === 1) {
				filteredArr.push({ value: item.id, label: item.name })
			}
		})
		return filteredArr
	}

	function onSubmit() {
		setLocationIdStr(locationIds.value)
	}

	return (
		<StyledTopbar className="topbar">
			<StyledTitle>當日銷售報表</StyledTitle>

			<Box d="flex" my="0.5rem">
				<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<Box mr="1em">
						<Text>日期：{formattedDate}</Text>
					</Box>
					<Box mr="1em">
						<Select
							styles={{ container: (provided) => ({ ...provided, minWidth: 200 }) }}
							options={optionArray()}
							closeMenuOnSelect={true}
							value={locationIds}
							placeholder="請選擇通路"
							onChange={(value) => {
								setLocationIds(value)
							}}
						/>
					</Box>
					<FormSubmitButton flexShrink="0" type="submit">
						<AiOutlineSearch />
						<Text ml="5px">搜尋</Text>
					</FormSubmitButton>
				</StyledForm>
			</Box>
		</StyledTopbar>
	)
}
