import React from 'react'
import { Box, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Image } from '@chakra-ui/react'
import ImageUploader from './uploadSignedSheet/ImageUploader'

export default function PictureModal({ imageUrl, name, sheetId }) {
	const { onOpen, isOpen, onClose } = useDisclosure()
	return (
		<>
			<Box onClick={onOpen}>{name}</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent maxW="100vw" w="400px">
					<ModalBody>{sheetId ? <ImageUploader sheetId={sheetId} /> : <Image src={imageUrl ? imageUrl : 'https://fakeimg.pl/350x200/?text=待提供照片&font=noto'} w="100%" maxW="98vw" />}</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
