import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './components/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import useGetStockReturnDetail from 'hook/returnTable/useGetStockReturnDetail'
import { useHistory, useParams } from 'react-router-dom'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}
	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.precautions {
				display: block;
				padding-bottom: 3em !important;

				ul {
					list-style: none;
				}
			}
		}
		@page {
			size: A4 portrait;
		}
	}
`

export default function DisplayContent() {
	const { id } = useParams()
	const history = useHistory()
	const [orderData] = useGetStockReturnDetail(id)

	const goBackToList = () => {
		history.push('/admin/return/stockReturn')
	}

	return (
		<StyledTransitionBg>
			<Box textAlign="center">
				<Box className="title" fontSize="2em" mb=".7em">
					OPMM寄退單
				</Box>
			</Box>
			{orderData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{orderData.data.id ? (
						<>
							<Box className="info" mb="1em">
								<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
									<Box>
										<Box>寄退單號：{orderData.data.order_no}</Box>
										<Box>寄退日期：{orderData.data.date}</Box>
									</Box>
									<Box>
										<Box>廠商姓名：{orderData.data.vendor}</Box>
										<Box>廠商電話：{orderData.data.vendor_phone}</Box>
									</Box>
									<Box>
										<Box>經辦編號：{orderData.data.user_no}</Box>
										<Box>經辦通路：{orderData.data.user_phone}</Box>
									</Box>
								</Box>
							</Box>
							<DisplayTable products={orderData.data.products} />
							<Box d="flex" justifyContent="flex-end" my="2em">
								<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em">
									寄賣總額
									<Box d="flex" alignItems="flex-end" ml="1.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
										$ {orderData.data.total}
									</Box>
								</Box>
								<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em" mx="3rem">
									手續費
									<Box d="flex" alignItems="flex-end" ml="1.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
										$ {orderData.data.fee}
									</Box>
								</Box>
							</Box>
						</>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}
				</>
			)}

			<Box className="success-button" d="flex" justifyContent="center" my="1em">
				<StyledButtonBox onClick={goBackToList}>完成</StyledButtonBox>
			</Box>

			<Box className="precautions" px="3em" display="none">
				<Box fontSize="1.5em">
					<Box mt="4rem" mb="2rem">
						<Box>寄賣商品現況需當面確認完畢，出店後一概不負責。</Box>
					</Box>
					<Box>簽收人：＿＿＿＿＿＿＿＿＿＿ （___/___/___） </Box>
				</Box>
			</Box>
		</StyledTransitionBg>
	)
}
