const { FaCaretSquareDown, FaTrashAlt } = require('react-icons/fa')
const { BiCabinet } = require('react-icons/bi')
const { MdAttachMoney, MdLocalPrintshop } = require('react-icons/md')
const { AiOutlineMoneyCollect } = require('react-icons/ai')

export function modalIconSwitcher(type) {
	switch (type) {
		case '進價':
			return <AiOutlineMoneyCollect />
		case '定價':
			return <MdAttachMoney />
		case '標籤':
			return <MdLocalPrintshop />
		case '保留':
			return <FaCaretSquareDown />
		case '庫位':
			return <BiCabinet />
		case '刪除':
			return <FaTrashAlt color="#EB9191" />
		default:
			return
	}
}

export function modalTitleSwitcher(type) {
	switch (type) {
		case '進價':
			return '進價修改'
		case '定價':
			return '售價新增/修改'
		case '標籤':
			return '商品標籤'
		case '保留':
			return '是否確定保留?'
		case '取消保留':
			return '是否取消保留?'
		case '刪除':
			return '是否確定刪除?'
		case '庫位':
			return '商品庫位紀錄'
		default:
			return
	}
}

export function updateModalWordingSwitcher(type) {
	switch (type) {
		case 'color_id':
			return '顏色'
		case 'size_id':
			return '尺寸'
		case 'condition_id':
			return '狀況'
		case 'brand_id':
			return '品牌'
		case 'subtypes':
			return '子分類'
		case 'locations':
			return '位置'
		default:
			return
	}
}

export function updateTypeToApiRoute(type) {
	switch (type) {
		case 'color_id':
			return 'colors'
		case 'size_id':
			return 'sizes'
		case 'condition_id':
			return 'conditions'
		case 'brand_id':
			return 'brands'
		default:
			return type
	}
}
