import { Box, Button } from '@chakra-ui/react'
import styled from 'styled-components'

/**
 * CTA 按鈕樣式先建好，若未來有需要的話，在component多isCTA的屬性就會變成CTA按鈕
 */
export const StyledButtonBox = styled(Box)`
	border: 1px solid #2250a6;
	display: flex;
	border-radius: 20px;
	padding: 0.4em 1.2em;
	align-items: center;
	color: ${(props) => (props.isCTA ? 'white' : '#2250a6')};
	font-weight: 700;
	cursor: pointer;
	background-color: ${(props) => (props.isCTA ? '#2250a6' : 'white')} !important;
	&:hover {
		background-color: ${(props) => (props.isCTA ? 'white' : '#2250a6')} !important;
		color: ${(props) => (props.isCTA ? '#2250a6' : 'white')} !important;
	}
`
export const StyledPaginationButton = styled(Button)`
	border: 1px solid #2250a6;
	display: flex;
	border-radius: 20px;
	padding: 0.4em 1.2em;
	align-items: center;
	color: #2250a6;
	font-weight: 700;
	cursor: pointer;
	background: white !important;
	height: 2em !important;
	margin: 0.1em 0.2em;

	&:hover {
		background-color: #2250a6 !important;
		color: white;
	}
`

/**
 * CTA 按鈕樣式先建好，若未來有需要的話，在component多isCTA的屬性就會變成CTA按鈕
 */
export const FormSubmitButton = styled(Button)`
	border: 1px solid #2250a6 !important;
	box-shadow: none !important;
	color: ${(props) => (props.isCTA ? 'white' : '#2250a6')};
	border-radius: 20px !important;
	font-weight: 700 !important;
	padding: 0.4em 1.2em !important;
	background-color: ${(props) => (props.isCTA ? '#2250a6' : 'white')} !important;
	&:hover {
		background-color: ${(props) => (props.isCTA ? 'white' : '#2250a6')} !important;
		color: ${(props) => (props.isCTA ? '#2250a6' : 'white')} !important;
	}
`
export const StyledDeleteButton = styled(Box)`
	border: 1px solid red;
	display: flex;
	border-radius: 20px;
	padding: 0.4em 1.2em;
	align-items: center;
	color: red;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		background-color: red;
		color: white;
	}
`
