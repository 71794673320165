import { createSlice } from '@reduxjs/toolkit'

export const sidebarShrink = createSlice({
	name: 'sidebarWidth',
	initialState: false,
	reducers: {
		toggle: (state, action) => {
			return !state
		},
	},
})

export const { toggle } = sidebarShrink.actions
export default sidebarShrink.reducer
