import { Box, Spinner } from '@chakra-ui/react'
// import VendorService from 'Api/vendor/vendorService'
import React from 'react'
// import { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
// import { error, success, warning } from 'reducers/toast'
import styled from 'styled-components'
import VendorProductTable from './VendorProductTable'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

export default function DisplayTable({ productData, isLoading }) {
	return (
		<>
			{!isLoading ? (
				<StyledTable>
					{productData.length > 0 ? (
						<VendorProductTable tableData={productData} />
					) : (
						<Box textAlign="center" my="2em" fontSize="1.3em">
							此廠商目前沒有寄賣商品
						</Box>
					)}
				</StyledTable>
			) : (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			)}
		</>
	)
}
