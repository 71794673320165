import Axios from 'Api/interceptors'

export class ColorService {
	async createColor(payload) {
		let result
		try {
			result = await Axios.post('/colors', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateColor(id, payload) {
		let result
		try {
			result = await Axios.put('/colors/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteColor(id) {
		let result
		try {
			result = await Axios.delete('/colors/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}
