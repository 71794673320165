import Axios from 'Api/interceptors'
import { ProductService } from 'Api/product/productService'
// import { getCookie } from 'utils/cookies'

export class PrintTagService {
	async printTag(product_id) {
		let result
		try {
			const productService = new ProductService()
			const productResult = await productService.getOneProduct(product_id)
			const { data } = productResult
			const { no, brand_name, condition_name, sale_price, size_name, id } = data.response
			console.log(data)
			let localData = {
				no: no,
				condition: condition_name,
				price: sale_price,
				// type的位置改成size_name，printer_server的req欄位沒有改，直接用原來的type欄位
				type: size_name,
				id: id,
				name: brand_name,
			}
			const localResult = await Axios.post('http://localhost:4444/print', localData)
			console.log(localResult)

			// 本來打算送ID到樹梅派，但最後無法解決樹梅派方面的ISSUE而作罷
			// const locationId = getCookie('_l')
			// result = await Axios.get(`/products/${product_id}/printer?location_id=${locationId}`)
		} catch (error) {
			result = error
		}
		return result
	}
}
