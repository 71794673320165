import Axios from 'Api/interceptors'

export default class usersTableService {
	async getUserList(page, keyword) {
		let result
		try {
			if (!keyword) result = await Axios.get('/users?page=' + page)
			if (keyword) result = await Axios.get('/users?page=' + page + '&keyword=' + keyword)
		} catch (error) {
			result = error
		}
		return result
	}

	async createUser(payload) {
		let result
		try {
			result = await Axios.post('/users', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOneUser(userId) {
		let result
		try {
			result = await Axios.get('/users/' + userId)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateUser(payload, userId) {
		let result
		try {
			result = await Axios.put('/users/' + userId, payload)
		} catch (error) {
			result = error
		}
		return result
	}
	async deleteUser(userId) {
		let result
		try {
			result = await Axios.delete('/users/' + userId)
		} catch (error) {
			result = error
		}
		return result
	}
}
