import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import TableContent from './TableContent'
import TableTopBar from './TableTopBar'
import { PurchaseProvider, SelectOptionsProvider } from './useContext/createContext'
import SubContent from './SubContent'
import DisplayContent from './DisplayContent'
import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getPurchaseOrderList, getSelectOptionsData } from 'Api/purchaseOrder/purchaseOrderService'
import { useDispatch } from 'react-redux'
import { warning } from 'reducers/toast'

const StyledTable = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;

	@media print {
		& {
			box-shadow: none;
			padding: 0;
		}
	}

	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	const matchList = useRouteMatch('/admin/purchase/purchaseSheet')
	const matchCreate = useRouteMatch('/admin/purchase/purchaseSheet/:action')
	const matchPrint = useRouteMatch('/admin/purchase/purchaseSheet/order/:id')
	const dispatch = useDispatch()

	const [printType, setPrintType] = useState('')
	const [params, setParams] = useState({ page: 1, startDate: '', endDate: '', phone: '' })

	const {
		data: queryData,
		isFetching,
		refetch: refetchList,
		error,
	} = useQuery({
		queryKey: ['getTableList', params],
		queryFn: getPurchaseOrderList(params),
	})
	const { data, refetch: refetchOptions } = useQuery(['selectOptionsData'], { queryFn: getSelectOptionsData })

	const selectOptionsData = data?.data?.response?.data

	useEffect(() => {
		if (error?.status === 400) {
			dispatch(warning({ message: error?.data?.message, status: error?.status, code: error?.data?.code }))
		}
	}, [error, dispatch])

	const value = {
		printType,
		setPrintType,
		setParams,
		refetchList,
	}

	const selectOptionsValue = {
		selectOptionsData,
		refetchOptions,
	}

	const isOnListPage = matchList?.isExact
	const isOnCreatePage = matchCreate?.isExact && matchCreate?.params?.action === 'create'
	const isOnPrintPage = matchPrint?.isExact && !!matchPrint?.params?.id

	const gotoPage = (page) => {
		setParams({ ...params, page })
	}

	const renderPage = () => {
		if (isOnListPage) {
			return <TableContent queryData={queryData} isFetching={isFetching} gotoPage={gotoPage} />
		}
		if (isOnPrintPage) {
			return <DisplayContent />
		}
		if (isOnCreatePage) {
			return <SubContent />
		}
	}

	const getType = () => {
		if (isOnListPage) {
			return 'viewList'
		}
		if (isOnCreatePage) {
			return 'create'
		}
		if (isOnPrintPage) {
			return 'print'
		}
	}

	return (
		<PurchaseProvider value={value}>
			<SelectOptionsProvider value={selectOptionsValue}>
				<StyledTable>
					<TableTopBar pageState={getType()} />
					{renderPage()}
				</StyledTable>
			</SelectOptionsProvider>
		</PurchaseProvider>
	)
}
