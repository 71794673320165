import {
	Box,
	Stack,
	Skeleton,
	Image,
	FormControl,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'
import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import StyledFormLabel from 'utils/StyledFormLabel'
import styled from 'styled-components'
import useGetVendorDetail from 'hook/vendorPage/useGetVendorDetail'
import { StyledButtonBox } from 'utils/StyledButton'
import { useLocation } from 'react-router-dom'

const StyleBox = styled(Box)`
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	padding: 0 0.5rem 1.2rem 0;
`

const InfoBox = styled(Box)`
	border-bottom: 1px solid;
	padding-bottom: 0.5rem;
	margin-bottom: 0.5rem;
`

// 目前不引用這個component，因為修改廠商的功能被取消
export default function VendorInfoCannotEdit() {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { search } = useLocation()
	const code = new URLSearchParams(search).get('code')
	const [vendorDetail, isFetching] = useGetVendorDetail(isOpen, code)

	function closeAndResetModal() {
		onClose()
	}
	return (
		<>
			<Box
				onClick={onOpen}
				cursor="pointer"
				p="0.5em"
				background="#51AB9B"
				transition="all .2s ease"
				_hover={{ bg: 'rgb(75, 136, 150)' }}
				color="white"
				borderRadius="10px"
				mr="1em"
				flex="1"
			>
				<Box fontSize="2em">
					<AiOutlineCheckCircle />
				</Box>
				<Box>會員資料</Box>
				<Box fontSize="10px" transform="scale(0.8)" ml="-1em" mb="0em" mr="-1em">
					補齊第一次沒有確定的個人資料吧！
				</Box>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent maxW="375px">
					<ModalHeader textAlign="center" fontSize="1.5rem">
						會員資料
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody mx=".5rem">
						<Stack spacing="5">
							{!isFetching ? (
								<>
									<FormControl>
										<StyledFormLabel tag="廠商電話" />
										<InfoBox>{vendorDetail.phone}</InfoBox>
									</FormControl>
									<FormControl>
										<StyledFormLabel tag="廠商姓名" />
										<InfoBox>{vendorDetail.name}</InfoBox>
									</FormControl>
									<FormControl>
										{/* API欄位沒變，一樣是line id，但改成紀錄instagram */}
										<StyledFormLabel tag="Instagram" />
										<InfoBox>{vendorDetail.line}</InfoBox>
									</FormControl>
									<FormControl>
										<StyledFormLabel tag="付款方式" />
										{vendorDetail.payment === 0 ? <InfoBox>現金</InfoBox> : null}
										{vendorDetail.payment === 1 ? <InfoBox>匯款</InfoBox> : null}
									</FormControl>
									<FormControl>
										<StyledFormLabel tag="匯款帳號" />
										<InfoBox>{`${vendorDetail.code || '未提供'}(銀行代碼)-${vendorDetail.account || '未提供'}(銀行帳號)`}</InfoBox>
									</FormControl>
									<FormControl>
										<StyledFormLabel tag="廠商證件" />
										<Image src={vendorDetail.image || 'https://fakeimg.pl/170x108/?retina=1&text=未提供&font=noto'} w="70%" mx="auto" />
									</FormControl>
								</>
							) : (
								[1, 2, 3].map((item, i) => (
									<FormControl key={i}>
										<Box display="flex" alignItems="center" mb="0.5rem" color="#C4C4C4">
											<Skeleton height="1rem" w="100px" />
										</Box>
										<StyleBox>
											<Skeleton h="1rem" w="100%" />
										</StyleBox>
									</FormControl>
								))
							)}
						</Stack>
						<Box display="flex" justifyContent="center" my="2rem">
							<StyledButtonBox mr="1rem" onClick={closeAndResetModal}>
								確定
							</StyledButtonBox>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
