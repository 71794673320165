import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import styled from 'styled-components'

const StyledTable = styled.table`
	border-radius: 10px;
	border: 1px solid #cfcfcf;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	overflow: hidden;

	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 100px;
		&:last-child {
			border-right: none;
			min-width: 200px;
		}
	}
`

export default function BasicTable({ pageSize, tableData = [], columns = [], isLoading = false }) {
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
		{
			columns,
			data: tableData,
			initialState: { pageIndex: 0, pageSize: pageSize || 10 },
		},
		useSortBy,
		usePagination
	)

	if (isLoading) {
		return (
			<Box py="2em" d="flex" alignItems="center" flexDir="column">
				<Box fontSize="1.5em">載入資料中...</Box>
				<Spinner size="xl" mt="2em" />
			</Box>
		)
	}

	const isTableDataEmpty = tableData.length === 0

	if (isTableDataEmpty) {
		return (
			<Box fontSize="1.2em" textAlign="center" my="3em">
				目前沒有任何資料載入
			</Box>
		)
	}

	return (
		<StyledTable {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup, g) => (
					<tr {...headerGroup.getHeaderGroupProps()} key={g}>
						{headerGroup.headers.map((column, k) => {
							return (
								<th key={k}>
									<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
										{
											// Render the header
											column.render('Header')
										}
									</Box>
								</th>
							)
						})}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				{
					// Loop over the table rows
					page.map((row, i) => {
						// Prepare the row for display
						prepareRow(row)
						return (
							// Apply the row props
							<tr {...row.getRowProps()} key={i}>
								{
									// Loop over the rows cells
									row.cells.map((cell, j) => {
										// Apply the cell props
										return (
											<td {...cell.getCellProps()} key={j}>
												{
													// Render the cell contents
													cell.render('Cell')
												}
											</td>
										)
									})
								}
							</tr>
						)
					})
				}
			</tbody>
		</StyledTable>
	)
}
