import React from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'
import CreateVendorModal from './CreateVendorModal'
import VendorSearchFormInput from './VendorSearchFormInput'
import useGetVendorByPhone from 'hook/purchaseTable/useGetVendorByPhone'

export default function VendorSearchForm() {
	const { isOpen, onToggle, onClose } = useDisclosure()

	const [vendorList, setVendorPhone] = useGetVendorByPhone()
	return (
		<Box d="flex">
			<VendorSearchFormInput typeData={vendorList} type="vendor" setVendorPhone={setVendorPhone} />
			<Box
				borderRadius="4px"
				fontSize="1.5em"
				d="flex"
				justifyContent="center"
				alignItems="center"
				bgColor="#2B64D0"
				color="white"
				cursor="pointer"
				flexShrink="0"
				w="35px"
				_hover={{ bgColor: '#265abb' }}
				onClick={onToggle}
				ml=".2em"
			>
				<AiOutlinePlus />
			</Box>
			<CreateVendorModal isopen={isOpen} onclose={onClose} />
		</Box>
	)
}
