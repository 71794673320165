import Axios from 'Api/interceptors'

export class ConditionService {
	async createCondition(payload) {
		let result
		try {
			result = await Axios.post('/conditions', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateCondition(id, payload) {
		let result
		try {
			result = await Axios.put('/conditions/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteCondition(id) {
		let result
		try {
			result = await Axios.delete('/conditions/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}
