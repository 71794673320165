import Axios from 'Api/interceptors'

export default class VendorService {
	async createVendor(payload) {
		let result
		try {
			result = await Axios.post('/vendors', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateVendor(id, payload) {
		let result
		try {
			result = await Axios.put('/vendors/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async getVendorList(page, phone) {
		let result
		try {
			if (phone) result = await Axios.get('/vendors/?page=' + page + '&phone=' + phone)
			if (!phone) result = await Axios.get('/vendors/?page=' + page)
		} catch (error) {
			result = error
		}
		return result
	}

	async getVendorListByPhone(phone) {
		let result
		try {
			result = await Axios.get('/vendors/?phone=' + phone)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSingleVendor(id) {
		let result
		try {
			result = await Axios.get('/vendors/' + id)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSingleVendorOpenAuth(phone) {
		let result
		try {
			result = await Axios.get('/purchaseOrders/vendor?phone=' + phone)
		} catch (error) {
			result = error
		}
		return result
	}

	async getAllVendor() {
		let result
		try {
			result = await Axios.get('/vendors')
		} catch (error) {
			result = error
		}

		return result
	}
}
