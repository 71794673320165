import { ReturnService } from 'Api/return/returnService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { success, warning } from 'reducers/toast'

export default function useGetSaleReturnList(date) {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const returnService = new ReturnService()

			let getListData
			if (date.date) getListData = await returnService.getSaleReturnListByDate(date.date.startDate, date.date.endDate, currentPage)
			if (!date.date) getListData = await returnService.getSaleReturnList(currentPage)
			const { status, data } = getListData

			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: [...data.response.data], isFetching: false })
					if (date.startDate) dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					break
			}
		}

		fetchData()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, date || ''])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}

	return [currentPage, maxPage, gotoPage, tableData]
}
