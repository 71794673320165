import { Box, Input } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { useForm } from 'react-hook-form'
import { CheckBoxProvider } from './useContext/createContext'
import { useContext } from 'react'
import SingleAccordion from './component/SingleAccordion'
import { FormSubmitButton } from 'utils/StyledButton'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { useEffect } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { RoleService } from 'Api/role/roleService'
import useGetRoleData from 'hook/roleTable/useGetRoleData'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 2em 2em 0;
	border-radius: 4px;
	background-color: #f9f9f9;
	flex-wrap: wrap;
	justify-content: space-between;
`

const StyledForm = styled.form`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	input[type='checkbox'] {
		border: 1px solid;
		padding: 5px;
		appearance: none;
		outline: none;
	}

	input[type='checkbox']:checked {
		appearance: auto;
		background: none !important;
		color: black !important;
	}
`

export default function SubContent() {
	const { setEditState, editState, setIsNeedFetchList } = useContext(otherTableContext)
	const { register, handleSubmit, setValue } = useForm()
	// permission的checkbox list清單 & user資料都是從這邊出來
	// 如果有提供userId的話就會有userdata，editState.editType也會是edit
	// userData 的 預設值是空object
	const [defaultPermissions, roleData] = useGetRoleData(editState.roleId)
	const dispatch = useDispatch()

	async function onSubmit(formData) {
		let ids = []
		function checkboxResultRecursion(obj) {
			for (let i in obj) {
				if (typeof obj[i] !== 'boolean') {
					checkboxResultRecursion(obj[i])
				}
				if (typeof obj[i] === 'boolean') {
					if (obj[i]) {
						ids.push(i)
					}
				}
			}
		}
		checkboxResultRecursion(formData.checkboxGroup)
		formData.permission_ids = ids.toString()
		delete formData['checkboxGroup']
		delete formData['staffLevel']

		const roleService = new RoleService()
		let result
		if (editState.editType === 'createAuth') result = await roleService.createRole(formData)
		if (editState.editType === 'authEdit') result = await roleService.updateRole(formData, roleData.data.id)

		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				setEditState({ editType: '' })
				setIsNeedFetchList(true)
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
	}

	useEffect(() => {
		if (Object.keys(roleData.data).length > 0) {
			const { name, notes } = roleData.data
			setValue('name', name)
			setValue('notes', notes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleData.isFetching])

	const value = { register, roleData }

	return (
		<CheckBoxProvider value={value}>
			<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Box d="flex" flexWrap="wrap" justifyContent="flex-start" mb="1.5em">
					<Box mr="2em">
						<Box d="flex">
							權限名稱 <Box color="red">*</Box>
						</Box>
						<Input {...register('name')} isRequired />
					</Box>
					<Box>
						<Box d="flex">說明</Box>
						<Input {...register('notes', { value: editState.editType === 'edit' ? '123456789' : null })} />
					</Box>
				</Box>
				<StyledBg overflow="auto">{defaultPermissions ? defaultPermissions.map((items, t) => <SingleAccordion singlepermission={items} key={t} />) : null}</StyledBg>
				<Box textAlign="center" py="1em">
					<FormSubmitButton mr="1em" onClick={() => setEditState((pre) => (pre = { editType: '' }))}>
						取消
					</FormSubmitButton>
					<FormSubmitButton type="submit">儲存</FormSubmitButton>
				</Box>
			</StyledForm>
		</CheckBoxProvider>
	)
}
