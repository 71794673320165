import { AttendanceService } from 'Api/attendance/attendanceService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetAttendanceList(editState) {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()
	const [keyword, setKeyword] = useState('')
	const [isNeedFetch, setIsNeedFetch] = useState(true)
	useEffect(() => {
		async function fetchData() {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const attendanceService = new AttendanceService()
			const getListData = await attendanceService.getList(currentPage, editState.date, editState.keyword)
			const { status, data } = getListData
			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: [...data.response.data], isFetching: false })
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
			setTableData((pre) => (pre = { data: [...pre.data], isFetching: false }))
			setIsNeedFetch(false)
		}
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetch])

	useEffect(() => {
		if (!isNeedFetch) {
			setIsNeedFetch(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, editState])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}

	return [currentPage, maxPage, gotoPage, tableData, setIsNeedFetch, setKeyword, keyword]
}
