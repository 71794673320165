import Axios from './interceptors'

export default class AuthService {
	async login(payload) {
		let result
		try {
			result = await Axios.post('/login', payload)
		} catch (error) {
			result = error
		}

		return result
	}

	async logout() {
		let result
		try {
			//logout不用帶payload
			result = await Axios.post('/logout', {})
		} catch (error) {
			result = error
		}
		return result
	}
}
