import { Box, Input, Select, Textarea, Heading, Checkbox } from '@chakra-ui/react'
import ReactSelect from 'react-select'
import styled, { keyframes } from 'styled-components'
import { useForm, useController } from 'react-hook-form'
import { staffContext, CheckBoxProvider } from './useContext/createContext'
import { useContext } from 'react'
import useGetUserPermission from 'hook/hrTable/useGetPermissionList'
import SingleAccordion from './component/SingleAccordion'
import usersTableService from 'Api/users/users'
import { FormSubmitButton } from 'utils/StyledButton'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { useEffect } from 'react'
import useGetAllRoles from 'hook/hrTable/useGetAllRoles'
import { useState } from 'react'
import useGetLoginLocation from 'hook/useGetLoginLocation'
import useGetPermissionTags from 'hook/hrTable/useGetPermissionTags'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 2em 2em 2em;
	border-radius: 4px;
	background-color: #f9f9f9;
	flex-wrap: wrap;
	justify-content: space-between;
`

const StyledForm = styled.form`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	input[type='checkbox'] {
		border: 1px solid;
		padding: 5px;
		appearance: none;
		outline: none;
	}

	input[type='checkbox']:checked {
		appearance: auto;
		background: none !important;
		color: black !important;
	}
`

const permissionTagsMap = Object.freeze({
	show_product_price: '顯示商品管理進價',
})

export default function SubContent() {
	const { setEditState, editState, setIsNeedFetch } = useContext(staffContext)
	const { register, handleSubmit, setValue, control } = useForm()
	const dispatch = useDispatch()
	// permission的checkbox list清單 & user資料都是從這邊出來
	// 如果有提供userId的話就會有userdata，editState.editType也會是edit
	// userData 的 預設值是空object
	const [defaultPermissions, userData] = useGetUserPermission(editState.userId)
	const [locationOptions] = useGetLoginLocation()
	const { field } = useController({ name: 'location_ids', control })
	const { value, onChange, ...restLocationField } = field
	// locationValue 這邊會存 [{value: 1, label: '西門店'}...], 用來control react-select的值
	const [locationValue, setLocationValue] = useState([])

	// roleListData 和 defaultRoleLevel 是用來拿權限等級列表 跟 select onchange用的
	const [roleListData] = useGetAllRoles()
	const [permissionTagsData] = useGetPermissionTags()
	const [defaultRoleLevel, setDefaultRoleLevel] = useState('')

	async function onSubmit(formData) {
		let ids = []
		function checkboxResultRecursion(obj) {
			for (let i in obj) {
				if (typeof obj[i] !== 'boolean') {
					checkboxResultRecursion(obj[i])
				}
				if (typeof obj[i] === 'boolean') {
					if (obj[i]) {
						ids.push(i)
					}
				}
			}
		}
		checkboxResultRecursion(formData.checkboxGroup)
		formData.permission_ids = ids.toString()

		// 這邊是把permission_tag_ids的formData vavlue object轉成string
		formData.permission_tag_ids = Object.keys(formData.permission_tag_ids)
			.map((key) => {
				if (formData.permission_tag_ids[key]) {
					return permissionTagsData.find((item) => item.name === key)?.id
				}
				return false
			})
			.filter((key) => !!key)
			.join(',')

		delete formData['checkboxGroup']

		const userService = new usersTableService()
		let result
		if (editState.editType === 'create') result = await userService.createUser(formData)
		if (editState.editType === 'edit') result = await userService.updateUser(formData, userData.data.id)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				setIsNeedFetch(true)
				setEditState({ editType: 'viewList' })
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
	}

	useEffect(() => {
		const isDataLoaded = userData.isFetching === false && !!userData?.data?.id

		if (isDataLoaded) {
			// location_ids 會是 '1,2,3,4' 這樣的字串
			const { user_no, name, notes, phone, location_ids = '' } = userData.data
			setValue('user_no', user_no)
			setValue('name', name)
			setValue('notes', notes)
			setValue('phone', phone)

			// setup default location value start
			setLocationValue(() => {
				if (!location_ids) return []

				return location_ids
					.split(',')
					.map((id) => ({ value: id, label: locationOptions.find((option) => option.id === Number(id))?.name }))
					.filter((item) => !!item)
			})
			setValue('location_ids', location_ids)
			// setup default location value end
		}
	}, [userData, locationOptions, setValue])

	const providerValue = { register, userData, defaultRoleLevel }
	const serializedLocationOptions = locationOptions
		.map((option) => ({ label: option.name, value: option.id }))
		// 因為122行的setLocationValue，是不會更新react-select options的，所以要把已經選過的過濾掉，如果之後有看到react-select有相關的更新方法，可以改成新的方式
		.filter((item) => !locationValue.find((value) => parseInt(value.value) === item.value))

	return (
		<CheckBoxProvider value={providerValue}>
			<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gridGap="1rem" justifyContent="space-between" mb="1.5em">
					<Box>
						<Box d="flex">
							員工編號 <Box color="red">*</Box>
						</Box>
						<Input {...register('user_no')} isRequired />
					</Box>
					<Box>
						<Box d="flex">
							員工密碼 <Box color="red">*</Box>
						</Box>
						{editState.editType === 'edit' ? <Input isDisabled type="password" value="不要亂看，這是假密碼" /> : null}

						{editState.editType !== 'edit' ? (
							<Input
								type="password"
								{...register('password')}
								isRequired
								disabled={editState.editType === 'edit' ? true : false}
								autoComplete="off"
							/>
						) : null}
					</Box>
					<Box>
						<Box d="flex">
							員工姓名<Box color="red">*</Box>
						</Box>
						<Input {...register('name')} isRequired />
					</Box>
					<Box>
						<Box d="flex">
							員工電話 <Box color="red">*</Box>
						</Box>
						<Input {...register('phone')} isRequired />
					</Box>
					<Box gridColumnStart="1" gridColumnEnd="3" display="flex">
						<Box>
							<Box d="flex">所屬店家</Box>
							<ReactSelect
								styles={{
									container: (provided) => ({ ...provided, minWidth: 200 }),
									control: (provided) => ({ ...provided, minHeight: 35, height: 35 }),
								}}
								options={serializedLocationOptions}
								closeMenuOnSelect={false}
								isMulti
								placeholder="請選擇通路"
								value={locationValue}
								onChange={(value) => {
									// 這個onChange是用來手動觸發react-hook-form的setValue，因為react-select 沒辦法用react-hook-form的register
									onChange(value.map((item) => item.value).join(','))

									// 這個是用來更新react-select的值，把uncontrolled的react-select改成controlled
									setLocationValue(() => value)
								}}
								{...restLocationField}
							/>
						</Box>
					</Box>
				</Box>
				<StyledBg overflow="auto">
					<Box>
						<Heading as="h3" size="lg">
							頁面權限設定
						</Heading>
						<Box w="100%" mb="2em" display="flex" flexWrap="wrap">
							<Box my="1rem">
								<Box d="flex">權限等級</Box>
								<Select minW="180px" value={defaultRoleLevel} onChange={(e) => setDefaultRoleLevel(e.target.value)}>
									<option value="" disabled>
										預設權限等級
									</option>
									<option value="反選">反選所有權限</option>
									{roleListData.data.map((item, i) => (
										<option key={i} value={item.permission_ids}>
											{item.name}
										</option>
									))}
								</Select>
							</Box>
						</Box>
						<Box d="flex" flexWrap="wrap">
							{defaultPermissions ? defaultPermissions.map((items, t) => <SingleAccordion singlepermission={items} key={t} />) : null}
						</Box>
					</Box>

					<Box>
						<Heading as="h3" size="lg" mb="1rem">
							其他權限設定
						</Heading>
						<Box>
							{
								permissionTagsData.map((item, index) => {
									const isDefaultChecked = !!userData?.data?.permission_tags?.find((tag) => tag.name === item.name)
									return (
										<Checkbox key={index} {...register(`permission_tag_ids.${item.name}`)} defaultChecked={isDefaultChecked}>
											{permissionTagsMap[item.name]}
										</Checkbox>
									)
								})}
						</Box>
					</Box>
				</StyledBg>
				<Box>
					<Box my={['.5em', '1em']}>備註</Box>
					<Textarea {...register('notes')} />
				</Box>
				<Box textAlign="center" py="1em">
					<FormSubmitButton mr="1em" onClick={() => setEditState((pre) => (pre = { editType: 'viewList' }))}>
						取消
					</FormSubmitButton>
					<FormSubmitButton type="submit">儲存</FormSubmitButton>
				</Box>
			</StyledForm>
		</CheckBoxProvider>
	)
}
