import React, { useContext } from 'react'
import { Box } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './components/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import { transferContext } from './useContext/createContext'
import useGetTransferOrderDetail from 'hook/stockTable/useGetTransferOrderDetail'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}
	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.precautions {
				display: block;
				padding-bottom: 3em !important;

				ul {
					list-style: none;
				}
			}
		}
	}
`

export default function DisplayContent() {
	const { setEditState, editState } = useContext(transferContext)
	const [orderData] = useGetTransferOrderDetail(editState.orderId)
	return (
		<StyledTransitionBg>
			<Box textAlign="center">
				<Box className="title" fontSize="2em" mb=".7em">
					OPMM調撥單
				</Box>
			</Box>
			<Box className="info" mb="1em">
				<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
					<Box>
						<Box>調撥單號：{orderData.data.transfer_order_no}</Box>
						<Box>調撥日期：{orderData.data.date}</Box>
					</Box>
					<Box>
						<Box>經辦姓名：{orderData.data.user_no}</Box>
						<Box>經辦電話：{orderData.data.user_phone}</Box>
					</Box>
					<Box>
						<Box>調撥位置：{orderData.data.location}</Box>
						<Box>調撥門市電話：{orderData.data.location_phone}</Box>
					</Box>
				</Box>
			</Box>
			<DisplayTable productList={orderData.data.products || []} />
			<Box textAlign="center">
				<Box d="inline-block" className="success-button" mt="2em">
					<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'viewList' }))}>完成</StyledButtonBox>
				</Box>
			</Box>
			<Box className="precautions" borderTop="1px solid" borderColor="gray.600" px="3em" display="none">
				<Box fontSize="2em" textAlign="center" mt="1em" mb="2em">
					注意事項
				</Box>
				<Box fontSize="1.5em">
					<ul>
						<li>一﹑ 請掃右側QR code, 完成個人資料填寫。</li>
						<li>二﹑ 個人資料填寫（修改）僅限一次，若有任何其他需求， 請來電：0912345678。</li>
						<li>三﹑ 寄賣貨品的狀況, 可隨時透過右側QR code追蹤。</li>
						<li>四﹑ 若有任何問題, 請洽：0912345678, 或門市：台北市西門區西門路1號。</li>
					</ul>
				</Box>
			</Box>
		</StyledTransitionBg>
	)
}
