import { Box, FormLabel, Input, Image } from '@chakra-ui/react'
import ImageService from 'Api/image'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import styled from 'styled-components'
import { FaRegAddressCard } from 'react-icons/fa'
import { useEffect } from 'react'

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`
// 為什麼要把imageUploader往內移一層
// 因為image upload成功之後，用hook存return的image url，會re-render component導致其他已經填寫過的form data變成undefined
export default function ImageUploader({ register, vendorImage }) {
	const dispatch = useDispatch()
	const [imageSrc, setImageSrc] = useState('')

	// 負責處理image上傳
	// 先做一個formdata的instance再塞值進去
	// image用FileReader轉base64的字串後再塞進formdata
	// 成功會return image的url，再用hook存起來，備後續form submit用
	const fileHandler = async (file) => {
		const formData = new FormData()
		const imageBase64 = await imageToBase64(file)
		// image upload 的target 有分product 和 vendor
		formData.append('target', 'vendor')

		function imageToBase64(file) {
			return new Promise((res, rej) => {
				let reader = new FileReader()
				reader.onload = () => {
					res(reader.result)
				}
				reader.readAsDataURL(file)
			})
		}
		formData.append('image', imageBase64)
		const imageService = new ImageService()
		const result = await imageService.uploadImage(formData)
		if (result.status === 200) {
			dispatch(success({ message: result.data.message, status: result.status }))
			setImageSrc(result.data.response.path)
		}
		if (result.status === 400) dispatch(warning({ message: result.message, status: result.status, code: result.code }))
		if (result.status === 500) dispatch(error({ message: result.data.message, status: result.status }))
	}

	useEffect(() => {
		setImageSrc(vendorImage)
	}, [vendorImage])

	// http://35.194.211.164/upload/tmp/vendor/D944F16E-136D-45D6-BDAB-7F093741A3CD.jpg
	// https://storage.googleapis.com/opmm_bucket-1/vendor/D944F16E-136D-45D6-BDAB-7F093741A3CD.jpg

	return (
		<Box className="photoBox">
			<FormLabel d="flex">拍照</FormLabel>
			<Box border="2px dashed" borderColor="gray.600" _hover={{ borderColor: 'gray.700' }} w="180px" h="100px" borderRadius="15px" position="relative">
				<Box position="absolute" w="100%" h="100%" d="flex" justifyContent="center" alignItems="center" flexDirection="column" color="gray.600">
					{imageSrc ? (
						<>
							<Image src={imageSrc} maxH="100%" />
							<Input {...register('image', { value: imageSrc })} d="none" />
						</>
					) : (
						<>
							<Box fontSize="3em">
								<FaRegAddressCard />
							</Box>

							<Box>拍照或上傳身分證</Box>
						</>
					)}
				</Box>
				<StyledInput
					type="file"
					accept="image/*"
					onChange={(e) => {
						if (e.target.files[0]) fileHandler(e.target.files[0])
					}}
					pb="100%"
					border="none"
					opacity="0"
				/>
			</Box>
		</Box>
	)
}
