import Axios from 'Api/interceptors'

export class BrandService {
	// brand、category、size、color、condition、position、auth的get都在Api/otherTable/other.js裡面
	async createBrand(payload) {
		let result
		try {
			result = await Axios.post('/brands', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateBrand(id, payload) {
		let result
		try {
			result = await Axios.put('/brands/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteBrand(id) {
		let result
		try {
			result = await Axios.delete('/brands/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}
