import Axios from 'Api/interceptors'

export const getInventoryList = (page) => () => {
	return Axios.get(`/inventory?page=${page}`)
}

export const getInventorySheetDetail = (id) => () => {
	return Axios.get(`/inventory/${id}/report`)
}

export const postInventory = (locationId) => {
	return Axios.post(`/inventory`, { location_id: locationId })
}

export const closeInventory = (id) => () => {
	return Axios.put(`/inventory/${id}`, { end: true })
}

export const scanInventoryItem = ({ sheetId, productNo }) => {
	return Axios.post(`/inventory/${sheetId}/scan`, { product_no: productNo })
}
