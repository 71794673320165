import TempPurchaseOrdersService from 'Api/tempPurchaseOrder/tempPurchaseOrderService'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetSelectOptionsData() {
	const [selectOptionsData, setSelectOptionsData] = useState({ data: {}, isFetching: false })
	const [isNeedFetch, setIsNeedFetch] = useState(true)
	const dispatch = useDispatch()

	useEffect(() => {
		async function fetchData() {
			setSelectOptionsData((pre) => (pre = { ...pre, isFetching: true }))
			const tempPurchaseService = new TempPurchaseOrdersService()
			const result = await tempPurchaseService.getSelectOptionsData()
			const { status, data } = result
			switch (status) {
				case 200:
					setSelectOptionsData((pre) => (pre = { data: data.response.data, isFetching: false }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setSelectOptionsData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					setSelectOptionsData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setIsNeedFetch(false)
		}
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetch])

	return [selectOptionsData, setIsNeedFetch]
}
