import {
	Box,
	Text,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	FormControl,
} from '@chakra-ui/react'
import React from 'react'
import { BiTimeFive, BiReceipt } from 'react-icons/bi'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

import styled from 'styled-components'
import { priceCommaInserter } from 'utils/priceInsertComma'

const StyleBox = styled(Box)`
	border-bottom: 2px solid #d6f3d6;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	padding: 0 0.5rem 1.2rem;
`

export default function PaymentOrder({ item }) {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Box 				
				onClick={onOpen}
				ml="auto" 
				display="flex" 
				alignItems="center" 
				cursor="pointer" 
				color="#2250a6"
				fontWeight="500"
			>
				更多資訊
				<IoIosArrowForward />
			</Box>

			<Modal isOpen={isOpen} scrollBehavior="inside">
				<ModalOverlay />
				<ModalContent maxW="375px">
					<ModalHeader zIndex="3" backgroundColor="#ffffff" color="#51AB9B" textAlign="center" fontSize="1.5rem" display="flex" alignItems="center" justifyContent="center" >
						<Box onClick={onClose} mr="0.5rem" position="absolute" left="1.5rem">
							<IoIosArrowBack />
						</Box>
							付款單商品清單
						<Box ml="0.5rem">
							<BiReceipt />
						</Box>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb="3em" maxH="600px">
						<Stack spacing={5}>
						<Box display="flex" alignItems="center" mb="0.5rem" color="#C4C4C4">
						<Box mr="0.5rem">
							<BiTimeFive />
						</Box >
							{item.date}
								<Text 
								color="#51AB9B" 				
								ml="auto" 
								display="flex" 
								alignItems="center" 
							>
								{`總計 NT${priceCommaInserter(item.price)}`}
							</Text>
						</Box>
							{item.products.map((product, i) => (
								<FormControl key={i}>
									<StyleBox>
										<Box>商品 {product.product_name}</Box>
										<Box color="#51AB9B">NT${priceCommaInserter(product.product_price)}</Box>
									</StyleBox>
								</FormControl>
							))}
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
