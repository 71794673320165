import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

export default function DisplayTable({ productList }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>商品編號</td>
						<td>品名</td>
						<td>備註</td>
						<td>原位置</td>
						<td>商品狀態</td>
						<td>付款方式</td>
						<td>進價</td>
						<td>售價</td>
					</tr>
				</thead>
				<tbody>
					{productList.length > 0
						? productList.map((item, i) => (
								<tr key={i}>
									<td>{item.product_no}</td>
									<td>{item.product_name}</td>
									<td>{item.product_notes}</td>
									<td>{item.old_location}</td>
									<td>{item.product_status}</td>
									<td>{item.product_type}</td>
									<td>{item.product_price ? item.product_price : '從缺'}</td>
									<td>{item.product_sale_price ? item.product_sale_price : '從缺'}</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
