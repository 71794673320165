import Axios from 'Api/interceptors'

export class SubtypeService {
	async createSubtype(typeId, payload) {
		payload.type_id = typeId
		let result
		try {
			result = await Axios.post('/subtypes', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateSubtype(typeId, subtypeId, payload) {
		payload.type_id = typeId
		let result
		try {
			result = await Axios.put('/subtypes/' + subtypeId, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteSubtype(subtypeId) {
		let result
		try {
			result = await Axios.delete('/subtypes/' + subtypeId)
		} catch (error) {
			result = error
		}
		return result
	}
}
