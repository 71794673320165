import React from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'

const StyledForm = styled.form`
	display: flex;
	align-items: center;
	margin-right: 2.5em;
`

export default function SearchPaymentListForm({ setEditState, setProductListIsNeedFetch }) {
	const { handleSubmit, register } = useForm()
	function onSubmit(formData) {
		setEditState((pre) => (pre = { ...pre, date: formData.date }))
		setProductListIsNeedFetch(true)
	}

	return (
		<Box d="flex" alignItems="center">
			<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Input minW="160px" type="date" {...register('date.startDate')} isRequired />
				<Box mx=".5em">到</Box>
				<Input mr="1em" minW="160px" type="date" {...register('date.endDate')} isRequired />
				<FormSubmitButton flexShrink="0" type="submit">
					<AiOutlineSearch />
					<Text ml="5px">搜尋</Text>
				</FormSubmitButton>
			</StyledForm>
			<StyledButtonBox onClick={() => setEditState((pre) => (pre = { editType: 'create', date: {} }))}>
				<HiPlusSm />
				新增付款單
			</StyledButtonBox>
		</Box>
	)
}
