import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { MdKeyboardArrowRight } from 'react-icons/md'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

export default function TableTopBar() {
	const { setEditState, editState } = useContext(otherTableContext)

	function openTypeModal() {
		setEditState((pre) => (pre = { editType: 'create' }))
	}

	function openSubtypeModal() {
		setEditState((pre) => (pre = { ...pre, editSubtype: 'create', editType: 'detail' }))
	}
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				類別設定
				{editState.editType === 'detail' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						類別詳情
					</Box>
				) : null}
			</Box>
			<Box>
				{editState.editType !== 'detail' ? (
					<Box onClick={openTypeModal} border="1px solid  #2250A6" d="flex" borderRadius="20px" p=".4em 1.2em" alignItems="center" color="#2250A6" fontWeight="700" cursor="pointer">
						<HiPlusSm />
						<Text ml="5px">新增類別</Text>
					</Box>
				) : null}
				{editState.editType === 'detail' ? (
					<Box onClick={openSubtypeModal} border="1px solid  #2250A6" d="flex" borderRadius="20px" p=".4em 1.2em" alignItems="center" color="#2250A6" fontWeight="700" cursor="pointer">
						<HiPlusSm />
						<Text ml="5px">新增子類別</Text>
					</Box>
				) : null}
			</Box>
		</StyledTopbar>
	)
}
