import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import ChartContent from './ChartContent'
import TableTopBar from './TableTopBar'
import { Provider } from './useContext/createContext'
// import SubContent from './SubContent'
// import DisplayContent from './DisplayContent'
import useGetStoreSalesData from 'hook/reportChart/useGetStoreSalesData'

const StyledTable = styled(Box)`
	${'' /* height: 100%; */}
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	${'' /* overflow: hidden; */}

	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	const [editState, setEditState] = useState({ editType: 'viewList', date: {} })
	const [chartData, tableData] = useGetStoreSalesData(editState)
	const value = { editState, setEditState, chartData, tableData }

	return (
		<Provider value={value}>
			<StyledTable>
				<TableTopBar />
				{editState.editType === 'viewList' ? <ChartContent /> : null}
				{/* {editState.editType === 'create' ? <SubContent /> : null}
				{editState.editType === 'print' ? <DisplayContent /> : null} */}
			</StyledTable>
		</Provider>
	)
}
