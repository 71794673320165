import { Box, Input, Select } from '@chakra-ui/react'
import React, { useState } from 'react'
import StyledFormLabel from 'utils/StyledFormLabel'
export default function PaymentSelect({ register }) {
	const [paymentSelect, setPaymentSelect] = useState('')

	return (
		<Box d="flex" mr="3em">
			<Box mr="1em">
				<StyledFormLabel tag="付款方式" isRequired />
				<Select isRequired {...register('payment', { value: paymentSelect })} onChange={(e) => setPaymentSelect(e.target.value)} w="180px">
					<option value="" disabled>
						付款方式
					</option>
					<option value="0">現金</option>
					<option value="1">匯款</option>
				</Select>
			</Box>

			{paymentSelect === '1' ? (
				<Box>
					<StyledFormLabel tag="帳號後五碼" isRequired />
					<Input maxW="100px" placeholder="後五碼" {...register('payment_detail')} isRequired maxLength="5" autoComplete="off" />
				</Box>
			) : null}
		</Box>
	)
}
