import { Box, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { IoDocumentText } from 'react-icons/io5'
import { AiOutlineCloudUpload, AiOutlineInfoCircle } from 'react-icons/ai'
import GeneralPagination from 'components/pagination'
import PictureModal from 'components/modals/PictureModal'
import ImageUploader from './component/uploadSignedSheet/ImageUploader'
import BasicTable from 'components/basicTable'
import { useHistory } from 'react-router-dom'

export default function TableContent({ queryData, gotoPage, isFetching }) {
	const history = useHistory()

	const goToDetail = (id) => {
		history.push(`/admin/purchase/purchaseSheet/order/${id}`)
	}

	const columns = React.useMemo(
		() => [
			{
				Header: () => (
					<Box display="flex" alignItems="center" justifyContent="center">
						<Box>進貨單編號</Box>
						<Tooltip label="點擊單號可以看簽名進貨單">
							<span>
								<AiOutlineInfoCircle />
							</span>
						</Tooltip>
					</Box>
				),
				accessor: 'purchase_order_no',
				Cell: (row) => {
					const imageUrl = row.row.original.image
					const sheetId = row.row.original.id
					return (
						<Box display="flex" alignItems="center" justifyContent="center">
							<PictureModal imageUrl={imageUrl} text={row.value} />
							<Box color="#2b64d0" ml="0.5rem" cursor="pointer">
								<PictureModal text={<AiOutlineCloudUpload size="1.3rem" />}>
									<ImageUploader sheetId={sheetId} />
								</PictureModal>
							</Box>
						</Box>
					)
				},
			},
			{
				Header: '日期',
				accessor: 'date',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '廠商電話',
				accessor: 'phone',
				disableGlobalFilter: true,
			},

			{
				Header: '廠商姓名',
				accessor: 'vendor',
				disableGlobalFilter: true,
			},
			{
				// API欄位沒變，一樣是line id，但改成紀錄instagram
				Header: 'Instagram',
				accessor: 'line',
				disableGlobalFilter: true,
			},
			{
				Header: '管理',
				accessor: 'management',
				disableGlobalFilter: true,
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box color="#91B0EB" cursor="pointer" onClick={() => goToDetail(row.row.original.id)}>
								<IoDocumentText />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const listData = queryData?.data?.response?.data || []
	const currentPage = queryData?.data?.response?.page || 1
	const maxPage = queryData?.data?.response?.total_page || 1

	return (
		<>
			<BasicTable tableData={listData} columns={columns} isLoading={isFetching} />

			<GeneralPagination current={currentPage} defaultCurrent={1} total={maxPage * 10} onChange={gotoPage} />
		</>
	)
}
