import { Box } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { vendorContext } from './useContext/createContext'
import { useContext, useEffect, useState } from 'react'
import DisplayTable from './component/DisplayTable'
import CreateVendorForm from './component/CreateVendorForm'
import { useDispatch } from 'react-redux'
import VendorService from 'Api/vendor/vendorService'
import { error, success, warning } from 'reducers/toast'
import { full, normal } from 'reducers/fullScreen'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
`

export default function SubContent() {
	const { setEditState, editState } = useContext(vendorContext)
	const [oneVendorData, setOneVenderData] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(full())
		return function () {
			dispatch(normal())
		}
	}, [dispatch])

	useEffect(() => {
		if (editState.editType === 'edit') {
			setIsLoading(true)
			async function fetchData() {
				const vendorService = new VendorService()
				const result = await vendorService.getSingleVendor(editState.data.id)
				const { data, status } = result
				switch (status) {
					case 200:
						setOneVenderData((pre) => (pre = { ...data.response }))
						dispatch(success({ message: data.message, status: status }))
						break
					case 400:
						dispatch(warning({ message: data.message, status: status }))
						break
					default:
						dispatch(error({ message: data.message, status: status }))
						break
				}
				setIsLoading(false)
			}

			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState.data.id])

	return (
		<StyledTransitionBg>
			<CreateVendorForm setEditState={setEditState} editState={editState} vendorImage={oneVendorData.image || ''} />
			{editState.editType === 'edit' ? <DisplayTable productData={oneVendorData.products || []} isLoading={isLoading} /> : null}
		</StyledTransitionBg>
	)
}
