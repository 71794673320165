import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import TableContent from './TableContent'
import TableTopBar from './TableTopBar'
import { Provider } from './useContext/createContext'
import SubContent from './SubContent'
import DisplayContent from './DisplayContent'
import useGetTempPurchaseOrder from 'hook/tempPurchaseTable/useGetTempPurchaseOrder'
import useGetSelectOptionsData from 'hook/tempPurchaseTable/useGetSelectOptionsData'

const StyledTable = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;

	@media print {
		& {
			box-shadow: none;
			padding: 0;
		}
	}

	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	const [setTempPurchaseOrderIsNeedFetch, tableData, currentPage, maxPage, gotoPage] = useGetTempPurchaseOrder()
	const [editState, setEditState] = useState({
		editType: 'viewList',
	})
	const [printType, setPrintType] = useState('')
	const [selectOptionsData, setIsNeedFetch] = useGetSelectOptionsData()

	const value = {
		selectOptionsData,
		editState,
		setEditState,
		currentPage,
		maxPage,
		gotoPage,
		tableData,
		setIsNeedFetch,
		setTempPurchaseOrderIsNeedFetch,
		printType,
		setPrintType,
	}
	return (
		<Provider value={value}>
			<StyledTable>
				<TableTopBar />
				{editState.editType === 'viewList' ? <TableContent /> : null}
				{editState.editType === 'edit' ? <SubContent /> : null}
				{editState.editType === 'print' ? <DisplayContent /> : null}
			</StyledTable>
		</Provider>
	)
}
