import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text, ModalOverlay, Modal, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import useGetSheetDetail from 'hook/paymentTable/useGetSheetDetail'
import React, { useContext } from 'react'
import { IoDocumentText } from 'react-icons/io5'
import styled from 'styled-components'
import { StyledButtonBox } from 'utils/StyledButton'
import { paymentListContext } from '../useContext/createContext'

const StyledTimeBox = styled(Box)`
	border-color: black !important;
	border-bottom: 1px solid;
	color: #a1a1a1;
	min-height: 1.5em;
	min-width: 160px;
	margin-top: 1em;
	text-align: left;
	padding-bottom: 0.5em;
	padding-left: 1em;
`

const StyledTable = styled(Box)`
	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
		margin-top: 2em;
		table-layout: fixed;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function SheetDetailModel({ vendorId }) {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Box d="flex" justifyContent="center">
				<Box color="#91B0EB" cursor="pointer" p="3px" onClick={onOpen}>
					<IoDocumentText />
				</Box>
			</Box>
			{isOpen && <DataModal vendorId={vendorId} isOpen={isOpen} onClose={onClose} />}
		</>
	)
}

function DataModal({ vendorId, isOpen, onClose }) {
	const { editState } = useContext(paymentListContext)
	const { sheetData } = useGetSheetDetail(editState.orderId, vendorId)

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW="1000px">
				<ModalHeader d="flex" justifyContent="center">
					單筆款項詳情
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box d="flex" justifyContent="space-between" pr="2em">
						<Box d="flex">
							<Box mr="4em">
								<Text>廠商姓名</Text>
								<Box d="flex" alignItems="center">
									<StyledTimeBox>{sheetData.vendor || '廠商姓名'}</StyledTimeBox>
								</Box>
							</Box>
							<Box>
								<Text>廠商電話</Text>
								<Box d="flex" alignItems="center">
									<StyledTimeBox>{sheetData.phone}</StyledTimeBox>
								</Box>
							</Box>
						</Box>
					</Box>
					<StyledTable>
						<table>
							<thead>
								<tr>
									<td>商品編號</td>
									<td>品名</td>
									<td>品牌名稱</td>
									<td>商品類別</td>
									<td>商品狀況</td>
									<td>商品尺寸</td>
									<td>商品顏色</td>
									<td>商品售價</td>
								</tr>
							</thead>
							<tbody>
								{sheetData.products?.length > 0
									? sheetData.products.map((item, i) => (
											<tr key={i}>
												<td>{item.product_no}</td>
												<td>{item.product_name}</td>
												<td>{item.product_brand}</td>
												<td>{item.product_type}</td>
												<td>{item.product_condition}</td>
												<td>{item.product_size}</td>
												<td>{item.product_color}</td>
												<td>{item.product_price}</td>
											</tr>
									  ))
									: null}
							</tbody>
						</table>
					</StyledTable>
					<Box textAlign="end" fontSize="1.2em" mt="1rem" mr="1rem">
						總計：{sheetData.totalPrice}
					</Box>
				</ModalBody>

				<ModalFooter justifyContent="center">
					<StyledButtonBox onClick={onClose}>完成</StyledButtonBox>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
