import React from 'react'

export const purchaseContext = React.createContext()
export const PurchaseProvider = purchaseContext.Provider

export const selectOptionsContext = React.createContext()
export const SelectOptionsProvider = selectOptionsContext.Provider

export const createPurchaseOrderContext = React.createContext()
export const CreatePurchaseOrderProvider = createPurchaseOrderContext.Provider

export const updateModalContext = React.createContext()
export const UpdateModalProvider = updateModalContext.Provider
