import { PaymentService } from 'Api/payment/paymentService'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetSheetDetail(orderId, vendorId) {
	const [sheetData, setSheetData] = useState({})
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const paymentService = new PaymentService()
			const result = await paymentService.getPaymentSheetDetail(orderId, vendorId)
			const { status, data } = result
			switch (status) {
				case 200:
					setSheetData(data.response)
					dispatch(success({ message: data.message, status: status, code: data.code }))
					break
				case 400:
					setSheetData({})
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setSheetData({})
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}

		if (orderId && vendorId) fetchData()

		return () => setSheetData({})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId, vendorId])
	return { sheetData }
}
