import { Box, Tabs, Tab, TabList, TabPanel, TabPanels, Image } from '@chakra-ui/react'
import logoIcon from 'assets/images/logo.svg'
import React, { useContext, useEffect } from 'react'
import { storeSalesContext } from './useContext/createContext'
import Chart from 'chart.js/auto'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

function labelSwitcher(labelEN) {
	switch (labelEN) {
		case 'stockSaleAmount':
			return '進貨銷貨數量'
		case 'stockSalePrice':
			return '進貨銷貨金額'

		default:
			return ''
	}
}

export default function ChartContent() {
	const { chartData, tableData } = useContext(storeSalesContext)
	let canvasDomIdArr = ['stockSaleAmount', 'stockSalePrice']
	useEffect(() => {
		if (chartData.length > 0) {
			chartRenderer(chartData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData])

	function chartDomRenderer(canvasDomIdArr) {
		return (
			<Tabs w="100%">
				<TabList>
					{canvasDomIdArr.map((item, i) => (
						<Tab key={i}>{labelSwitcher(item)}</Tab>
					))}
				</TabList>
				<TabPanels>
					{canvasDomIdArr.map((item, i) => (
						<TabPanel p={4} key={i} w="80%" mx="auto">
							<canvas id={item}></canvas>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		)
	}

	function chartRenderer(chartData) {
		for (const singleChartData of chartData) {
			// 重新render前需要清空原本render過的圖表
			if (window[singleChartData.chart + '_chart']) {
				window[singleChartData.chart + '_chart'].destroy()
			}

			window[singleChartData.chart + '_chart'] = new Chart(document.getElementById(`${singleChartData.chart}`), {
				type: 'bar',
				data: {
					labels: singleChartData.labels,
					datasets: singleChartData.datasets,
				},
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'top',
						},
					},
				},
			})
		}
	}

	return (
		<Box>
			<Box display="flex" justifyContent="center" flexWrap="wrap" mb="2em">
				{chartData.length > 0 ? (
					chartDomRenderer(canvasDomIdArr)
				) : (
					<Box py="4em">
						<Image src={logoIcon} opacity=".6" />
					</Box>
				)}
			</Box>
			<Box>
				{tableData.length > 0 ? (
					<StyledTable>
						<table>
							<thead>
								<tr>
									<td>日期</td>
									<td>進貨數量</td>
									<td>進貨金額</td>
									<td>銷貨數量</td>
									<td>銷貨金額</td>
								</tr>
							</thead>
							<tbody>
								{tableData.map((item, j) => (
									<tr key={j}>
										<td>{item.date}</td>
										<td>{item.import_count}</td>
										<td>{item.import_price}</td>
										<td>{item.sale_count}</td>
										<td>{item.sale_price}</td>
									</tr>
								))}
							</tbody>
						</table>
					</StyledTable>
				) : null}
			</Box>
		</Box>
	)
}
