import React from 'react'
import BasicTable from '../../../../basicTable'
import { useMutation } from '@tanstack/react-query'
import { Box } from '@chakra-ui/react'
import AddProductForm from './AddProductForm'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { warning, success, error } from 'reducers/toast'
import { FormSubmitButton } from 'utils/StyledButton'
import { postRePurchaseOrder } from 'Api/rePurchaseOrder'
import SubmitAlert from './SubmitAlert'
import { useHistory } from 'react-router-dom'

function CreatePage() {
	const history = useHistory()
	const dispatch = useDispatch()
	const [tableData, setTableData] = React.useState([])

	const postDataMutation = useMutation({
		mutationFn: postRePurchaseOrder,
		onSuccess: () => {
			dispatch(success({ message: '新增寄賣轉買斷成功' }))
			goBackToListTable()
		},
		onError: (err) => {
			if (err?.status === 400) {
				dispatch(warning({ message: err?.data?.message }))
			} else {
				dispatch(error({ message: err?.data?.message }))
			}
		},
	})

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'product_no',
			},
			{
				Header: '品名',
				accessor: 'name',
			},
			{
				Header: '廠商名稱',
				accessor: 'vendor_name',
			},
			{
				Header: '廠商電話',
				accessor: 'vendor_phone',
			},
			{
				Header: '進價',
				accessor: 'price',
			},
			{
				Header: '進貨時間',
				accessor: 'date',
			},
			{
				Header: '是否過期',
				accessor: 'expire',
			},
			{
				Header: '管理',
				accessor: 'id',
				Cell: ({ value }) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box color="#EB9191" cursor="pointer" onClick={() => handleRemoveItem(value)}>
								<FaTrashAlt />
							</Box>
						</Box>
					)
				},
			},
		],
		[]
	)

	const handleRemoveItem = (id) => {
		setTableData((pre) => {
			return pre.filter((item) => item.id !== id)
		})
	}

	const fetchProductCallback = (productData) => {
		if (!productData) return

		const repeatedItem = tableData.find((item) => item.id === productData.id)
		if (repeatedItem) {
			return dispatch(warning({ message: '清單內已有此項商品' }))
		}

		if (productData.product_type === '買斷') {
			return dispatch(warning({ message: `商品 ${productData.product_name} 已是買斷狀態` }))
		}

		setTableData((pre) => [...pre, productData])
	}

	const establishRePurchaseOrder = () => {
		// check if vendor_name is all the same
		const vendorName = tableData[0].vendor_name
		const isAllSameVendorName = tableData.every((item) => item.vendor_name === vendorName)
		if (!isAllSameVendorName) {
			return dispatch(warning({ message: '請確認所有商品廠商名稱相同' }))
		}

		const productIds = tableData.map((item) => item.id).join(',')

		postDataMutation.mutate({ product_ids: productIds })
	}

	const goBackToListTable = () => {
		history.push('/admin/stock/rePurchase')
	}

	return (
		<>
			<Box mb="1rem">
				<AddProductForm fetchProductCallback={fetchProductCallback} />
			</Box>
			<BasicTable columns={columns} tableData={tableData} pageSize={999} />

			<Box display="flex" justifyContent="center" p="1rem">
				<FormSubmitButton mr="1rem" onClick={goBackToListTable}>
					取消
				</FormSubmitButton>
				<SubmitAlert onConfirm={establishRePurchaseOrder} isLoading={postDataMutation.isLoading} />
			</Box>
		</>
	)
}

export default CreatePage
