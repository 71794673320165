import TempPurchaseOrdersService from 'Api/tempPurchaseOrder/tempPurchaseOrderService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { warning } from 'reducers/toast'

export default function useGetTempPurchaseOrder() {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const [tempPurchaseOrderIsNeedFetch, setTempPurchaseOrderIsNeedFetch] = useState(true)
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const tempOrderService = new TempPurchaseOrdersService()
			const getListData = await tempOrderService.getOrderList(currentPage)
			const { status, data } = getListData

			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: [...data.response.data], isFetching: false })
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					break
			}

			setTempPurchaseOrderIsNeedFetch(false)
		}

		if (tempPurchaseOrderIsNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempPurchaseOrderIsNeedFetch])

	useEffect(() => {
		setTempPurchaseOrderIsNeedFetch(true)
	}, [currentPage])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}

	return [setTempPurchaseOrderIsNeedFetch, tableData, currentPage, maxPage, gotoPage]
}
