import React, { useContext, useEffect } from 'react'
import { Box, List, ListItem } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './component/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import { purchaseContext } from './useContext/createContext'
import QRCode from 'react-qr-code'
import { useQuery } from '@tanstack/react-query'
import { getOrderDetail } from 'Api/purchaseOrder/purchaseOrderService'
import { useParams, useHistory } from 'react-router-dom'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}

	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.signSheet {
				display: ${(props) => (props.printType === 'precautions' ? 'none' : 'block')};
			}

			.precautions {
				display: ${(props) => (props.printType === 'table' ? 'none' : 'block')};
				padding-bottom: 3em !important;
				page-break-before: always;

				ul {
					list-style: none;
				}
			}
		}

		@page {
			size: A4 portrait;
		}
	}
`

export default function DisplayContent() {
	const { printType, setPrintType } = useContext(purchaseContext)
	const { id } = useParams()
	const history = useHistory()
	const { data } = useQuery({ queryKey: ['getOrderDetail', id], queryFn: getOrderDetail(id) })
	const detailData = data?.data?.response || {}

	const detailList = detailData?.products || []

	const goBackToList = () => {
		history.push('/admin/purchase/purchaseSheet')
	}

	useEffect(() => {
		if (printType) {
			window.print()
		}

		return () => setPrintType('')
	}, [printType, setPrintType])

	return (
		<StyledTransitionBg printType={printType}>
			<Box className="signSheet">
				<Box textAlign="center">
					<Box className="title" fontSize="2em" mb=".7em">
						OPMM進貨單
					</Box>
				</Box>

				<Box className="info" mb="1em">
					<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
						<Box>
							<Box>進貨單號：{detailData.purchase_order_no}</Box>
							<Box>進貨日期：{detailData.date}</Box>
						</Box>
						<Box>
							<Box>廠商姓名：{detailData.vendor}</Box>
							<Box>廠商電話：{detailData.vendor_phone}</Box>
						</Box>
						<Box>
							<Box>經辦姓名：{detailData.user_no}</Box>
							<Box>經辦電話：{detailData.user_phone}</Box>
						</Box>
					</Box>
				</Box>
				<DisplayTable productList={detailList} />
				<Box textAlign="center">
					<Box textAlign="right" my="1em" mr="1em">
						總計：$ {detailData.total_price}
					</Box>
					<Box d="inline-block" className="success-button">
						<StyledButtonBox onClick={goBackToList}>完成</StyledButtonBox>
					</Box>
				</Box>
			</Box>
			<Box className="precautions" px="3em" display="none">
				<Box fontSize="1.5em">
					<Box textAlign="center" fontSize="2rem" my="1.5rem">
						寄賣／收購注意事項：
					</Box>
					<List>
						<ListItem>一、由寄賣⽇算起，寄賣期為60⽇，寄賣期間商品將全權由OPMM授權販售。
							如遇不可抗因素需提前取消寄賣服務，前30⽇需酌收處理費1000元/次；
							31~60⽇取消需酌收處理費500元/次；寄賣60⽇後則不會產⽣其他費⽤。</ListItem>
						<ListItem>
							二、寄賣期間如需取消，請於⼀週前⾄官⽅LINE＠以訊息告知，恕不接受臨時
							⾄店上取回，如需寄送需⾃⾏負擔運費。
							（超商寄送處理費為100元／宅配為160元起）
						</ListItem>
						<ListItem>三、寄賣⾦額均由店舖⼈員與寄賣者雙⽅協議訂定價格，當價格不易售出時，
							將與寄賣者再次協議，若⾦額與預期不符，可選擇取消寄賣取回商品。</ListItem>
						<ListItem>
							四、寄賣商品如銷售不佳或有瑕疵等無法銷售等情事，OPMM將以LINE/IG訊息
							通知取回，寄賣者須在接到通知後30⽇內⾄店舖領回（也可安排⾃付運費
							寄送），逾期未領取將視同授權OPMM將寄賣商品以回收處理。
						</ListItem>
						<ListItem>
							五、若寄賣/收購之商品經查證為仿品、贓物等相關情事，OPMM將其相關資料
							交付警務單位處置並依法送辦，同時將向寄賣者求償商譽損失及銷毀商品。
						</ListItem>
						<ListItem>六、寄賣期間OPMM將付保管責任，倘若寄賣商品遺失，將以雙⽅協議之寄賣
							實收⾦額直接收購。</ListItem>
						<ListItem>七、會員基本資料僅供OPMM店內使⽤與執⾏相關業務，不會將資料提供他⼈
							使⽤。</ListItem>
						<ListItem>八、如有個⼈資料異動及其他疑問，請洽OPMM服務⼈員。</ListItem>
					</List>
					<Box textAlign="center" fontSize="2rem" my="1.5rem">
						取款規定：
					</Box>
					<List>
						<ListItem>商品售出後，將於隔⽉10~15⽇匯入其指定帳⼾（需負擔匯款⼿續費30元），
							如需提前取款，待系統顯⽰收款⾦額時，請攜帶⼀張有效證件於營業時間內⾄
							收購店即可（不需提前預約）。</ListItem>
					</List>
					<Box display="flex" justifyContent="center" mt="2rem" mb="4rem">
						<Box>
							如需查詢寄賣銷售狀況，請掃描右側QR CODE查詢。（寄賣商品資訊將於晚上12點更新）
						</Box>
						<Box mx="2rem">
							<QRCode value={`${window.location.origin}/vendor?code=${detailData.vendor_token}`} size={100} />
						</Box>
					</Box>
					<Box>寄賣人簽名：＿＿＿＿＿＿＿＿＿＿ （___/___/___） </Box>
				</Box>
			</Box>
		</StyledTransitionBg>
	)
}
