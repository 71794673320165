import { Box, useToast } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { useForm } from 'react-hook-form'
import { transferContext, transferTableContext } from './useContext/createContext'
import { useContext, useState } from 'react'
import { StyledButtonBox } from 'utils/StyledButton'
import LocationSelect from './components/LocationSelect'
import { error, success, warning } from 'reducers/toast'
import { useDispatch } from 'react-redux'
import AddTransferSheetHead from './components/AddTransferSheetHead'
import AddTransferSheet from './components/AddTransferSheet'
import SubmitAlert from './components/SubmitAlert'
import TransferService from 'Api/transferOrder/transferService'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
	background-color: white;

	td,
	th {
		min-width: 80px;
		max-width: 160px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`

const StyledForm = styled.form`
	width: 100%;
`

export default function SubContent() {
	const { setEditState } = useContext(transferContext)
	const { register, handleSubmit } = useForm()
	const [tableData, setTableData] = useState([])
	const toast = useToast()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)

	const submitHandler = async (formData) => {
		if (tableData.length <= 0) {
			toast({ title: '系統通知', description: '請至少新增一樣商品', status: 'warning', isClosable: true, duration: 1500 })
			return
		}
		const merchantIdArray = []

		for (let i = 0; i < tableData.length; i++) {
			merchantIdArray.push(tableData[i].id)
		}
		formData.product_ids = merchantIdArray.join(',')
		setIsLoading(true)
		const transferService = new TransferService()
		const result = await transferService.createTransferSheet(formData)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				setEditState({ editType: 'viewList', date: {} })
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
		setIsLoading(false)
	}

	const value = { tableData, setTableData }
	return (
		<transferTableContext.Provider value={value}>
			<StyledTransitionBg>
				<AddTransferSheetHead />
				<AddTransferSheet />
				<StyledForm autoComplete="off">
					<Box d="flex" justifyContent="space-between" mt="3em">
						<Box d="flex">
							<LocationSelect register={register} />
						</Box>
					</Box>
					<Box textAlign="center" py="1em" d="flex" justifyContent="center">
						<StyledButtonBox mx=".5em" onClick={() => setEditState((pre) => (pre = { editType: 'viewList', date: {} }))}>
							取消
						</StyledButtonBox>
						<SubmitAlert handleSubmit={handleSubmit} submitHandler={submitHandler} loadingState={isLoading} />
					</Box>
				</StyledForm>
			</StyledTransitionBg>
		</transferTableContext.Provider>
	)
}
