import { Box, Image } from '@chakra-ui/react'
import logoIcon from 'assets/images/logo.svg'
import React, { useContext, useEffect, useState } from 'react'
import { storeSalesContext } from './useContext/createContext'
import Chart from 'chart.js/auto'
import _ from 'lodash'
import MaintypeTable from './components/MaintypeTable'
import SubtypeTable from './components/SubtypeTable'

export default function ChartContent() {
	const { pieData } = useContext(storeSalesContext)
	const [subtypeData, setSubtypeData] = useState([])

	useEffect(() => {
		if (pieData.length > 0) {
			maintypePieRenderer(pieData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pieData])

	function maintypePieRenderer(pieData) {
		if (window['maintypePie_chart']) {
			window['maintypePie_chart'].destroy()
		}
		let tempLabels = []
		let tempDataArr = []
		let otherDataArr = []
		let otherLabels = []
		let tempData = {
			data: [],
		}
		tempData.label = '主類別'
		tempData.backgroundColor = [
			'rgba(169, 188, 208, .7)',
			'rgba(155, 173, 193, .7)',
			'rgba(141, 157, 177, .7)',
			'rgba(127, 142, 161, .7)',
			'rgba(112, 126, 145, .7)',
			'rgba(98, 111, 129, .7)',
			'rgba(84, 95, 113, .7)',
			'rgba(55, 63, 81, .7)',
			'rgba(41, 45, 56, .7)',
			'rgba(27, 27, 30, .7)',
		]
		tempData.borderColor = [
			'rgba(169, 188, 208, 1)',
			'rgba(155, 173, 193, 1)',
			'rgba(141, 157, 177, 1)',
			'rgba(127, 142, 161, 1)',
			'rgba(112, 126, 145, 1)',
			'rgba(98, 111, 129, 1)',
			'rgba(84, 95, 113, 1)',
			'rgba(55, 63, 81, 1)',
			'rgba(41, 45, 56, 1)',
			'rgba(27, 27, 30, 1)',
		]
		tempData.borderWidth = 1.5
		const sortedPieData = _.orderBy(pieData, 'count', 'desc')
		for (let index = 0; index < sortedPieData.length; index++) {
			if (index <= 9) {
				tempLabels.push(sortedPieData[index].type)
				tempDataArr.push(sortedPieData[index].count)
			} else {
				otherLabels.push(sortedPieData[index].type)
				otherDataArr.push(sortedPieData[index].count)
			}
		}
		tempData.data = tempDataArr
		if (tempLabels.length >= 10) {
			tempLabels.push('其他')
			tempData.data.push(otherDataArr.reduce((a, b) => a + b))
		}

		window['maintypePie_chart'] = new Chart(document.getElementById('maintypePie'), {
			type: 'pie',
			data: {
				labels: tempLabels,
				datasets: [tempData],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: 'top',
					},
					title: {
						display: true,
						text: '主類別庫存量',
					},
				},
				onClick: (evt) => {
					let subtypeObj = window['maintypePie_chart'].getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)
					if (subtypeObj.length > 0) {
						if (subtypeObj[0].index <= 9) {
							subtypePieRender(subtypeObj[0].index)
							setSubtypeData(sortedPieData[subtypeObj[0].index].subtype)
						}
					}
				},
			},
		})
	}

	function subtypePieRender(index) {
		const sortedPieData = _.orderBy(pieData, 'count', 'desc')
		const orderedSubTypePieNumber = _.orderBy(sortedPieData[index].subtype, 'count', 'desc').map((item) => item.count)
		const orderedSubTypePieLabel = _.orderBy(sortedPieData[index].subtype, 'count', 'desc').map((item) => item.subtype)
		var ctx3 = document.getElementById('subtypePie').getContext('2d')

		if (window.subtypePie_chart) {
			window.subtypePie_chart.destroy()
		}
		window.subtypePie_chart = new Chart(ctx3, {
			type: 'pie',
			data: {
				labels: orderedSubTypePieLabel,
				datasets: [
					{
						label: '# of Votes',
						data: orderedSubTypePieNumber,
						backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
						borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
						borderWidth: 1,
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: 'top',
					},
					title: {
						display: true,
						text: '子類別庫存量',
					},
				},
			},
		})
	}
	return (
		<Box>
			<Box display="flex" justifyContent="center" flexWrap="wrap" mb="2em">
				{pieData.length > 0 ? (
					<Box display="flex" w="100%" justifyContent="space-around">
						<Box>
							<canvas id="maintypePie"></canvas>
						</Box>
						<Box>
							<canvas id="subtypePie"></canvas>
						</Box>
					</Box>
				) : (
					<Box py="4em">
						<Image src={logoIcon} opacity=".6" />
					</Box>
				)}
			</Box>
			{pieData.length > 0 ? (
				<Box display="flex">
					<Box flex="1" mx="1em">
						<MaintypeTable pieData={pieData} />
					</Box>
					<Box flex="1" mx="1em">
						{subtypeData.length > 0 ? <SubtypeTable subtypeData={subtypeData} /> : null}
					</Box>
				</Box>
			) : null}
		</Box>
	)
}
