import { Box, Input, Spinner } from '@chakra-ui/react'
import ImageService from 'Api/image'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import styled from 'styled-components'
import { TiCamera } from 'react-icons/ti'
import { purchaseContext } from '../../useContext/createContext'

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`
// 為什麼要把imageUploader往內移一層
// 因為image upload成功之後，用hook存return的image url，會re-render component導致其他已經填寫過的form data變成undefined
export default function ImageUploader({ sheetId }) {
	const { refetchList } = useContext(purchaseContext)
	const dispatch = useDispatch()
	const [uploading, setUploading] = useState(false)
	// 負責處理image上傳
	// 先做一個formdata的instance再塞值進去
	// image用FileReader轉base64的字串後再塞進formdata
	// 成功會return image的url，再用hook存起來，備後續form submit用
	const fileHandler = async (file) => {
		const formData = new FormData()
		const imageBase64 = await imageToBase64(file)
		// // image upload 的target 有分product 和 vendor
		// formData.append('target', 'vendor')

		function imageToBase64(file) {
			return new Promise((res, rej) => {
				let reader = new FileReader()
				reader.onload = () => {
					res(reader.result)
				}
				reader.readAsDataURL(file)
			})
		}
		formData.append('image', imageBase64)
		setUploading(true)
		const imageService = new ImageService()
		const result = await imageService.uploadSignedNameSheetImage('purchase', formData, sheetId)
		if (result.status === 200) {
			dispatch(success({ message: result.data.message, status: result.status }))
			refetchList()
		}
		if (result.status === 400) dispatch(warning({ message: result.message, status: result.status, code: result.code }))
		if (result.status === 500) dispatch(error({ message: result.data.message, status: result.status }))
		setUploading(false)
	}

	return (
		<Box className="photoBox">
			<Box textAlign="center" mb="0.5rem" fontSize="1.5rem">
				<h1>上傳簽名進貨單</h1>
			</Box>
			{uploading ? (
				<Box textAlign="center" my="2rem">
					<Spinner size="xl" />
				</Box>
			) : (
				<Box
					mx="auto"
					border="2px dashed"
					borderColor="gray.600"
					_hover={{ borderColor: 'gray.700' }}
					w="180px"
					h="180px"
					borderRadius="15px"
					position="relative"
				>
					<Box
						position="absolute"
						w="100%"
						h="100%"
						d="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						color="gray.600"
					>
						<Box fontSize="3em">
							<TiCamera />
						</Box>
						<Box>拍照或上傳圖片</Box>
					</Box>
					<StyledInput
						type="file"
						accept="image/*"
						onChange={(e) => {
							if (e.target.files[0]) fileHandler(e.target.files[0])
						}}
						onClick={(e) => (e.target.value = '')}
						pb="100%"
						border="none"
						opacity="0"
					/>
				</Box>
			)}
		</Box>
	)
}
