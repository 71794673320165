import React from 'react'

export const tempPurchaseContext = React.createContext()
export const { Provider } = tempPurchaseContext

export const selectOptionsContext = React.createContext()
export const SelectOptionsProvider = selectOptionsContext.Provider

export const updateModalContext = React.createContext()
export const UpdateModalProvider = updateModalContext.Provider
