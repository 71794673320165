import Axios from 'Api/interceptors'

export default class PermissionService {
	async getPermissionList() {
		let result
		try {
			result = await Axios.get('/permissions')
		} catch (error) {
			result = error
		}
		return result
	}

	async getPermissionTags() {
		let result
		try {
			result = await Axios.get('/users/permission-tags')
		} catch (error) {
			result = error
		}
		return result
	}
}
