import React, { useContext } from 'react'
import { Box, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Text } from '@chakra-ui/react'
import QRCode from 'react-qr-code'
import { vendorContext } from '../useContext/createContext'
import { useEffect } from 'react'

export default function QrcodeModal() {
	const { onOpen, isOpen, onClose } = useDisclosure()
	const { modalState, setModalState } = useContext(vendorContext)

	useEffect(() => {
		if (modalState.mode === 'qrCode') {
			onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalState])

	function handleOnClose() {
		setModalState({ mode: '', data: {} })
		onClose()
	}
	return (
		<>
			<Modal isOpen={isOpen} onClose={handleOnClose}>
				<ModalOverlay />
				<ModalContent maxW="100vw" w="300px">
					<ModalBody textAlign="center">
						<Text fontSize="2rem" fontWeight={500}>
							廠商QR code
						</Text>
						<Box display="flex" justifyContent="center" py="1rem">
							<QRCode value={`${window.location.origin}/vendor?code=${modalState?.data.token}`} size={200} />
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
