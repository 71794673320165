import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosArrowDown } from 'react-icons/io'
import styled from 'styled-components'
import { createPurchaseOrderContext } from '../../useContext/createContext'
import VirtualizedList from '../VirtualizedList'

const StyledInput = styled(Input)`
	width: 180px !important;
`

export default function VendorSearchFormInput({ typeData, order, type, setVendorPhone }) {
	const [visible, setVisible] = useState(false)
	const [dataValue, setDataValue] = useState('')
	const { updateData } = useContext(createPurchaseOrderContext)

	const { handleSubmit, register, setValue } = useForm()

	useEffect(() => {
		setValue('vendorPhone', '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue])

	function onSubmit(formData) {
		setVendorPhone(formData.vendorPhone)
	}
	return (
		<Box position="relative" d="inline-block">
			<Box d="flex" flexWrap="wraps">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<InputGroup>
						{/* <StyledInput placeholder="輸入廠商電話" value={dataValue} onChange={(e) => setDataValue(e.target.value)} onFocus={() => setVisible(true)} onBlur={() => setVisible(false)} /> */}
						<StyledInput
							autoComplete="off"
							placeholder="輸入廠商電話"
							{...register('vendorPhone')}
							required
							onFocus={() => setVisible(true)}
							onBlur={() => setVisible(false)}
						/>
						<InputRightElement children={<IoIosArrowDown />} />
					</InputGroup>
				</form>
			</Box>
			<Box maxW="180px">
				{typeData.length > 0 ? (
					<VirtualizedList
						dataValue={dataValue}
						setDataValue={setDataValue}
						isVisible={visible}
						typeData={typeData}
						updateData={updateData}
						order={order}
						type={type}
					/>
				) : null}
			</Box>
		</Box>
	)
}
