import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import styled from 'styled-components'
import SubtypeTable from './components/SubtypeTable'
import { otherTableContext } from 'context/otherTableContext'
import { StyledButtonBox } from 'utils/StyledButton'
import useGetSubtypeList from 'hook/otherTable/useGetSubtypeList'
import SubtypeModal from './components/SubtypeModal'

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 2em 0;
	border-radius: 4px;
	flex-wrap: wrap;
`

const StyledBox = styled(Box)`
	display: flex;
	border-bottom: 1px solid;
	padding: 0.3em 0.7em;
	min-width: 200px;
	color: #a2a2a2;
	border-color: black;
	margin-top: 1em;
	min-height: 2.1em;
`

export default function SubContent() {
	const { editState, setEditState } = useContext(otherTableContext)
	const [subtypeList, setSubtypeNeedFetch] = useGetSubtypeList(editState.id)

	return (
		<>
			<SubtypeModal setSubtypeNeedFetch={setSubtypeNeedFetch} />
			<StyledBg>
				<Box d="flex" pl="10px" mb="2em">
					<Box mr="3em">
						<Box>類別序號</Box>
						<StyledBox>
							<Box>{editState.id}</Box>
						</StyledBox>
					</Box>
					<Box mr="3em">
						<Box>主類別</Box>
						<StyledBox>
							<Box>{editState.name}</Box>
						</StyledBox>
					</Box>
					<Box>
						<Box>說明</Box>
						<StyledBox>
							<Box>{editState.notes}</Box>
						</StyledBox>
					</Box>
				</Box>
				<SubtypeTable subtypeList={subtypeList} />
				<Box w="100%" d="flex" mt="1em" justifyContent="center">
					<StyledButtonBox mr="1em" onClick={() => setEditState((pre) => (pre = { editType: '' }))}>
						完成
					</StyledButtonBox>
				</Box>
			</StyledBg>
		</>
	)
}
