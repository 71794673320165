import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoIosArrowDown } from 'react-icons/io'
import styled from 'styled-components'
import { selectOptionsContext, updateModalContext } from '../../useContext/createContext'
import VirtualizedList from '../VirtualizedList'

const StyledInput = styled(Input)`
	width: 180px !important;
`

export default function SingleItemInput({ tempDataId, typeData, order, type }) {
	const [visible, setVisible] = useState(false)
	const [dataValue, setDataValue] = useState('')
	const { updateData } = useContext(selectOptionsContext)
	const { setUpdateModalInfo } = useContext(updateModalContext)

	useEffect(() => {
		setDataValue(typeData.find((item) => item.id === tempDataId)?.name)
	}, [tempDataId, typeData])

	return (
		<Box position="relative" d="inline-block">
			<Box d="flex" flexWrap="wraps">
				<InputGroup>
					<StyledInput
						placeholder="必填欄位"
						value={dataValue}
						onChange={(e) => setDataValue(e.target.value)}
						onFocus={() => setVisible(true)}
						onBlur={() => setVisible(false)}
					/>
					<InputRightElement children={<IoIosArrowDown />} />
				</InputGroup>
				{type !== 'type' ? (
					<Box
						borderRadius="4px"
						fontSize="1.5em"
						d="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="#2B64D0"
						color="white"
						cursor="pointer"
						flexShrink="0"
						w="35px"
						_hover={{ bgColor: '#265abb' }}
						onClick={() => setUpdateModalInfo({ type: type })}
						ml=".2em"
					>
						<AiOutlinePlus />
					</Box>
				) : null}
			</Box>
			<Box maxW="180px">
				{typeData.length > 0 ? (
					<VirtualizedList
						dataValue={dataValue?.toUpperCase()}
						setDataValue={setDataValue}
						isVisible={visible}
						typeData={typeData}
						updateData={updateData}
						order={order}
						type={type}
					/>
				) : null}
			</Box>
		</Box>
	)
}
