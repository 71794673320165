import PermissionService from 'Api/permission/permissionService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetPermissionTags() {
	const [permissionTags, setPermissionTags] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const permissionService = new PermissionService()
			const result = await permissionService.getPermissionTags()
			const { data, status } = result

			switch (status) {
				case 200:
					setPermissionTags((pre) => (pre = [...data.response.data]))
					break
				case 400:
					dispatch(warning({ message: data.message, status: 400 }))
					break
				default:
					dispatch(error({ message: data.message, status: 500 }))
					break
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return [permissionTags]
}
