import { Box } from '@chakra-ui/react'
import BasicTable from 'components/basicTable'
import GeneralPagination from 'components/pagination'
import React from 'react'
import { FaPen } from 'react-icons/fa'
import { HiDocumentText } from 'react-icons/hi'
import FormSwitch from './formSwitch'
import { useHistory } from 'react-router-dom'

function List({ data = [], page = 1, goToPage, maxPage = 1, isFetchingList = false, refetchList }) {
	const history = useHistory()
	const columns = React.useMemo(
		() => [
			{
				Header: '盤點店家名稱',
				accessor: 'location',
			},
			{
				Header: '開啟盤點人員',
				accessor: 'start_by',
			},
			{
				Header: '關閉盤點人員',
				accessor: 'end_by',
			},
			{
				Header: '開始時間',
				accessor: 'start_time',
				Cell: ({ value }) => {
					const [date, time] = value?.split(' ') || ''
					return (
						<Box>
							<div>{date}</div>
							<div>{time}</div>
						</Box>
					)
				},
			},
			{
				Header: '結束時間',
				accessor: 'end_time',
				Cell: ({ value }) => {
					const [date, time] = value?.split(' ') || ''
					return (
						<Box>
							<div>{date}</div>
							<div>{time}</div>
						</Box>
					)
				},
			},
			{
				Header: '盤點狀態',
				accessor: 'status',
				Cell: ({ value: status, row }) => {
					const sheetId = row.original?.id

					return <FormSwitch status={status} sheetId={sheetId} refetchList={refetchList} />
				},
			},
			{
				Header: '管理',
				accessor: 'id',
				Cell: ({ value: sheetId, row }) => {
					const status = row.original?.status
					const isOnInventory = status === '盤點中'

					const handleClickDetail = () => {
						isOnInventory ? alert('請先完成盤點。') : history.push(`/admin/inventory/inventorySheet/detail/${sheetId}`)
					}

					const handleClickEdit = () => {
						!isOnInventory ? alert('此筆盤點紀錄已關閉。') : history.push(`/admin/inventory/inventorySheet/edit/${sheetId}`)
					}

					return (
						<Box display="flex" justifyContent="space-around">
							<Box color="#91B0EB" cursor="pointer" onClick={handleClickDetail}>
								<HiDocumentText />
							</Box>
							<Box color="#91B0EB" cursor="pointer" onClick={handleClickEdit}>
								<FaPen />
							</Box>
						</Box>
					)
				},
			},
		],
		[history, refetchList]
	)

	return (
		<div>
			<BasicTable tableData={data} columns={columns} isLoading={isFetchingList} />
			<GeneralPagination defaultCurrent={page} total={maxPage * 10} onChange={goToPage} />
		</div>
	)
}

export default List
