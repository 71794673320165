import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

export default function TableTopBar() {
	const { onOpen, setEditState } = useContext(otherTableContext)

	function openModal() {
		setEditState((pre) => (pre = { editType: 'create' }))
		onOpen()
	}

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700">
				尺寸設定
			</Box>
			<Box>
				<Box onClick={openModal} border="1px solid  #2250A6" d="flex" borderRadius="20px" p=".4em 1.2em" alignItems="center" color="#2250A6" fontWeight="700" cursor="pointer">
					<HiPlusSm />
					<Text ml="5px">新增尺寸</Text>
				</Box>
			</Box>
		</StyledTopbar>
	)
}
