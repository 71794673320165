import { Box, Tabs, Tab, TabList, TabPanel, TabPanels, Image } from '@chakra-ui/react'
import logoIcon from 'assets/images/logo.svg'
import React, { useContext, useEffect } from 'react'
import { storeSalesContext } from './useContext/createContext'
import Chart from 'chart.js/auto'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

function labelSwitcher(labelEN) {
	switch (labelEN) {
		case 'total_sales':
			return '銷售總額'
		case 'sale_order_count':
			return '銷貨單數量'
		case 'cost':
			return '成本金額'
		case 'net_sales':
			return '銷售淨額'
		default:
			return ''
	}
}

export default function ChartContent() {
	const { chartData, tableData } = useContext(storeSalesContext)
	let canvasDomIdArr = ['total_sales', 'sale_order_count', 'cost', 'net_sales']
	useEffect(() => {
		if (chartData.length > 0) {
			chartRenderer(chartData, canvasDomIdArr)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData])

	function chartDomRenderer(canvasDomIdArr) {
		return (
			<Tabs w="100%">
				<TabList>
					{canvasDomIdArr.map((item, i) => (
						<Tab key={i}>{labelSwitcher(item)}</Tab>
					))}
				</TabList>
				<TabPanels>
					{canvasDomIdArr.map((item, i) => (
						<TabPanel p={4} key={i}>
							<canvas id={item}></canvas>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		)
	}

	function chartRenderer(chartData, canvasDomIdArr) {
		for (const canvasDomId of canvasDomIdArr) {
			// 重新render前需要清空原本render過的圖表
			if (window[canvasDomId + '_chart'] !== undefined) {
				window[canvasDomId + '_chart'].destroy()
			}
			let tempLabels = []
			let tempDatasets = []
			for (const item of chartData) {
				let tempSingleData = {}
				tempLabels = item.label
				tempSingleData.label = item.location
				tempSingleData.backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)']
				tempSingleData.borderColor = ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)']
				tempSingleData.borderWidth = 1
				tempSingleData.data = item[canvasDomId]
				tempDatasets.push(tempSingleData)
			}
			window[canvasDomId + '_chart'] = new Chart(document.getElementById(`${canvasDomId}`), {
				type: 'line',
				data: {
					labels: tempLabels,
					datasets: tempDatasets,
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			})
		}
	}

	function renderSubtotalRow(data) {
		const totalSales = data.reduce((acc, cur) => acc + cur.total_sales, 0)
		const saleOrderCount = data.reduce((acc, cur) => acc + cur.sale_order_count, 0)
		const cost = data.reduce((acc, cur) => acc + cur.cost, 0)
		const netSales = data.reduce((acc, cur) => acc + cur.net_sales, 0)

		return (
			<tr>
				<td>小計</td>
				<td>{totalSales}</td>
				<td>{saleOrderCount}</td>
				<td>{cost}</td>
				<td>{netSales}</td>
			</tr>
		)
	}

	return (
		<Box>
			<Box display="flex" justifyContent="center" flexWrap="wrap" mb="2em">
				{chartData.length > 0 ? (
					chartDomRenderer(canvasDomIdArr)
				) : (
					<Box py="4em">
						<Image src={logoIcon} opacity=".6" />
					</Box>
				)}
			</Box>
			<Box>
				{tableData.length > 0 ? (
					<Tabs w="100%">
						<TabList>
							{tableData.map((items, i) => (
								<Tab key={i}>{items.location}</Tab>
							))}
						</TabList>
						<TabPanels>
							{/* table應該要拆出去做一個component */}
							{tableData.map((items, i) => (
								<TabPanel p={4} key={i}>
									<StyledTable>
										<table>
											<thead>
												<tr>
													<td>日期</td>
													<td>銷售總額</td>
													<td>銷售單數量</td>
													<td>成本金額</td>
													<td>銷售淨額</td>
												</tr>
											</thead>
											<tbody>
												{items.date_data.map((item, j) => (
													<tr key={j}>
														<td>{item.date}</td>
														<td>{item.total_sales}</td>
														<td>{item.sale_order_count}</td>
														<td>{item.cost}</td>
														<td>{item.net_sales}</td>
													</tr>
												))}
												{renderSubtotalRow(items.date_data)}
											</tbody>
										</table>
									</StyledTable>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				) : null}
			</Box>
		</Box>
	)
}
