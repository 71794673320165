import React from 'react'
import { Box, Avatar, useDisclosure } from '@chakra-ui/react'
import { IoIosArrowDown } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { logout } from 'reducers/auth'
import { getCookie } from 'utils/cookies'
import styled from 'styled-components'
import { BiLogOut } from 'react-icons/bi'
import AuthService from 'Api/authService'
import { error, success, warning } from 'reducers/toast'
import Punch from './Punch'

const StyledMenu = styled(Box)`
	background: #ffffff;
	border: 1px solid;
	box-sizing: border-box;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	position: absolute;
	right: 0;
	top: 110%;
	overflow: hidden;
	transition: all 0.1s ease;
	z-index: 10;
`

const StyledMenuItem = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1.5em;
	cursor: pointer;
	position: relative;

	&:after {
		content: '';
		height: 1px;
		width: 80%;
		background: #d7d7d7;
		position: absolute;
		bottom: -1px;
	}
	&:last-child {
		&:after {
			display: none;
		}
	}

	&:hover {
		background: #2250a6;
		color: white;
	}
`

export default function User() {
	const dispatch = useDispatch()
	const { isOpen, onToggle, onClose } = useDisclosure()
	async function handleLogout() {
		const authService = new AuthService()
		const result = await authService.logout()
		const { data, status } = result
		switch (status) {
			case 200:
				dispatch(success({ message: '登出成功', status: 200 }))
				break
			case 400:
				dispatch(warning({ message: result.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
		//不管發生何種情況都要讓USER登出
		dispatch(logout())
	}

	return (
		<>
			<Box d="flex" alignItems="center" mr="1em" position="relative">
				<Box cursor="pointer" fontSize="1.2em" d="flex" alignItems="center" onClick={onToggle}>
					<Box mb=".2em" mr="1em">
						Hi, {getCookie('userName')}
					</Box>
					<Box d="flex" alignItems="center">
						<Avatar mr=".4em" />
						<IoIosArrowDown />
					</Box>
				</Box>
				<StyledMenu className="test" maxH={isOpen ? '1000' : '0'} borderColor={isOpen ? '#d7d7d7' : 'transparent'}>
					<Punch parentOnClose={onClose} />
					<StyledMenuItem onClick={handleLogout}>
						<Box mr=".5em">
							<BiLogOut />
						</Box>
						登出
					</StyledMenuItem>
				</StyledMenu>
			</Box>
		</>
	)
}
