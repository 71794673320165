import { Switch } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { closeInventory } from 'Api/inventory'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

function FormSwitch({ sheetId, status, refetchList }) {
	const dispatch = useDispatch()
	const [checked, setChecked] = React.useState(false)

	const closeInventoryMutation = useMutation({
		mutationFn: closeInventory(sheetId),
		onSuccess: () => {
			dispatch(success({ message: '關閉盤點單！' }))
			refetchList()
		},
		onError: (err) => {
			if (err?.status === 400) {
				dispatch(warning({ message: err?.data?.message }))
			} else {
				dispatch(error({ message: err?.data?.message }))
			}
		},
	})

	useEffect(() => {
		if (status === '盤點中') {
			setChecked(true)
		}
	}, [status])

	const handleOnChange = (e) => {
		const isTurnOff = !e.target.checked

		if (isTurnOff) {
			const result = window.confirm('確定要關閉盤點嗎?')
			if (result) {
				closeInventoryMutation.mutate()
			}
		} else {
			alert('無法重啟已結束的盤點。')
		}
	}

	return <Switch size="md" onChange={handleOnChange} isChecked={checked} disabled={!checked} />
}

export default FormSwitch
