import React from 'react'
import { Box, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`
export default function TabData({ tableData }) {
	return (
		<Tabs w="100%">
			<TabList>
				{tableData.map((items, i) => (
					<Tab key={i}>{items.brand}</Tab>
				))}
			</TabList>
			<TabPanels>
				{/* table應該要拆出去做一個component */}
				{tableData.map((items, i) => (
					<TabPanel p={4} key={i}>
						<StyledTable>
							<table>
								<thead>
									<tr>
										<td>日期</td>
										<td>品牌銷售額</td>
										<td>品牌銷售數量</td>
									</tr>
								</thead>
								<tbody>
									{items.date_data.map((item, j) => (
										<tr key={j}>
											<td>{item.date}</td>
											<td>{item.sale_price}</td>
											<td>{item.sales}</td>
										</tr>
									))}
								</tbody>
							</table>
						</StyledTable>
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	)
}
