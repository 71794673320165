import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MdKeyboardArrowRight } from 'react-icons/md'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: flex-start;
	margin-bottom: 1em;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 80px 0 0 80px;
	}
`

const Title = styled(Box)`
	font-size: 1.25em;
	font-weight: 700;
`

function TableTopBar({ title, subtitle, children }) {
	return (
		<StyledTopbar className="topbar">
			<Title display="flex">
				{title}
				{subtitle && (
					<Box display="flex">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{subtitle}
					</Box>
				)}
			</Title>
			<Box>{children}</Box>
		</StyledTopbar>
	)
}

export default TableTopBar

TableTopBar.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	children: PropTypes.node,
}
