import { Box } from '@chakra-ui/react'
import PictureModal from 'components/modals/PictureModal'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
	thead {
		td {
			white-space: nowrap;
			padding: 1em 0.5em;

			&:first-child {
				width: 140px;
			}
		}
	}

	@media print {
		table {
			tr {
				td[print='hidden'] {
					display: none;
				}
			}
		}
	}
`

export default function DisplayTable({ productList }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td print="hidden">商品編號</td>
						<td>品名</td>
						<td print="hidden">類別</td>
						<td>品牌名</td>
						<td>顏色</td>
						<td>尺寸</td>
						<td>狀況</td>
						<td>類型</td>
						<td>備註</td>
						<td>進價</td>
					</tr>
				</thead>
				<tbody>
					{productList.length > 0
						? productList.map((item, i) => (
								<tr key={i}>
									<td print="hidden">
										<PictureModal imageUrl={item.product_image} text={item.product_no} />
									</td>
									<td>{item.product_name}</td>
									<td print="hidden">{item.product_type}</td>
									<td>{item.product_brand}</td>
									<td>{item.product_color}</td>
									<td>{item.product_size}</td>
									<td>{item.product_condition}</td>
									<td>{item.type}</td>
									<td>{item.product_notes}</td>
									<td>{item.product_price ? item.product_price : '從缺'}</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
