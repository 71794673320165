import React, { useContext } from 'react'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { purchaseContext } from './useContext/createContext'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight, MdPrint } from 'react-icons/md'
import TopbarForm from './component/TopbarForm'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}

	@media print {
		display: none;
	}
`

TableTopBar.propTypes = {
	pageState: PropTypes.oneOf(['viewList', 'create', 'print']),
}

export default function TableTopBar({ pageState }) {
	const { setPrintType } = useContext(purchaseContext)
	const history = useHistory()

	const gotoCreatePage = () => {
		history.push('/admin/purchase/purchaseSheet/create')
	}

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex" whiteSpace="nowrap">
				進貨單管理
				{pageState !== 'viewList' && (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{pageState === 'create' ? '新增進貨單' : null}
						{pageState === 'print' ? '列印進貨單' : null}
					</Box>
				)}
			</Box>
			<Box d="flex">
				{pageState === 'viewList' && <TopbarForm />}
				{pageState === 'viewList' && (
					<StyledButtonBox whiteSpace="nowrap" onClick={gotoCreatePage}>
						<HiPlusSm />
						<Text ml="5px">新增進貨單</Text>
					</StyledButtonBox>
				)}
				{pageState === 'print' ? (
					<>
						<StyledButtonBox
							onClick={() => {
								setPrintType('table')
							}}
							mr="1rem"
						>
							<MdPrint /> 列印
						</StyledButtonBox>
						<StyledButtonBox
							onClick={() => {
								setPrintType('precautions')
							}}
						>
							<MdPrint /> 列印簽單
						</StyledButtonBox>
					</>
				) : null}
			</Box>
		</StyledTopbar>
	)
}
