import SaleService from 'Api/sale/saleService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { success, warning } from 'reducers/toast'

export default function useGetSaleOrderList(date) {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const [isNeedFetch, setIsNeedFetch] = useState(true)
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()
	async function fetchData() {
		setTableData((pre) => (pre = { data: [], isFetching: true }))
		const saleService = new SaleService()

		let getListData
		if (date.date) getListData = await saleService.getSaleOrderListByDate(date.date.startDate, date.date.endDate, currentPage)
		if (!date.date) getListData = await saleService.getSaleOrderList(currentPage)
		const { status, data } = getListData

		switch (status) {
			case 200:
				setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
				setTableData({ data: [...data.response.data], isFetching: false })
				if (date.startDate) dispatch(success({ message: data.message, status: status }))
				break
			case 400:
				setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
				setTableData({ data: [], isFetching: false })
				dispatch(warning({ message: data.message, code: data.code }))
				break
			default:
				setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
				setTableData({ data: [], isFetching: false })
				break
		}

		setIsNeedFetch(false)
	}
	useEffect(() => {
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetch])

	useEffect(() => {
		setIsNeedFetch(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	useEffect(() => {
		// 重新search的話要reset currentPage/maxPage
		// 免得會出現重新search以後，出現 89 / 10 這種 currentPage / maxPage的情況
		setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
		setIsNeedFetch(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date || ''])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}

	return [currentPage, maxPage, gotoPage, tableData]
}
