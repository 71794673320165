import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text, ModalOverlay, Modal, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import useGetOptionDetail from 'hook/paymentTable/useGetOptionDetail'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { StyledButtonBox } from 'utils/StyledButton'
import { paymentListContext } from '../useContext/createContext'
import DetailTable from './DetailTable'
const StyledTimeBox = styled(Box)`
	border-color: black !important;
	border-bottom: 1px solid;
	color: #a1a1a1;
	min-height: 1.5em;
	min-width: 160px;
	margin-top: 1em;
	text-align: left;
	padding-bottom: 0.5em;
	padding-left: 1em;
`

const StyledBox = styled(Box)`
	border: 1px solid;
	border-radius: 10px;
	padding: 0.5em;
	text-align: center;
`

export default function DetailModel({ vendorDetailId, setVendorDetailId }) {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { editState } = useContext(paymentListContext)
	const [detailData] = useGetOptionDetail(editState, vendorDetailId)

	useEffect(() => {
		if (vendorDetailId) onOpen()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorDetailId])
	useEffect(() => {
		return () => setVendorDetailId('')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function handleOnClose() {
		setVendorDetailId('')
		onClose()
	}
	return (
		<Modal isOpen={isOpen} onClose={handleOnClose}>
			<ModalOverlay />
			<ModalContent maxW="1000px">
				<ModalHeader d="flex" justifyContent="center">
					付款記錄詳情
				</ModalHeader>
				<ModalCloseButton onClick={handleOnClose} />
				<ModalBody>
					<Box d="flex" justifyContent="space-between" pr="2em">
						<Box d="flex">
							<Box mr="4em">
								<Text>廠商姓名</Text>
								<Box d="flex" alignItems="center">
									<StyledTimeBox>{detailData.data.vendor || '廠商姓名'}</StyledTimeBox>
								</Box>
							</Box>
							<Box>
								<Text>廠商電話</Text>
								<Box d="flex" alignItems="center">
									<StyledTimeBox>{detailData.data.phone}</StyledTimeBox>
								</Box>
							</Box>
						</Box>

						<Box d="flex">
							<StyledBox>
								<Box px="2.5em" pb=".5em" borderBottom="1px solid">
									總金額
								</Box>
								<Box minH="2.2em" d="flex" alignItems="center" justifyContent="center">
									{detailData.data.totalPrice || 0}
								</Box>
							</StyledBox>
						</Box>
					</Box>
					{detailData.data.products ? <DetailTable productList={detailData.data.products} /> : null}
				</ModalBody>

				<ModalFooter justifyContent="center">
					<StyledButtonBox onClick={handleOnClose}>完成</StyledButtonBox>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
