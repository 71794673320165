import { Box } from '@chakra-ui/layout'
import React from 'react'
import styled from 'styled-components'
const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}

	margin: 10px;
	max-height: 65vh;
	overflow: scroll;
`
export default function MaintypeTable({ data }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>員工編號</td>
						<td>員工姓名</td>
						<td>銷售額</td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, i) => (
						<tr key={i}>
							<td>{item.user_no}</td>
							<td>{item.name}</td>
							<td>{item.sale_amount}</td>
						</tr>
					))}
				</tbody>
			</table>
		</StyledTable>
	)
}
