import { Box, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { getInventorySheetDetail } from 'Api/inventory'
import BasicTable from 'components/basicTable'
import PictureModal from 'components/modals/PictureModal'
import GeneralPagination from 'components/pagination'
import React, { useEffect } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { warning } from 'reducers/toast'
import { StyledButtonBox } from 'utils/StyledButton'

const SkeletonText = ({ text }) => {
	return <>{text || <Skeleton w="150px" h="20px" display="inline-block" />}</>
}

const TabComponent = ({ tableData = [], columns = [], isFetching = false }) => {
	const [page, setPage] = React.useState(1)
	const recordsFilteredByPage = tableData.slice((page - 1) * 10, page * 10)

	return (
		<>
			<BasicTable tableData={recordsFilteredByPage} columns={columns} isLoading={isFetching} />
			<GeneralPagination defaultCurrent={page} total={tableData.length} onChange={(e) => setPage(e)} />
		</>
	)
}

function DetailPage() {
	const dispatch = useDispatch()
	const matchCreatePage = useRouteMatch('/admin/inventory/inventorySheet/detail/:id')
	const history = useHistory()
	const sheetId = matchCreatePage?.params?.id

	const { data, isFetching, isError, error } = useQuery(['getInventorySheetDetail', sheetId], { queryFn: getInventorySheetDetail(sheetId) })

	useEffect(() => {
		if (isError) {
			dispatch(warning({ message: error?.data?.message }))
		}
	}, [isError, dispatch, error])

	const records = data?.data?.response?.records || []
	const normalRecords = records.filter((item) => item.result === '正常')
	const abnormalRecords = records.filter((item) => item.result === '異常')
	const notInventoryRecords = data?.data?.response?.not_inventoried_products || []

	const location = data?.data?.response?.location || ''
	const expectedInventoryAmount = data?.data?.response?.expected_quantity || 0 // 盤點店家預期盤點數量
	const normalInventoryAmount = data?.data?.response?.normal_quantity || 0 // 盤點店家正常已盤點數量
	const abnormalInventoryAmount = data?.data?.response?.abnormal_quantity || 0 // 盤點店家異常已盤點數量
	const startTime = data?.data?.response?.start_time || ''
	const endTime = data?.data?.response?.end_time || ''
	const startBy = data?.data?.response?.start_by || ''
	const endBy = data?.data?.response?.end_by || ''

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'product_no',
			},
			{
				Header: () => (
					<Box display="flex" alignItems="center" justifyContent="center">
						<Box>品名</Box>
						<Tooltip label="點擊商品可看商品圖片">
							<span>
								<AiOutlineInfoCircle />
							</span>
						</Tooltip>
					</Box>
				),
				accessor: 'product',
				Cell: (row) => {
					const imageUrl = row.row.original.product_image
					return (
						<Box display="flex" alignItems="center" justifyContent="center">
							<PictureModal imageUrl={imageUrl} text={row.value} />
						</Box>
					)
				},
			},
			{
				Header: '品牌',
				accessor: 'product_brand_name',
			},
			{
				Header: '商品狀態',
				accessor: 'product_status',
			},
			{
				Header: '商品類型',
				accessor: 'product_type',
				Cell: (row) => {
					const { value } = row
					const isPurchasedProduct = value === '買斷'

					return (
						<Box fontWeight={isPurchasedProduct && 'bold'} color={isPurchasedProduct && 'red.300'}>
							{value}
						</Box>
					)
				},
			},
			{
				Header: '商品盤點結果',
				accessor: 'result',
			},
			{
				Header: '異常情形',
				accessor: 'abnormal_reason',
			},
		],
		[]
	)

	return (
		<Box>
			<Box d="flex" justifyContent="space-between" alignItems="center" mb="1.5em" mt="2em" fontSize="1.1rem">
				<Box>
					盤點店家：
					<SkeletonText text={location} />
					<br />
					正常盤點總數：
					<Text as="samp" color="blue" m="0">
						{normalInventoryAmount}
					</Text>
					<br />
					預計商品總數：{expectedInventoryAmount}
					<br />
					異常盤點總數：
					<Text as="samp" color="red.300">
						{abnormalInventoryAmount}
					</Text>
					<br />
				</Box>

				<Box>
					開始盤點時間：
					<SkeletonText text={startTime} />
					<br />
					結束盤點時間：
					<SkeletonText text={endTime} />
					<br />
					開始盤點人員：
					<SkeletonText text={startBy} />
					<br />
					結束盤點人員：
					<SkeletonText text={endBy} />
				</Box>
			</Box>

			<Tabs>
				<TabList>
					<Tab>成功盤點</Tab>
					<Tab>異常盤點</Tab>
					<Tab>未盤點</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<TabComponent tableData={normalRecords} isFetching={isFetching} columns={columns} />
					</TabPanel>
					<TabPanel>
						<TabComponent tableData={abnormalRecords} isFetching={isFetching} columns={columns} />
					</TabPanel>
					<TabPanel>
						<TabComponent tableData={notInventoryRecords} isFetching={isFetching} columns={columns} />
					</TabPanel>
				</TabPanels>
			</Tabs>

			<Box display="flex" justifyContent="center" mt="2rem">
				<StyledButtonBox
					onClick={() => {
						history.push('/admin/inventory/inventorySheet')
					}}
				>
					返回
				</StyledButtonBox>
			</Box>
		</Box>
	)
}

export default DetailPage
