import { createSlice } from '@reduxjs/toolkit'
import { resetCookies, setLoginInfo, getCookie } from 'utils/cookies'

let tokenCookies = getCookie('token')
let nameCookies = getCookie('name')

export const authStateSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuth: tokenCookies ? true : false,
		name: nameCookies,
		message: '',
		status: 0,
	},
	reducers: {
		login: (state, action) => {
			resetCookies()
			const { payload } = action
			switch (payload.status) {
				case 200:
					setLoginInfo(payload.data.response.access_token, payload.data.response.user.name, payload.data.response.user, payload.data.response.user.user_no)
					return { ...state, isAuth: true }
				case 400:
					return { ...state, isAuth: false }
				default:
					// return state
					return { ...state, isAuth: false }
			}
		},
		logout: (state, action) => {
			resetCookies()
			return { ...state, isAuth: false }
		},
	},
})

export const { login, logout } = authStateSlice.actions
export default authStateSlice.reducer
