import { RoleService } from 'Api/role/roleService'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetAllRoles() {
	const [roleListData, setRoleListData] = useState({ data: [], isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setRoleListData((pre) => (pre = { ...pre, isFetching: true }))
			const roleService = new RoleService()
			const result = await roleService.getAllRoles()
			const { data, status } = result
			switch (status) {
				case 200:
					setRoleListData((pre) => (pre = { data: [...data.response.data], isFetching: false }))
					break
				case 400:
					setRoleListData((pre) => (pre = { data: [], isFetching: false }))
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					setRoleListData((pre) => (pre = { data: [], isFetching: false }))
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return [roleListData]
}
