import { useToast } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'reducers/auth'

export default function ToastManager() {
	const toast = useToast()
	const dispatch = useDispatch()
	const { message, status, code, date } = useSelector((state) => state.toastManager)
	useEffect(() => {
		if (message) {
			// 901 & 902都是跟TOKEN有關
			// 901是TOKEN過期、 902是TOKEN錯誤
			if (code === 902 || code === 901) {
				toast({ title: '系統通知', description: message, status: status, isClosable: true, duration: 15000 })
				dispatch(logout())
			}
			if (code !== 902 || code !== 902) {
				if (message === '無此操作權限') {
					//任何情況只要權限不足，就是直接回admin畫面
					toast({ title: '系統通知', description: message, status: status, isClosable: true, duration: 1500 })
					setTimeout(function routeRedirect() {
						// history.replace('/admin')
					}, 1000)
					return
				}
				toast({ title: '系統通知', description: message, status: status, isClosable: true, duration: 1500 })
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, code, message])
	return <></>
}
