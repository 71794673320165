import Axios from 'Api/interceptors'

export class ProductService {
	async getProductList(params, currentPage) {
		let paramStr = ''
		if (params.startDate) {
			params.startDate = params.startDate.split('-').join('/')
		}
		if (params.endDate) {
			params.endDate = params.endDate.split('-').join('/')
		}
		for (const param in params) {
			if (param === 'startDate') {
				paramStr += 'start_date=' + (params[param] || '') + '&'
			} else if (param === 'endDate') {
				paramStr += 'end_date=' + (params[param] || '') + '&'
			} else {
				paramStr += param + '=' + (params[param] || '') + '&'
			}
		}

		let result
		try {
			if (paramStr || params) {
				result = await Axios.get(`/products?${paramStr}page=${currentPage || ''}`)
			}
			if (!params) result = await Axios.get('/products?page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOneProduct(productId) {
		let result
		try {
			result = await Axios.get('/products/' + productId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOneProductForTransfer(productId) {
		let result
		try {
			result = await Axios.get('/transferOrders/' + productId + '/product')
		} catch (error) {
			result = error
		}
		return result
	}

	async getOneProductForSale(productId) {
		let result
		try {
			result = await Axios.get('/saleOrders/' + productId + '/product')
		} catch (error) {
			result = error
		}
		return result
	}
	async updateProduct(productId, payload) {
		let result
		try {
			result = await Axios.put('/products/' + productId, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async changeReserveState(productId, payload) {
		let result
		try {
			result = await Axios.put('/products/' + productId + '/reserve', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteProduct(productId) {
		let result
		try {
			result = await Axios.delete('/products/' + productId)
		} catch (error) {
			result = error
		}

		return result
	}

	async changeProductPrice(productId, payload) {
		let result
		try {
			result = await Axios.post('/products/' + productId + '/sale_price', payload)
		} catch (error) {
			result = error
		}

		return result
	}

	async changeProductPurchasePrice(productId, payload) {
		let result
		try {
			result = await Axios.post(`products/${productId}/price`, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async getPurchasePriceLog(productId) {
		let result
		try {
			result = await Axios.get(`/products/${productId}/price`)
		} catch (error) {
			result = error
		}
		return result
	}

	async getPriceLog(productId) {
		let result
		try {
			result = await Axios.get('/products/' + productId + '/sale_price')
		} catch (error) {
			result = error
		}

		return result
	}

	async updateProductShelf(productId, formData) {
		let result
		try {
			result = await Axios.post(`/products/${productId}/area`, formData)
		} catch (error) {
			result = error
		}
		return result
	}
}

export const getProductList = async (page, searchParams) => {
	const paramsStr = Object.keys(searchParams)
		.reduce((acc, key) => {
			if (searchParams[key]) {
				if (key === 'start_date' || key === 'end_date') {
					searchParams[key] = searchParams[key].replaceAll('-', '/')
				}
				acc += `${key}=${searchParams[key]}&`
			}
			return acc
		}, '')
		.split('&')
		.slice(0, -1)
		.join('&')
	return await Axios.get(`/products?page=${page || ''}&${paramsStr}`)
}

export const getOneProduct = (productId) => async () => {
	if (!productId) return
	return await Axios.get('/products/' + productId)
}
