import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

export default function TableTopBar() {
	const { editState, setEditState } = useContext(otherTableContext)

	function openModal() {
		setEditState((pre) => (pre = { editType: 'createAuth' }))
	}
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				權限設定
				{editState.editType !== '' && editState.editType !== 'delete' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{editState.editType === 'authEdit' ? '編輯權限' : null}
						{editState.editType === 'createAuth' ? '新增權限' : null}
					</Box>
				) : null}
			</Box>
			{editState.editType !== 'authEdit' ? (
				<Box>
					<StyledButtonBox onClick={openModal}>
						<HiPlusSm />
						<Text ml="5px">新增權限</Text>
					</StyledButtonBox>
				</Box>
			) : null}
		</StyledTopbar>
	)
}
