import Axios from 'Api/interceptors'

export class LocationService {
	async getLocation() {
		let result
		try {
			result = await Axios.get('/locations')
		} catch (error) {
			result = error
		}
		return result
	}
	async createLocation(payload) {
		let result
		try {
			result = await Axios.post('/locations', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateLocation(id, payload) {
		let result
		try {
			result = await Axios.put('/locations/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteLocation(id) {
		let result
		try {
			result = await Axios.delete('/locations/' + id)
		} catch (error) {
			result = error
		}
		return result
	}

	async getLoginLocation() {
		let result
		try {
			result = await Axios.get('/users/locations')
		} catch (error) {
			result = error
		}
		return result
	}
}

export const getLocations = () => {
	return Axios.get('/locations')
}
