import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { useQuery } from '@tanstack/react-query'
import { getDailyReport } from 'Api/dailySalesReport'
import styled from 'styled-components'
import { Box, Image, Spinner } from '@chakra-ui/react'
import logoIcon from 'assets/images/logo.svg'
import ChartContent from './ChartContent'
import TableTopBar from './TableTopBar'

const StyledTable = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;

	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	const [locationIdsStr, setLocationIdStr] = useState()
	const dispatch = useDispatch()

	const today = new Date().toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' })

	const {
		data,
		refetch,
		isFetching,
		isFetched,
		isSuccess,
		isError,
		error: fetchedError,
	} = useQuery({
		queryKey: ['dailyReport'],
		queryFn: () => getDailyReport(today, locationIdsStr),
		enabled: false,
	})

	useEffect(() => {
		if (locationIdsStr) {
			refetch()
		}
	}, [locationIdsStr, refetch])

	useEffect(() => {
		if (isSuccess) {
			dispatch(success({ message: '取得當日報表成功' }))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (isError) {
			if (fetchedError?.status === 400) {
				dispatch(warning({ message: fetchedError?.data?.message }))
			} else {
				dispatch(error({ message: fetchedError?.data?.message }))
			}
		}
	}, [isError, dispatch, fetchedError])

	return (
		<StyledTable>
			<TableTopBar formattedDate={today} setLocationIdStr={setLocationIdStr} />
			{isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : isFetched && data && locationIdsStr ? (
				<ChartContent data={data} />
			) : (
				<Box py="4em" mb="2em" display="flex" flexWrap="wrap" justifyContent="center">
					<Image src={logoIcon} opacity=".6" />
				</Box>
			)}
		</StyledTable>
	)
}
