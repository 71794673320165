import { useEffect, useContext, useState } from 'react'
import { Box, Text, useToast } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";
import { tempPurchaseContext, SelectOptionsProvider, UpdateModalProvider } from './useContext/createContext'
import { FormSubmitButton } from 'utils/StyledButton'
import { FaPlus, FaTrashAlt } from 'react-icons/fa'
import SingleItem from './component/singleItem/SingleItem'
import UpdateModal from './component/singleItem/UpdateModal'
// import LocationSearchForm from './component/createLocation/LocationSearchForm'
import TempPurchaseOrdersService from 'Api/tempPurchaseOrder/tempPurchaseOrderService'
import useGetTempDetailPurchaseOrder from 'hook/tempPurchaseTable/useGetTempDetailPurchaseOrder'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
`

const StyledForm = styled.form`
	width: 100%;
`

export default function SubContent() {
	const { editState, setEditState, selectOptionsData } = useContext(tempPurchaseContext)
	// const { setPurchaseOrderIsNeedFetch } = useContext(purchaseContext)
	const { register, handleSubmit } = useForm()
	const [detailList] = useGetTempDetailPurchaseOrder(editState.orderId)

	const [merchantListData, setMerchantListData] = useState([])
	const [vendorInfo, setVendorInfo] = useState({ id: '', name: '未選擇', phone: '未選擇' })
	const [locationInfo, setLocationInfo] = useState({ id: '', name: '未選擇' })
	const [updateModalInfo, setUpdateModalInfo] = useState({ type: '' })
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const dispatch = useDispatch()
	const history = useHistory();


	const { vendor_id, vendor, vendor_phone, location_id, location_name } = detailList.data

	useEffect(() => {
		setVendorInfo((pre) => (pre = { ...pre, id: vendor_id, name: vendor, phone: vendor_phone }))
		setLocationInfo((pre) => (pre = { ...pre, id: location_id, name: location_name }))

		detailList.data.products && setMerchantListData(detailList.data.products)
	}, [detailList.data, vendor_id, vendor, vendor_phone, location_id, location_name, selectOptionsData])
	// 新增下一筆
	const newMerchant = () => {
		setMerchantListData((pre) => pre.concat({ uuid: Date.now() }))
	}

	const deleteLastMerchant = () => {
		setMerchantListData((pre) => (pre = pre.slice(0, pre.length - 1)))
	}

	// 防止ENTER送出表單
	const checkKeyDown = (e) => {
		if (e.key === 'Enter') e.preventDefault()
	}

	const singleDataWarning = (singleData) => {
		let isFormDataOk = true

		for (let i = 0; i < singleData.length; i++) {
			let wording = []
			if (!merchantListData[i].name) {
				isFormDataOk = false
				wording.push('品名未輸入')
			}
			if (!merchantListData[i].brand_id) {
				isFormDataOk = false
				wording.push('品牌名未選擇')
			}
			if (!merchantListData[i].color_id) {
				isFormDataOk = false
				wording.push('顏色未選擇')
			}
			if (!merchantListData[i].size_id) {
				isFormDataOk = false
				wording.push('尺寸未選擇')
			}
			if (!merchantListData[i].condition_id) {
				isFormDataOk = false
				wording.push('狀況未選擇')
			}
			if (!merchantListData[i].type_id) {
				isFormDataOk = false
				wording.push('類別未選擇')
			}
			if (!merchantListData[i].subtype_id) {
				isFormDataOk = false
				wording.push('子類別未選擇')
			}
			if (!merchantListData[i].type && merchantListData[i].type !== 0) {
				isFormDataOk = false
				wording.push('類型未選擇')
			}
			if (!merchantListData[i].price) {
				isFormDataOk = false
				wording.push('進價未填寫')
			}
			if (wording.length > 0) toast({ titel: '系統通知', description: `第${i + 1}的商品的${wording.join('、')}`, status: 'warning' })
		}
		return isFormDataOk
	}

	const submitHandler = async (e) => {
		let formData = {
			vendor_id: vendorInfo.id,
			location_id: locationInfo.id,
			products: merchantListData,
			transfer_to_purchase_order: true,
		}
		// formData 檢驗流程 start
		if (!singleDataWarning(merchantListData)) return
		if (!formData.vendor_id) {
			toast({ titel: '系統通知', description: `尚未選擇廠商`, status: 'warning' })
			return
		}
		if (!formData.location_id) {
			toast({ titel: '系統通知', description: `尚未選擇進貨地點`, status: 'warning' })
			return
		}
		// formData 檢驗流程 end
		setIsLoading(true)
		setEditState({ editType: 'viewList' })
		const tempPurchaseOrderService = new TempPurchaseOrdersService()
		const result = await tempPurchaseOrderService.editTempPurchaseOrder(editState.orderId, formData, true)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				history.push("/admin/purchase/purchaseSheet");
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
		setIsLoading(false)
	}

	const submitTempHandler = async (e) => {
		let formData = {
			vendor_id: vendorInfo.id,
			location_id: locationInfo.id,
			products: merchantListData,
			transfer_to_purchase_order: false,
		}

		setIsLoading(true)
		const tempPurchaseOrderService = new TempPurchaseOrdersService()
		const result = await tempPurchaseOrderService.editTempPurchaseOrder(editState.orderId, formData)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				setEditState({ editType: 'viewList' })
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
		setIsLoading(false)
	}

	function updateData(name, value, order) {
		let array = merchantListData.map((item, i) => {
			if (i === order) {
				return { ...item, [name]: value }
			} else {
				return item
			}
		})
		setMerchantListData(array)
	}

	const SelectOptionsProviderValue = { selectOptionsData, updateData, setVendorInfo, setLocationInfo }
	const UpdateModalValue = { updateModalInfo, setUpdateModalInfo, merchantListData }
	return (
		<StyledTransitionBg>
			<SelectOptionsProvider value={SelectOptionsProviderValue}>
				<UpdateModalProvider value={UpdateModalValue}>
					<Box d="flex" flexWrap="wrap" mb="1.5em" alignItems="center">
						<Box display="flex" alignItems="center">
							<Text color="red">
								*
							</Text>
							<Box d="flex" borderBottom="1px solid" px=".7em" py=".3em">
								<Box>廠商名稱：{vendorInfo.name}</Box>
							</Box>
						</Box>
						<Box display="flex" alignItems="center">
							<Text color="red" ml="3em">
								*
							</Text>
							<Box d="flex" borderBottom="1px solid" px=".7em" py=".3em">
								<Box ml="1em">廠商電話：{vendorInfo.phone}</Box>
							</Box>
						</Box>
						<Box display="flex" alignItems="center">
							<Text color="red" ml="3em">
								*
							</Text>
							<Box d="flex" borderBottom="1px solid" px=".7em" py=".3em">
								<Box>地點：{locationInfo.name}</Box>
							</Box>
						</Box>
					</Box>

					{/* 地點選擇隱藏掉，如果要啟用的話直接開啟即可 */}
					{/* <Box d="flex" flexWrap="wrap" mb="1.5em" alignItems="center">
						<LocationSearchForm typeData={selectOptionsData.data.locations || []} />
						<Text color="red" ml="3em">
							*
						</Text>
						<Box d="flex" borderBottom="1px solid" px=".7em" py=".3em">
							<Box>地點：{locationInfo.name}</Box>
						</Box>
					</Box> */}

					<UpdateModal />

					<StyledForm onSubmit={handleSubmit((e) => submitHandler(e))} autoComplete="off" onKeyDown={(e) => checkKeyDown(e)}>
						{merchantListData.map((item, i) => {
							return (
								<SingleItem
									register={register}
									order={i}
									key={item.uuid}
									setMerchantListData={setMerchantListData}
									merchantListData={merchantListData}
								/>
							)
						})}
						<Box d="flex" justifyContent="space-around">
							<Box
								flexBasis="45%"
								bgColor="gray.100"
								p="1em 2em"
								borderRadius="10px"
								my="1em"
								onClick={deleteLastMerchant}
								cursor="pointer"
								_hover={{ bgColor: 'gray.600', color: 'white' }}
								transition="all .2s ease"
							>
								<Box textAlign="center">
									<Box d="inline-block" mb="1em" color="red.300">
										<FaTrashAlt size="30px" />
									</Box>
									<Box>刪除最後一筆</Box>
								</Box>
							</Box>
							<Box
								flexBasis="45%"
								background="#f0f5ff"
								p="1em 2em"
								borderRadius="10px"
								my="1em"
								onClick={newMerchant}
								cursor="pointer"
								_hover={{ bgColor: 'gray.600', color: 'white' }}
								transition="all .2s ease"
							>
								<Box textAlign="center">
									<Box d="inline-block" mb="1em">
										<FaPlus size="30px" />
									</Box>
									<Box>添加下一筆</Box>
								</Box>
							</Box>
						</Box>

						<Box textAlign="center" py="1em">
							<FormSubmitButton mx=".5em" onClick={() => setEditState((pre) => (pre = { editType: 'viewList' }))}>
								取消
							</FormSubmitButton>
							<FormSubmitButton isLoading={isLoading} mx=".5em" onClick={(e) => {submitTempHandler(e)}}>
								暫存進貨單
							</FormSubmitButton>
							<FormSubmitButton isLoading={isLoading} mx=".5em" type="submit">
								送出進貨單
							</FormSubmitButton>
						</Box>
					</StyledForm>
				</UpdateModalProvider>
			</SelectOptionsProvider>
		</StyledTransitionBg>
	)
}
