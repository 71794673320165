import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoIosArrowDown } from 'react-icons/io'
import styled from 'styled-components'
import { updateModalContext } from '../../useContext/createContext'
import VirtualizedList from '../VirtualizedList'
import { useFormContext } from 'react-hook-form'

const StyledInput = styled(Input)`
	width: 180px !important;
`

export default function SingleItemInput({ typeData, type }) {
	const [visible, setVisible] = useState(false)
	const [dataValue, setDataValue] = useState('')
	const { setValue } = useFormContext()
	const { setUpdateModalInfo } = useContext(updateModalContext)

	useEffect(() => {
		if (dataValue) {
			let arr = typeData.filter((item) => item['name'] === dataValue)
			if (arr[0]) setValue(type, arr[0]['id'])
			if (!arr[0]) {
				setValue(type, null)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue])
	return (
		<Box position="relative" d="inline-block">
			<Box d="flex" flexWrap="wraps">
				<InputGroup>
					<StyledInput
						placeholder="必填欄位"
						value={dataValue}
						onChange={(e) => setDataValue(e.target.value)}
						onFocus={() => setVisible(true)}
						onBlur={() => setVisible(false)}
					/>
					<InputRightElement children={<IoIosArrowDown />} />
				</InputGroup>
				{type !== 'status' ? (
					<Box
						borderRadius="4px"
						fontSize="1.5em"
						d="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="#2B64D0"
						color="white"
						cursor="pointer"
						flexShrink="0"
						w="35px"
						_hover={{ bgColor: '#265abb' }}
						onClick={() => setUpdateModalInfo({ type: type })}
						ml=".2em"
					>
						<AiOutlinePlus />
					</Box>
				) : null}
			</Box>
			<Box maxW="180px">
				{typeData.length > 0 ? (
					<VirtualizedList
						dataValue={dataValue?.toUpperCase()}
						setDataValue={setDataValue}
						isVisible={visible}
						typeData={typeData}
						type={type}
					/>
				) : null}
			</Box>
		</Box>
	)
}
