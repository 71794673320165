import VendorService from 'Api/vendor/vendorService'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetVendorByPhone() {
	const [vendorList, setVendorList] = useState([])
	const [isNeedFetch, setIsNeedFetch] = useState(false)
	const [vendorPhone, setVendorPhone] = useState('')
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const vendorService = new VendorService()
			const result = await vendorService.getSingleVendorOpenAuth(vendorPhone)
			const { data, status } = result
			switch (status) {
				case 200:
					setVendorList([...data.response])
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setVendorList([])
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					setVendorList([])
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setIsNeedFetch(false)
			setVendorPhone('')
		}
		if (isNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetch])

	useEffect(() => {
		if (vendorPhone) setIsNeedFetch(true)
	}, [vendorPhone])

	return [vendorList, setVendorPhone]
}
