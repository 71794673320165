import { VendorPageService } from 'Api/vendorPage/vendorPageService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetNotification(token, isOpen) {
	const [notification, setNotification] = useState([])
	const [isFetching, setIsFetching] = useState(false)
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setIsFetching(true)
			const vendorPageService = new VendorPageService()
			const result = await vendorPageService.getNotificationData(token)
			const { data, status } = result
			switch (status) {
				case 200:
					setNotification((pre) => (pre = [...data.response.data]))
					break
				case 400:
					setNotification([])
					dispatch(warning({ message: data.message, code: data.code, status: status }))
					break
				default:
					setNotification([])
					dispatch(error({ message: data.message, code: data.code, status: status }))
					break
			}
			setIsFetching(false)
		}
		if ((token, isOpen)) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, isOpen])
	return [notification, isFetching]
}
