import { Box } from '@chakra-ui/react'
import React from 'react'
import SideBar from 'components/sidebar/SideBar'
import styled from 'styled-components'
import TopBar from 'components/topbar/TopBar'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'

const StyledBg = styled(Box)`
	min-width: 100vw;
	height: 100%;
	min-height: 100vh;
	display: flex;
	overflow: scroll;

	${'' /* 所有table變成 table-layout: fixed */}
	table {
		table-layout: fixed;
	}

	.topbar {
		& > div {
			white-space: nowrap;
		}
	}

	@media print {
		&::-webkit-scrollbar {
			display: none;
		}
	}
`

const StyledTableBg = styled(Box)`
	${'' /* height: calc(100vh - 56px); */}
	${'' /* min-width: 100vw; */}
	padding: 2em 1em;

	@media print {
		& {
			padding: 0;
		}
	}
`

const StyledRightBg = styled(Box)`
	${'' /* min-width: calc(100vw - 150px); */}
	padding-bottom: 2em;
`

const StyledSidebarBox = styled(Box)`
	width: 150px;
	flex-shrink: 0;
	transition: all 0.3s ease;

	@media print {
		display: none;
	}
`

const StyledTopbarBox = styled(Box)`
	transition: all 0.3s ease;

	@media print {
		display: none;
	}
`

export default function Layout({ children }) {
	const state = useSelector((state) => state.fullState)
	const isShrink = useSelector((state) => state.sidebarShrink)

	return (
		<StyledBg>
			<StyledSidebarBox maxW={state === 'full' ? '0px' : '150px'} marginLeft={isShrink && state !== 'full' ? '-150px' : '0'}>
				<SideBar />
			</StyledSidebarBox>
			<StyledRightBg width={!isShrink ? '100vw' : 'auto'}>
				<StyledTopbarBox marginTop={state === 'full' ? '-60px' : '0px'}>
					<TopBar />
				</StyledTopbarBox>
				<StyledTableBg minW={isShrink ? '100vw' : 'calc(100vw - 150px)'}>
					<Switch>{children}</Switch>
				</StyledTableBg>
			</StyledRightBg>
		</StyledBg>
	)
}

Layout.propTypes = {
	children: PropTypes.arrayOf(PropTypes.element),
}
