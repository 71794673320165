import React from 'react'
import { Box } from '@chakra-ui/react'
import styled from 'styled-components'
import TopbarForm from './component/TopbarForm'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

export default function TableTopBar() {
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				出勤管理
			</Box>
			<Box d="flex">
				<TopbarForm />
			</Box>
		</StyledTopbar>
	)
}
