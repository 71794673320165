import { Box, Select } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import StyledFormLabel from 'utils/StyledFormLabel'
import useGetSelectOptionsData from 'hook/purchaseTable/useGetSelectOptionsData'

export default React.memo(function LocationSelect({ register }) {
	const [selectOptionsData] = useGetSelectOptionsData('create')
	const [locationSelect, setLocationSelect] = useState('')

	return (
		<Box>
			<StyledFormLabel tag="調撥位置" />
			<Select isRequired onChange={(e) => setLocationSelect(e.target.value)} {...register('location_id', { value: locationSelect })} w="180px">
				<option value="" disabled>
					調撥位置
				</option>
				{selectOptionsData.data.locations
					? selectOptionsData.data.locations.map((item) => (
							<option key={item.name} value={item.id}>
								{item.name}
							</option>
					  ))
					: null}
			</Select>
		</Box>
	)
})
