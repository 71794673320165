import React from 'react'
import { Box, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Image } from '@chakra-ui/react'
import { IoImagesOutline } from 'react-icons/io5'

const FAKE_IMAGE_URL = 'https://fakeimg.pl/350x200/?text=待提供照片&font=noto'

export default function PictureModal({ imageUrl, text, children }) {
	const { onOpen, isOpen, onClose } = useDisclosure()

	const isImageExists = !!imageUrl
	return (
		<>
			<Box onClick={onOpen} display="flex" alignItems="center" justifyContent="center" cursor="pointer">
				{text}
				{isImageExists && (
					<Box ml="0.3em" color="#2b64d0" display="inline-block" fontSize="1.2rem" mb="-3px">
						<IoImagesOutline />
					</Box>
				)}
			</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent maxW="100vw" w="400px">
					<ModalBody>{children || <Image src={imageUrl || FAKE_IMAGE_URL} w="100%" maxW="98vw" />}</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
