import Axios from 'Api/interceptors'

export class AttendanceService {
	async getList(currentPage, date, keyword) {
		let result
		try {
			if (date.startDate) {
				let startDate = date.startDate.replace(/-/gi, '/')
				let endDate = date.endDate.replace(/-/gi, '/')
				result = await Axios.get(`/attendances?page=${currentPage}&keyword=${keyword}&start_date=${startDate}&end_date=${endDate}`)
			} else {
				result = await Axios.get(`/attendances?page=${currentPage}&keyword=${keyword}`)
			}
		} catch (error) {
			result = error
		}
		return result
	}

	async punch({ lat, lng, store_id }) {
		let result
		try {
			result = await Axios.post('/attendances', { lat, lng, store_id })
		} catch (error) {
			result = error
		}
		return result
	}
}
