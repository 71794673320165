import React from 'react'

export const merchantContext = React.createContext()
export const MerchantDataProvider = merchantContext.Provider

export const selectOptionsContext = React.createContext()
export const SelectOptionsProvider = selectOptionsContext.Provider

export const updateModalContext = React.createContext()
export const UpdateModalProvider = updateModalContext.Provider
