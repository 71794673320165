import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import TableContent from './TableContent'
import TableTopBar from './TableTopBar'
import EditModal from './components/Modal'

const StyledTable = styled(Box)`
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;

	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;

		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	return (
		<StyledTable>
			<EditModal />
			<TableTopBar />
			<TableContent />
		</StyledTable>
	)
}
