import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import TableContent from './TableContent'
import TableTopBar from './TableTopBar'
import { Provider } from './useContext/createContext'
import SubContent from './SubContent'
import DisplayContent from './DisplayContent'
import { getStockReturnList } from 'Api/return/returnService'
import { useQuery } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { warning } from 'reducers/toast'

const StyledTable = styled(Box)`
	${'' /* height: 100%; */}
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 1em 2em;
	overflow: hidden;
	@media print {
		& {
			box-shadow: none;
			padding: 0;
		}
	}
	table {
		border-radius: 10px;
		border: 1px solid #cfcfcf;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		overflow: hidden;
	}
	thead {
		tr {
			background: #f4f4f4;
			border-color: #cfcfcf;
			border-bottom: 1px solid #cfcfcf;
			color: #2b64d0;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	th,
	td {
		padding: 1em;
		text-align: center;
		border-bottom: 1px solid #cfcfcf;
		min-width: 130px;
		&:last-child {
			border-right: none;
		}
	}
`

export default function Table() {
	const matchList = useRouteMatch('/admin/return/stockReturn')
	const matchCreate = useRouteMatch('/admin/return/stockReturn/:action')
	const matchDetail = useRouteMatch('/admin/return/stockReturn/order/:id')

	const isOnList = matchList?.isExact
	const isOnCreate = matchCreate?.isExact && matchCreate?.params?.action === 'create'
	const isOnDetail = matchDetail?.isExact && matchDetail?.params?.id

	const [params, setParams] = useState({
		page: 1,
		start_date: '',
		end_date: '',
	})
	const dispatch = useDispatch()
	const {
		data,
		isFetching: isFetchingList,
		refetch,
		error,
	} = useQuery(['getStockReturnList', params], { queryFn: getStockReturnList(params) })

	const listData = data?.data?.response?.data || []
	const maxPage = data?.data?.response?.total_page || 1
	const errorStatus = error?.status

	useEffect(() => {
		if (errorStatus === 400) {
			dispatch(warning({ message: error?.data?.message, status: error?.status, code: error?.data?.code }))
		}
	}, [errorStatus, dispatch, error])

	const goToPage = (page) => {
		setParams({
			...params,
			page,
		})
	}

	const setupDate = (startDate, endDate) => {
		setParams({
			...params,
			start_date: startDate,
			end_date: endDate,
		})
	}

	const getPageType = () => {
		if (isOnList) {
			return 'viewList'
		} else if (isOnCreate) {
			return 'create'
		} else if (isOnDetail) {
			return 'print'
		}
	}

	const renderTable = () => {
		if (isOnList) {
			return (
				<TableContent listData={listData} maxPage={maxPage} goToPage={goToPage} isFetching={isFetchingList} currentPage={params.page} />
			)
		} else if (isOnCreate) {
			return <SubContent refetchList={refetchList} />
		} else if (isOnDetail) {
			return <DisplayContent />
		}
	}

	const refetchList = () => {
		refetch()
	}

	return (
		<Provider value={{}}>
			<StyledTable>
				<TableTopBar pageState={getPageType()} setDate={setupDate} />
				{renderTable()}
				{/* {editState.editType === 'viewList' ? <TableContent /> : null}
				{editState.editType === 'create' ? <SubContent /> : null}
				{editState.editType === 'print' ? <DisplayContent /> : null} */}
			</StyledTable>
		</Provider>
	)
}
