import { Box, Checkbox, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { HiDocumentText } from 'react-icons/hi'
import DetailModel from './DetailModel'

import { AiOutlineInfoCircle } from 'react-icons/ai'
import PictureModal from 'components/modals/PictureModal'

const StyledTable = styled(Box)`
	margin-top: 1em;
	th,
	td {
		min-width: auto !important;
	}
`

export default function SelectTable({ productList, setOptionIdList }) {
	const [vendorDetailId, setVendorDetailId] = useState('')

	function checkControl(status) {
		if (status) {
			document.querySelectorAll('.vendorId').forEach((item) => {
				if (!item.control.checked) item.click()
			})
		}
		if (!status) {
			document.querySelectorAll('.vendorId').forEach((item) => {
				if (item.control.checked) {
					item.click()
				}
			})
		}
	}

	return (
		<>
			<StyledTable>
				<table>
					<thead>
						<tr>
							<td>
								<Box d="flex" alignItems="center" justifyContent="center">
									{productList.length > 0 ? (
										<Checkbox
											bg="white"
											mr=".5em"
											onChange={(e) => {
												checkControl(e.target.checked)
											}}
										/>
									) : null}
									選取
								</Box>
							</td>
							<td>電話</td>
							<td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								廠商姓名
								<Tooltip label="點擊廠商姓名可看廠商身分證">
									<span>
										<AiOutlineInfoCircle />
									</span>
								</Tooltip>
							</td>
							<td>寄賣總額</td>
							<td>付款方式</td>
							<td>銀行代碼</td>
							<td>銀行帳號</td>
							<td>管理</td>
						</tr>
					</thead>
					<tbody>
						{productList.length > 0
							? productList.map((item, i) => (
									<tr key={i}>
										<td>
											<Box d="flex" alignItems="center" justifyContent="center">
												<Checkbox
													onChange={(e) => {
														if (e.target.checked) setOptionIdList((pre) => (pre = [...pre, e.target.value]))
														if (!e.target.checked) setOptionIdList((pre) => pre.filter((i) => i !== e.target.value))
													}}
													value={item.vendor_id}
													className={`vendorId`}
												/>
											</Box>
										</td>
										<td>{item.vendor_phone}</td>
										<td>
											<PictureModal text={item.vendor_name} imageUrl={item.vendor_image} />
										</td>
										<td>{item.price}</td>
										<td>{item.payment}</td>
										<td>{item.account?.split('-')[0]}</td>
										<td>{item.account?.split('-')[1]}</td>
										<td>
											<Box
												color="#91B0EB"
												cursor="pointer"
												d="flex"
												justifyContent="center"
												onClick={() => setVendorDetailId(item.vendor_id)}
											>
												<HiDocumentText />
											</Box>
										</td>
									</tr>
							  ))
							: null}
					</tbody>
				</table>
			</StyledTable>
			<DetailModel vendorDetailId={vendorDetailId} setVendorDetailId={setVendorDetailId} />
		</>
	)
}
