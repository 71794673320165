import { TypeService } from 'Api/type/typeService'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetSubtypeList(mainTypeId) {
	const [subtypeList, setSubtypeList] = useState({ data: [], isFetching: false })
	const [subtypeNeedFetch, setSubtypeNeedFetch] = useState(true)
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setSubtypeList((pre) => (pre = { ...pre, isFetching: true }))
			const typeService = new TypeService()
			const result = await typeService.getOneType(mainTypeId)
			const { data, status } = result
			switch (status) {
				case 200:
					setSubtypeList((pre) => (pre = { data: [...data.response.subtypes], isFetching: false }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setSubtypeList((pre) => (pre = { data: [], isFetching: false }))
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					setSubtypeList((pre) => (pre = { data: [], isFetching: false }))
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setSubtypeNeedFetch(false)
		}
		if (mainTypeId && subtypeNeedFetch) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainTypeId, subtypeNeedFetch])

	return [subtypeList, setSubtypeNeedFetch]
}
