import TransferService from 'Api/transferOrder/transferService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetTransferOrderDetail(orderId) {
	const dispatch = useDispatch()
	const [orderData, setOrderData] = useState({ data: {}, isFetching: false })

	useEffect(() => {
		async function fetchData() {
			setOrderData((pre) => (pre = { ...pre, isFetching: true }))
			const transferService = new TransferService()
			const result = await transferService.getTransferOrderById(orderId)
			const { status, data } = result

			switch (status) {
				case 200:
					setOrderData((pre) => (pre = { data: { ...data.response }, isFetching: false }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setOrderData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					setOrderData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
		}
		if (orderId) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId])

	return [orderData]
}
