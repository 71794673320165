import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { StyledButtonBox, StyledDeleteButton } from 'utils/StyledButton'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { deleteData } from 'reducers/deleteData'
import usersTableService from 'Api/users/users'
import { error, success, warning } from 'reducers/toast'

export default function DeleteDataAlert() {
	const { onClose, isOpen, onOpen } = useDisclosure()
	const { id, callback, payload } = useSelector((state) => state.deleteData)
	const dispatch = useDispatch()

	useEffect(() => {
		if (id) {
			onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	async function onSubmit() {
		let service
		let result
		switch (payload.deleteType) {
			case 'users':
				service = new usersTableService()
				result = await service.deleteUser(id)
				break
			default:
				break
		}

		const { data, status } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				break
			case 400:
				dispatch(warning({ message: data.message, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}

		// 用來調用重新fetchdata的function
		callback(true)
		firingWhenClose()
	}

	function firingWhenClose() {
		dispatch(deleteData({ id: '', payload: '', callback: '' }))
		onClose()
	}

	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={() => {
				firingWhenClose()
			}}
		>
			<AlertDialogOverlay>
				<AlertDialogContent py="1em" borderRadius="15px">
					<AlertDialogHeader fontSize="1.5em">確定要刪除該筆資料?</AlertDialogHeader>
					<AlertDialogBody d="flex" justifyContent="flex-start" fontSize="1.3em" px="2em" mb="1em">
						資料名稱 : {payload.name}
					</AlertDialogBody>

					<AlertDialogFooter>
						<StyledButtonBox mr="1em" onClick={firingWhenClose}>
							取消
						</StyledButtonBox>
						<StyledDeleteButton onClick={onSubmit}>刪除</StyledDeleteButton>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
