import React, { useContext, useEffect } from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { FormSubmitButton } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import { paymentListContext } from '../useContext/createContext'
import { useState } from 'react'
// import SearchVendorInput from './SearchVendorInput'

const StyledForm = styled.form`
	display: flex;
	align-items: center;
	margin-right: 0.5em;
`

const StyledBox = styled(Box)`
	display: flex;
	align-items: center;
	margin-right: 0.5em;
`

export default function SearchVendorListForm() {
	const { setEditState, setOptionData } = useContext(paymentListContext)
	const [isShowAdvancedSearch, setIsShowAdvancedSearch] = useState(false)
	const { handleSubmit, register, setValue } = useForm()
	function onSubmit(formData) {
		setEditState((pre) => (pre = { ...pre, date: formData.date, vendorPhone: formData.vendorPhone }))
		setOptionData((pre) => (pre = { ...pre, isFetching: true }))
	}

	useEffect(() => {
		if (!isShowAdvancedSearch) {
			setValue('date.startDate', '')
			setValue('date.endDate', '')
			setEditState((pre) => (pre = { ...pre, date: {} }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShowAdvancedSearch])

	// 原本的startDate & endDate 有initial value是當月第一天跟最後一天
	// const date = new Date()
	// const toISOStringOffset = 1
	// const firstDay = new Date(date.getFullYear(), date.getMonth(), 1 + toISOStringOffset).toISOString().substr(0, 10)
	// const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0 + toISOStringOffset).toISOString().substr(0, 10)

	return (
		<>
			<Box>
				<Box d="flex" my="0.5rem">
					{/* <SearchVendorInput /> */}
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<StyledBox>
							{/* 原本的startDate & endDate 有initial value是當月第一天跟最後一天 */}
							{/* <Input maxW="160px" type="date" {...register('date.startDate', { value: firstDay })} isRequired />
					<Box mx=".5em">到</Box>
					<Input mr="1em" maxW="160px" type="date" {...register('date.endDate', { value: lastDay })} isRequired /> */}
							<Box mr="1rem" cursor="pointer" onClick={() => setIsShowAdvancedSearch(!isShowAdvancedSearch)}>
								進階搜尋條件
							</Box>

							<Input mr="1em" maxW="160px" type="text" placeholder="請輸入廠商電話" autoComplete="off" {...register('vendorPhone')} />
						</StyledBox>
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">搜尋</Text>
						</FormSubmitButton>
					</StyledForm>
				</Box>

				<Box w="100%" d={isShowAdvancedSearch ? 'flex' : 'none'} justifyContent="flex-end" alignItems="center" my=".5em">
					<Input maxW="160px" type="date" {...register('date.startDate')} />
					<Box mx=".5em">到</Box>
					<Input mr=".5em" maxW="160px" type="date" {...register('date.endDate')} />
				</Box>
			</Box>
		</>
	)
}
