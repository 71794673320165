import { Box, Spinner } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import { staffContext } from './useContext/createContext'
import { FaPen, FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { deleteData } from 'reducers/deleteData'
import GeneralPagination from 'components/pagination'

export default function TableContent() {
	const { setIsNeedFetch, setEditState, tableData, gotoPage, currentPage, maxPage } = useContext(staffContext)
	const dispatch = useDispatch()

	const data = React.useMemo(() => {
		return tableData.data || [{ user_no: '', name: '', notes: '' }]
	}, [tableData.data])

	const columns = React.useMemo(
		() => [
			{
				Header: '員工編號',
				accessor: 'user_no',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '姓名',
				accessor: 'name',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '電話',
				accessor: 'phone',
				disableGlobalFilter: true,
			},
			{
				Header: '備註',
				accessor: 'notes',
				disableGlobalFilter: true,
			},
			{
				Header: '管理',
				accessor: 'management',
				disableGlobalFilter: true,
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box
								color="#91B0EB"
								cursor="pointer"
								onClick={() => setEditState((pre) => (pre = { editType: 'edit', userId: row.row.original.id }))}
							>
								<FaPen />
							</Box>
							<Box
								color="#EB9191"
								cursor="pointer"
								onClick={() => {
									dispatch(
										deleteData({
											id: row.row.original.id,
											payload: { name: row.row.original.name, deleteType: 'users' },
											callback: setIsNeedFetch,
										})
									)
								}}
							>
								<FaTrashAlt />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	)
	return (
		<>
			{tableData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{tableData.data.length > 0 ? (
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map((headerGroup, g) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={g}>
										{headerGroup.headers.map((column, k) => {
											return (
												<th key={k}>
													<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
														{
															// Render the header
															column.render('Header')
														}
													</Box>
												</th>
											)
										})}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									page.map((row, i) => {
										// Prepare the row for display
										prepareRow(row)
										return (
											// Apply the row props
											<tr {...row.getRowProps()} key={i}>
												{
													// Loop over the rows cells
													row.cells.map((cell, j) => {
														// Apply the cell props
														return (
															<td {...cell.getCellProps()} key={j}>
																{
																	// Render the cell contents
																	cell.render('Cell')
																}
															</td>
														)
													})
												}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}

					<GeneralPagination defaultCurrent={currentPage} total={maxPage * 10} onChange={(page) => gotoPage(page)} />
				</>
			)}
		</>
	)
}
