import React, { useContext, useState } from 'react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Box,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormLabel,
	Input,
	useDisclosure,
	RadioGroup,
	Radio,
} from '@chakra-ui/react'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { updateModalContext } from '../../useContext/createContext'
import OtherTableService from 'Api/otherTable/other'
import { updateModalWordingSwitcher, updateTypeToApiRoute } from 'utils/wordingOrIconsSwitcher'
import styled from 'styled-components'
import StyledFormLabel from 'utils/StyledFormLabel'
import { useQueryClient } from '@tanstack/react-query'

const StyledBox = styled(Box)`
	width: 80%;
	margin: 0.5em 0;
`

export default function UpdateModal() {
	const { updateModalInfo, setUpdateModalInfo } = useContext(updateModalContext)
	const { register, handleSubmit, reset, setValue } = useForm()
	const [radioValue, setRadioValue] = useState('倉庫')
	const queryClient = useQueryClient()

	const dispatch = useDispatch()
	const { isOpen, onClose, onToggle } = useDisclosure()
	useEffect(() => {
		if (updateModalInfo.type) {
			reset()
			onToggle()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModalInfo])

	async function onSubmit(formData) {
		// 新增LOCATIONS的時候才會有is_on_sale_order的欄位
		// is_on_sale_order:0 不會顯示在銷貨單選項 => 為倉庫，因為倉庫眾多，不希望客戶在銷售的時候選太多多餘的選項
		// is_on_sale_order:1 會顯示在銷貨單選項 => 為通路
		if (formData.is_on_sale_order === '倉庫') formData.is_on_sale_order = 0
		if (formData.is_on_sale_order === '通路') formData.is_on_sale_order = 1

		const otherTableService = new OtherTableService()
		let result
		result = await otherTableService.createData(updateTypeToApiRoute(updateModalInfo.type), formData, updateModalInfo.type_id)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, code: data.code, status: status }))
				queryClient.invalidateQueries({ queryKey: ['getSelectOptionsData'] })
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
		onToggle()
		reset()
		setUpdateModalInfo({ type: '' })
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent py="1em" borderRadius="15px">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalHeader fontSize="2.5em" textAlign="center">
						新增{updateModalWordingSwitcher(updateModalInfo.type)}
					</ModalHeader>
					<ModalCloseButton />
					{updateModalInfo.type !== 'locations' ? (
						<ModalBody d="flex" justifyContent="center" px="2em" mb="1em">
							<Box mr="1em">
								<StyledFormLabel isRequired tag={`${updateModalWordingSwitcher(updateModalInfo.type)}名稱`} />
								<Input {...register('name')} required />
							</Box>
							<Box>
								<FormLabel>說明</FormLabel>
								<Input {...register('notes')} />
							</Box>
						</ModalBody>
					) : null}

					{updateModalInfo.type === 'locations' ? (
						<ModalBody d="flex" justifyContent="center" flexWrap="wrap" px="2em" mb="1em">
							<StyledBox>
								<FormLabel>位置名稱</FormLabel>
								<Input {...register('name')} required />
							</StyledBox>

							<StyledBox>
								<FormLabel>地址</FormLabel>
								<Input {...register('address')} required />
							</StyledBox>
							<StyledBox>
								<FormLabel>電話</FormLabel>
								<Input {...register('phone')} required />
							</StyledBox>

							<StyledBox>
								<FormLabel>說明</FormLabel>
								<Input {...register('notes')} />
							</StyledBox>
							<StyledBox>
								<FormLabel>種類</FormLabel>
								<Input {...register('is_on_sale_order', { value: radioValue })} d="none" />
								<RadioGroup
									onChange={(value) => {
										setValue('is_on_sale_order', value)
										setRadioValue(value)
									}}
									value={radioValue}
									required
								>
									<Radio value="倉庫" mr="1em">
										倉庫
									</Radio>
									<Radio value="通路">通路</Radio>
								</RadioGroup>
							</StyledBox>
						</ModalBody>
					) : null}
					<ModalFooter justifyContent="center">
						<StyledButtonBox mr="1em" onClick={onClose}>
							取消
						</StyledButtonBox>
						<FormSubmitButton bg="white" type="submit">
							確定
						</FormSubmitButton>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
