import React, { useContext, useEffect } from 'react'
import { Box, List, ListItem } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './component/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import { tempPurchaseContext } from './useContext/createContext'
import useGetTempDetailPurchaseOrder from 'hook/tempPurchaseTable/useGetTempDetailPurchaseOrder'
import QRCode from 'react-qr-code'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}

	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.signSheet {
				display: ${(props) => (props.printType === 'precautions' ? 'none' : 'block')};
			}

			.precautions {
				display: ${(props) => (props.printType === 'table' ? 'none' : 'block')};
				padding-bottom: 3em !important;
				page-break-before: always;

				ul {
					list-style: none;
				}
			}
		}

		@page {
			size: A4 portrait;
		}
	}
`

export default function DisplayContent() {
	const { setEditState, editState, printType, setPrintType } = useContext(tempPurchaseContext)

	const [detailList] = useGetTempDetailPurchaseOrder(editState.orderId, true)

	useEffect(() => {
		if (printType) {
			window.print()
			setPrintType('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [printType])

	return (
		<StyledTransitionBg printType={printType}>
			<Box className="signSheet">
				<Box textAlign="center">
					<Box className="title" fontSize="2em" mb=".7em">
						OPMM暫存進貨單
					</Box>
				</Box>

				<Box className="info" mb="1em">
					<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
						<Box>
							<Box>暫存進貨單號：{detailList.data.purchase_order_no}</Box>
							<Box>進貨日期：{detailList.data.date}</Box>
						</Box>
						<Box>
							<Box>廠商姓名：{detailList.data.vendor}</Box>
							<Box>廠商電話：{detailList.data.vendor_phone}</Box>
						</Box>
						<Box>
							<Box>經辦姓名：{detailList.data.user_no}</Box>
							<Box>經辦電話：{detailList.data.user_phone}</Box>
						</Box>
					</Box>
				</Box>
				<DisplayTable productList={detailList.data.products || []} />
				<Box textAlign="center">
					<Box textAlign="right" my="1em" mr="1em">
						總計：$ {detailList.data.total_price}
					</Box>
					<Box d="inline-block" className="success-button">
						<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'viewList' }))}>完成</StyledButtonBox>
					</Box>
				</Box>
			</Box>
			<Box className="precautions" px="3em" display="none">
				<Box fontSize="1.5em">
					<Box textAlign="center" fontSize="2rem" my="1.5rem">
						注意事項：
					</Box>
					<List>
						<ListItem>一、寄賣售價均由店舖人員與寄賣者雙方協議取回價格。當取回價格不易售出時，將與寄賣者 再次協議取回價格。</ListItem>
						<ListItem>二、由寄賣日起計算，寄賣時間未達三週，若寄賣方欲取回商品，酌收處理費用200元/次；超過三週取回則不收取寄賣處理費200元/次。</ListItem>
						<ListItem>三、寄賣商品如需取回，請提早一週告知OPMM，寄賣者需自行負擔寄送運費或店取。</ListItem>
						<ListItem>四、服飾如果銷售不佳或有瑕疵等無法銷售等情事，OPMM以電話或IG訊息通知寄賣者領回，寄賣者需在接到通知後30日之內領回，逾期未領取，視同授權OPMM將服飾以舊衣回收處理。</ListItem>
						<ListItem>五、若寄賣商品經查證為仿品、贓物等相關情事，OPMM將其相關資料交付警務單位處置並依法送辦，同時將向寄賣者求償商譽賠償損失。</ListItem>
						<ListItem>六、寄賣期間，OPMM將負保管責任，倘若商品遺失將以雙方協議寄賣價格直接買斷。</ListItem>
						<ListItem>七、會員基本資料僅供OPMM店內使用與執行相關業務，不會將資料提供他人。</ListItem>
						<ListItem>八、如有個人資料異動及其他疑問，請洽OPMM店員。</ListItem>
					</List>
					<Box textAlign="center" fontSize="2rem" my="1.5rem">
						取款規定：
					</Box>
					<List>
						<ListItem>一、取款需提早跟OPMM店員預約取款時間，取款時需提供個人證件方便核對個人資料，確認款項無誤後簽名收款。</ListItem>
						<ListItem>二、如需匯款寄賣方需提供存摺影本以及負擔匯款手續費30元。</ListItem>
					</List>
					<Box display="flex" justifyContent="center" mt="2rem" mb="4rem">
						<Box>如查詢寄賣商品的銷售狀況，請掃描右側QR Code。 （寄賣商品資訊更新為當晚12點，如需知道當天寄賣商品銷售狀況，建議隔天登入QR Code網站查詢）</Box>
						<Box mx="2rem">
							<QRCode value={`${window.location.origin}/#/vendor?code=${detailList.data.vendor_token}`} size={100} />
						</Box>
					</Box>
					<Box>寄賣人簽名：＿＿＿＿＿＿＿＿＿＿ （___/___/___） </Box>
				</Box>
			</Box>
		</StyledTransitionBg>
	)
}
