import { createSlice } from '@reduxjs/toolkit'

//統一管理Message component的toast
//只要狀態有更新就會讓Message component發系統通知的toast

export const toastSlice = createSlice({
	name: 'toast',
	initialState: {
		code: '',
		message: '',
		status: '',
		//date只是用來代替uuid之類的功能，多一個uniq的值，區別出每一次狀態更新
		date: Date.now(),
	},
	reducers: {
		success: (state, action) => {
			return { ...state, message: action.payload.message, status: 'success', code: action.payload.code, date: Date.now() }
		},
		warning: (state, action) => {
			return { ...state, message: action.payload.message, status: 'warning', code: action.payload.code, date: Date.now() }
		},
		error: (state, action) => {
			return { ...state, message: action.payload.message, status: 'error', date: Date.now() }
		},
	},
})

export const { success, warning, error } = toastSlice.actions
export default toastSlice.reducer
