import { Box, StatGroup, Stat, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import MaintypeTable from './components/MaintypeTable'

const StyledBox = styled(Box)`
	min-height: 215px;
	width: 65vw;
	border: 1px solid #cfcfcf;
	border-radius: 10px;
	padding: 5px;
`

const StyledStatGroup = styled(StatGroup)`
	margin: 10px;
`

const StyledTotalStatGroup = styled(StatGroup)`
	margin: 10px 0;
	flex-direction: column;
`
const StyledStat = styled(Stat)`
	padding: 5px;
`

const StyledKPIStat = styled(Stat)`
	border: 1px dashed #cccccc;
	padding: 5px;
	border-radius: 5px;
`
const StyledKPIHelpText = styled(StatHelpText)`
	font-size: 8px;
`
const StyledTableTitle = styled(Box)`
	padding: 10px;
	font-size: 1.25em;
	font-weight: 700;
`

export default function ChartContent({ data }) {
	const {
		data: {
			response: {
				daily: {
					employee: employeeDailyData,
					kpi1,
					kpi2,
					payment_order_count: paymentOrderCount,
					sale_count: saleCount,
					sale_product_count: saleProductCount,
					total_cash_sale_amount: totalCashSaleAmount,
					total_money_transfer_sale_amount: totalMoneyTransferSaleAmount,
					total_payment_order_amount: totalPaymentOrderAmount,
					total_sale_amount: totalSaleAmount,
				},
				monthly: { employee: employeeMonthlyData, total_sale_amount: totalMonthlySaleAmount },
			},
		},
	} = data

	return (
		<Box position="relative" minHeight="50vh">
			<Box display="flex">
				<StyledTotalStatGroup flexGrow="1">
					<StyledStat m="6px 0" minWidth="85px">
						<StatLabel>
							當日
							<br />
							銷售總額
						</StatLabel>
						<StatNumber>{totalSaleAmount}</StatNumber>
					</StyledStat>
					<StyledStat m="6px 0" minWidth="85px">
						<StatLabel>
							當月至今
							<br />
							銷售總額
						</StatLabel>
						<StatNumber>{totalMonthlySaleAmount}</StatNumber>
					</StyledStat>
				</StyledTotalStatGroup>
				<StyledBox>
					<StyledStatGroup>
						<StyledStat>
							<StatLabel>銷售總額-現金</StatLabel>
							<StatNumber>{totalCashSaleAmount}</StatNumber>
						</StyledStat>
						<StyledStat>
							<StatLabel>銷售總額-匯款</StatLabel>
							<StatNumber>{totalMoneyTransferSaleAmount}</StatNumber>
						</StyledStat>
						<StyledStat mr="20px">
							<StatLabel>銷售單數量</StatLabel>
							<StatNumber>{saleCount}</StatNumber>
						</StyledStat>
						<StyledKPIStat>
							<StatLabel>當日-KPI1</StatLabel>
							<StatNumber>{kpi1}</StatNumber>
							<StyledKPIHelpText>銷售單數量/銷售總額</StyledKPIHelpText>
						</StyledKPIStat>
					</StyledStatGroup>
					<StyledStatGroup>
						<StyledStat>
							<StatLabel>銷售品項數量</StatLabel>
							<StatNumber>{saleProductCount}</StatNumber>
						</StyledStat>
						<StyledStat>
							<StatLabel>付款總額</StatLabel>
							<StatNumber>{totalPaymentOrderAmount}</StatNumber>
						</StyledStat>
						<StyledStat mr="20px">
							<StatLabel>付款單數量</StatLabel>
							<StatNumber>{paymentOrderCount}</StatNumber>
						</StyledStat>
						<StyledKPIStat>
							<StatLabel>當日-KPI2</StatLabel>
							<StatNumber>{kpi2}</StatNumber>
							<StyledKPIHelpText>銷售單數量/銷售品項數量</StyledKPIHelpText>
						</StyledKPIStat>
					</StyledStatGroup>
				</StyledBox>
			</Box>
			<Box display="flex" mt="30px">
				<Box>
					<StyledTableTitle>當日員工銷售量列表</StyledTableTitle>
					<MaintypeTable data={employeeDailyData} />
				</Box>
				<Box>
					<StyledTableTitle>當月至今員工銷售量列表</StyledTableTitle>
					<MaintypeTable data={employeeMonthlyData} />
				</Box>
			</Box>
		</Box>
	)
}
