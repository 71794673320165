import { Button, Input } from '@chakra-ui/react'
import React from 'react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { staffContext } from './useContext/createContext'

export default function TopbarForm() {
	const { register, handleSubmit } = useForm()
	const { setKeyword } = useContext(staffContext)

	function onSubmit(data) {
		setKeyword(data.keyword)
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Input {...register('keyword')} mr="1em" maxW="160px" placeholder="請輸入員編或姓名" />
			<Button d="none">submit</Button>
		</form>
	)
}
