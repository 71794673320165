import { useEffect, useState } from 'react'
import OtherTableService from 'Api/otherTable/other'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export function useGetOtherTable() {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const [isNeedFetchList, setIsNeedFetchList] = useState(false)
	const { currentPage, maxPage } = tablePage

	const dispatch = useDispatch()
	// eslint-disable-next-line no-unused-vars
	const [_, otherTableType] = window.location.pathname.split('/admin/other/')

	useEffect(() => {
		const fetchData = async () => {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const service = new OtherTableService()
			const getServiceData = await service.getData(otherTableType, currentPage)
			const { status, data } = getServiceData

			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: data.response.data, isFetching: false })
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setIsNeedFetchList(false)
		}
		if (isNeedFetchList) {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeedFetchList])

	useEffect(() => {
		if (otherTableType) {
			setIsNeedFetchList(true)
		}
	}, [otherTableType, currentPage])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}
	return [tableData, currentPage, maxPage, gotoPage, setIsNeedFetchList, otherTableType, setTablePage]
}
