import Axios from './interceptors'

export default class ImageService {
	async uploadImage(payload) {
		let result
		try {
			result = await Axios.post('/uploads/image', payload)
		} catch (error) {
			result = error
		}
		return result
	}
	async uploadImageWithVendorToken(payload, token) {
		let result
		try {
			result = await Axios.post(`/purchaseOrders/vendors/${token}/uploads`, payload)
		} catch (error) {
			result = error
		}
		return result
	}
	// 付款單 ＆ 進貨單 簽名上傳用 api
	async uploadSignedNameSheetImage(target, formData, sheetId) {
		let result
		try {
			result = await Axios.post(`/uploads/image/${target}/${sheetId}`, formData)
		} catch (error) {
			result = error
		}
		return result
	}
}
