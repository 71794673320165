import { Box, Flex, Text } from '@chakra-ui/layout'
import styled from 'styled-components'
import { IoIosArrowForward, IoIosArrowDown, IoIosCheckboxOutline } from 'react-icons/io'
import { BsPerson } from 'react-icons/bs'
import { FiBox, FiTag } from 'react-icons/fi'
import { IoHomeOutline, IoDocumentTextOutline, IoShirtOutline } from 'react-icons/io5'
import { AiOutlineAppstore } from 'react-icons/ai'
import { HiReceiptRefund } from 'react-icons/hi'
import { GoChecklist } from 'react-icons/go'

const StyledItem = styled(Box)`
	margin: 0 auto 0.5em;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	overflow-y: hidden;
	transition: all 0.3s ease;
`

export default function SideBarItem({ title, children, isToggle, onClickItem, onClickSubitem, activeSubitem }) {
	const iconSwitcher = (title) => {
		switch (title) {
			case '人事管理':
				return <BsPerson />
			case '進貨管理':
				return <FiBox />
			case '庫存管理':
				return <IoShirtOutline />
			case '廠商管理':
				return <IoHomeOutline />
			case '銷貨管理':
				return <IoIosCheckboxOutline />
			case '付款管理':
				return <FiTag />
			case '報表管理':
				return <IoDocumentTextOutline />
			case '退貨管理':
				return <HiReceiptRefund />
			case '盤點管理':
				return <GoChecklist />
			case '其他管理':
				return <AiOutlineAppstore />
			default:
				return
		}
	}

	return (
		<StyledItem>
			<Flex
				alignItems="center"
				cursor="pointer"
				my="1em"
				onClick={() => onClickItem(title)}
				opacity={isToggle ? '1' : '.5'}
				_hover={{
					opacity: '1',
				}}
				pl=".5em"
			>
				<Box min-width="40px" flexShrink={0} fontSize="1.5em">
					{iconSwitcher(title)}
				</Box>

				<Text mr="1em" ml="1em" whiteSpace="nowrap">
					{title}
				</Text>
				{!isToggle ? <IoIosArrowForward /> : <IoIosArrowDown />}
			</Flex>
			{children ? (
				<Box borderRadius="12px" bg="white" maxH={isToggle ? '100%' : '0'} transition="all .1s linear" w="95%">
					{children.map((item, i) => (
						<Box
							// ml={item.title === '權限角色設定' ? '1.5em' : '3em'}
							// mr={item.title === '權限角色設定' ? '1em' : '2.5em'}
							textAlign="center"
							my="1em"
							cursor="pointer"
							color={item.route === activeSubitem ? '#2B64D0' : '#A7A7A7'}
							_hover={{ color: '#2B64D0' }}
							key={i}
							onClick={() => onClickSubitem(title, item.title)}
						>
							{item.title}
						</Box>
					))}
				</Box>
			) : null}
		</StyledItem>
	)
}
