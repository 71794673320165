import React, { useContext } from 'react'
import { Box, Input, Spinner, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './components/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import { saleOrderContext } from './useContext/createContext'
import useGetOrderDetail from 'hook/saleTable/useGetOrderDetail'
import { FaRegEdit } from 'react-icons/fa'
import StyledFormLabel from 'utils/StyledFormLabel'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}
	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.precautions {
				display: block;
				padding-bottom: 3em !important;

				ul {
					list-style: none;
				}
			}
		}
		@page {
			size: A4 portrait;
		}
	}
`
const StyledInput = styled(Input)`
	border: none !important;
	padding: 0 !important;
	height: auto !important;
	max-width: 110px !important;
	font-size: 1em !important;
	margin-left: 0.5em;
	line-height: 1em;
	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
`
const AnimatedBox = styled(Box)`
	color: #eb9191;
	cursor: pointer;
	font-size: 1.3rem;

	@media print {
		& {
			display: none;
		}
	}
`

export default function DisplayContent() {
	const { setEditState, editState } = useContext(saleOrderContext)
	const [orderData] = useGetOrderDetail(editState.orderId)
	return (
		<StyledTransitionBg>
			<Box textAlign="center">
				<Box className="title" fontSize="2em" mb=".7em">
					OPMM銷貨單
				</Box>
			</Box>
			{orderData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{orderData.data.id ? (
						<>
							<Box className="info" mb="1em">
								<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
									<Box>
										<Box>銷貨單號：{orderData.data.sale_order_no}</Box>
										<Box>銷貨日期：{orderData.data.sale_time}</Box>
									</Box>
									<Box>
										<Box>員工姓名：{orderData.data.user_name}</Box>
										<Box>員工電話：{orderData.data.user_phone}</Box>
									</Box>
									<Box>
										<Box>員工編號：{orderData.data.user_no}</Box>
										<Box>銷貨通路：{orderData.data.location}</Box>
									</Box>
								</Box>
							</Box>
							<DisplayTable products={orderData.data.product} />
							<Box d="flex" justifyContent="space-between" my="2em">
								<Box d="flex">
									<Box mr="1em">
										<Box>付款方式</Box>
										<Box borderBottom="1px" minW="160px" mt=".5em" pl=".5em" pb=".3em">
											{orderData.data.payment === 0 ? '現金' : null}
											{orderData.data.payment === 1 ? '匯款' : null}
										</Box>
									</Box>
									{orderData.data.payment === 1 ? (
										<Box>
											<Box>帳號後五碼</Box>
											<Box borderBottom="1px" minW="100px" mt=".5em" pl=".5em" pb=".3em">
												{orderData.data.payment_detail}
											</Box>
										</Box>
									) : null}
									<Box ml="3em">
										<Box>銷貨通路</Box>
										<Box borderBottom="1px" minW="160px" mt=".5em" pl=".5em" pb=".3em">
											{orderData.data.location}
										</Box>
									</Box>
								</Box>
								<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em">
									小計
									<Box d="flex" alignItems="flex-end" ml="1.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
										$ <StyledInput defaultValue={orderData.data.sale_order_price} isDisabled _disabled={{ color: 'gray.500' }} />
									</Box>
									{orderData.data.reason ? (
										<Popover>
											<PopoverTrigger>
												<AnimatedBox>
													<FaRegEdit />
												</AnimatedBox>
											</PopoverTrigger>
											<PopoverContent>
												<PopoverArrow />
												<PopoverCloseButton />
												<PopoverBody>
													<StyledFormLabel isRequired tag="折扣原因" />
													<Box>{orderData.data.reason}</Box>
												</PopoverBody>
											</PopoverContent>
										</Popover>
									) : null}
								</Box>
							</Box>
						</>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}
				</>
			)}

			<Box className="success-button" d="flex" justifyContent="center" my="1em">
				<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'viewList' }))}>完成</StyledButtonBox>
			</Box>

			{/* <Box className="precautions" borderTop="1px solid" borderColor="gray.600" px="3em" display="none">
				<Box fontSize="2em" textAlign="center" mt="1em" mb="2em">
					注意事項
				</Box>
				<Box fontSize="1.5em">
					<ul>
						<li>一﹑ 請掃右側QR code, 完成個人資料填寫。</li>
						<li>二﹑ 個人資料填寫（修改）僅限一次，若有任何其他需求， 請來電：0912345678。</li>
						<li>三﹑ 寄賣貨品的狀況, 可隨時透過右側QR code追蹤。</li>
						<li>四﹑ 若有任何問題, 請洽：0912345678, 或門市：台北市西門區西門路1號。</li>
					</ul>
				</Box>
			</Box> */}
		</StyledTransitionBg>
	)
}
