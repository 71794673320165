import React, { useContext } from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { vendorContext } from './useContext/createContext'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { FormSubmitButton } from 'utils/StyledButton'
import { RiSearchLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

const StyledForm = styled.form`
	margin-right: 2em;
`

export default function TableTopBar() {
	const { setVendorPhone, setEditState, editState } = useContext(vendorContext)
	const { register, handleSubmit } = useForm()

	function onSubmit(data) {
		setVendorPhone(data.phone)
	}
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				廠商管理
				{editState.editType !== 'viewList' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{editState.editType === 'edit' ? '編輯廠商' : null}
						{editState.editType === 'create' ? '新增廠商' : null}
					</Box>
				) : null}
			</Box>
			<Box d="flex">
				{editState.editType === 'viewList' ? (
					<>
						<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<Input mr="1em" maxW="130px" placeholder="請輸入電話" {...register('phone')} />
							<FormSubmitButton type="submit">
								<RiSearchLine />
								<Text ml="5px">搜尋</Text>
							</FormSubmitButton>
						</StyledForm>

						<StyledButtonBox onClick={() => setEditState((pre) => (pre = { editType: 'create', data: {} }))}>
							<HiPlusSm />
							<Text ml="5px">新增廠商</Text>
						</StyledButtonBox>
					</>
				) : null}
			</Box>
		</StyledTopbar>
	)
}
