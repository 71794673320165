import {
	Box,
	Skeleton,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	FormControl,
} from '@chakra-ui/react'
import useGetNotification from 'hook/vendorPage/useGetNotification'
import React from 'react'
import { AiOutlineNotification } from 'react-icons/ai'
import { BiTimeFive } from 'react-icons/bi'
import { RiFilePaper2Line } from 'react-icons/ri'
import styled from 'styled-components'
import { priceCommaInserter } from 'utils/priceInsertComma'
import { useLocation } from 'react-router-dom'
import PaymentOrder from 'components/vendorPage/PaymentOrder'

const StyleBox = styled(Box)`
	border-bottom: 2px solid #d6f3d6;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	padding: 0 0.5rem 1.2rem;
`

export default function Notification() {
	const { search } = useLocation()
	const code = new URLSearchParams(search).get('code')
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [notification, isFetching] = useGetNotification(code, isOpen)

	return (
		<>
			<Box
				onClick={onOpen}
				cursor="pointer"
				p="0.5em"
				background="#F7A599"
				transition="all .2s ease"
				_hover={{ bg: 'rgb(227, 145, 133)' }}
				color="white"
				borderRadius="10px"
				mr="1em"
				flex="1"
			>
				<Box fontSize="2em">
					<AiOutlineNotification />
				</Box>
				<Box>商品通知</Box>
				<Box fontSize="10px" transform="scale(0.8)" ml="-1em" mb="0em" mr="-1em">
					關於商品的各項通知都在這裡！
				</Box>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
				<ModalOverlay />
				<ModalContent maxW="375px">
					<ModalHeader color="#51AB9B" textAlign="center" fontSize="1.5rem" display="flex" alignItems="center" justifyContent="center">
						通知紀錄
						<Box ml="0.5rem">
							<RiFilePaper2Line />
						</Box>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb="3em" maxH="600px">
						<Stack spacing={5}>
							{notification.length > 0 && !isFetching ? (
								notification.map((item, i) => (
									<FormControl key={i}>
										<Box display="flex" alignItems="center" mb="0.5rem" color="#C4C4C4">
											<Box mr="0.5rem">
												<BiTimeFive />
											</Box>
											{item.date}
											{item.type === 'payment_order' && <PaymentOrder item={item} />}
										</Box>
										<StyleBox>
											{item.type === 'payment_order' ? (
												<>
													<Box>付款單已成立</Box>
													<Box color="red">NT${priceCommaInserter(item.price)}</Box>
												</>
											) : (
												<>
													<Box>{item.name} 已售出</Box>
													<Box color="#51AB9B">NT${priceCommaInserter(item.price)}</Box>
												</>
											)}
										</StyleBox>
									</FormControl>
								))
							) : (
								<Box textAlign="center" fontWeight="bold">
									暫無紀錄
								</Box>
							)}
							{isFetching
								? [1, 2, 3].map((item, i) => (
										<FormControl key={i}>
											<Box display="flex" alignItems="center" mb="0.5rem" color="#C4C4C4">
												<Box mr="0.5rem">
													<BiTimeFive />
												</Box>
												<Skeleton height="1rem" w="100px" />
											</Box>
											<StyleBox>
												<Skeleton h="1rem" w="100%" />
											</StyleBox>
										</FormControl>
								  ))
								: null}
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
