import ReportService from 'Api/report/reportService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetStockSalesData(editState) {
	const [chartData, setChartData] = useState([])
	const [tableData, setTableData] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const reportService = new ReportService()
			const result = await reportService.getStockSalesChartData(editState.date)
			const { status, data } = result
			switch (status) {
				case 200:
					setChartData((pre) => (pre = returnChartDataset(data.response)))
					setTableData((pre) => (pre = [...data.response.table_data]))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}
		if (editState.date.startDate && editState.date.endDate) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])
	return [chartData, tableData]
}

function returnChartDataset(apiRawData) {
	let date = apiRawData['date']
	let import_count = apiRawData['import_count']
	let import_price = apiRawData['import_price']
	let sale_count = apiRawData['sale_count']
	let sale_price = apiRawData['sale_price']
	let stockAmount = {
		label: '進貨數量',
		data: import_count,
		borderColor: 'rgba(255, 99, 132, 1)',
		backgroundColor: 'rgba(255, 99, 132, 1)',
	}
	let salesAmount = {
		label: '銷貨數量',
		data: sale_count,
		borderColor: 'rgba(54, 162, 235, 1)',
		backgroundColor: 'rgba(54, 162, 235, 1)',
	}

	let stockPrice = {
		label: '進貨金額',
		data: import_price,
		borderColor: 'rgba(255, 99, 132, 1)',
		backgroundColor: 'rgba(255, 99, 132, 1)',
	}
	let salesPrice = {
		label: '銷貨金額',
		data: sale_price,
		borderColor: 'rgba(54, 162, 235, 1)',
		backgroundColor: 'rgba(54, 162, 235, 1)',
	}
	let stockAndSalesAmountDataset = [stockAmount, salesAmount]
	let stockAndSalesPriceDataset = [stockPrice, salesPrice]
	return [
		{ chart: 'stockSaleAmount', labels: date, datasets: stockAndSalesAmountDataset },
		{ chart: 'stockSalePrice', labels: date, datasets: stockAndSalesPriceDataset },
	]
}
