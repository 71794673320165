import { Box, useToast } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { useForm } from 'react-hook-form'
import { subStockReturnContext } from './useContext/createContext'
import { useCallback, useState } from 'react'
import { StyledButtonBox } from 'utils/StyledButton'
import { error, success, warning } from 'reducers/toast'
import { useDispatch } from 'react-redux'
import AddSaleSheetHead from './components/AddSaleSheetHead'
import AddSaleSheet from './components/AddSaleSheet'
import SubmitAlert from './components/SubmitAlert'
import { getCookie } from 'utils/cookies'
import { ReturnService } from 'Api/return/returnService'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
	background-color: white;

	td,
	th {
		min-width: 80px;
		max-width: 160px;
	}
`

const StyledForm = styled.form`
	width: 100%;
`

SubContent.propTypes = {
	refetchList: PropTypes.func,
}

export default function SubContent({ refetchList }) {
	const { handleSubmit } = useForm()
	const [subTableData, setSubTableData] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const dispatch = useDispatch()
	const history = useHistory()

	const goBackToList = () => {
		history.push('/admin/return/stockReturn')
	}

	const submitHandler = async (formData) => {
		if (subTableData.length <= 0) {
			toast({ title: '請至少新增一件商品', status: 'warning' })
			return
		}
		const merchantIdArray = []
		for (let i = 0; i < subTableData.length; i++) {
			merchantIdArray.push(subTableData[i].id)
		}
		formData.product_ids = merchantIdArray.join(',')
		formData.location_id = getCookie('_l')
		setIsLoading(true)
		const returnService = new ReturnService()
		const result = await returnService.createStockReturnSheet(formData)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				goBackToList()
				refetchList()
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
		setIsLoading(false)
	}

	const subTableTotalPrice = useCallback(() => {
		let totalPrice = 0
		for (const data of subTableData) {
			totalPrice += data.price
		}
		return totalPrice
	}, [subTableData])

	const subTableTotalReturnFee = useCallback(() => {
		let isAnyMerchantExpired = true
		for (const data of subTableData) {
			if (data.expire === '否') {
				isAnyMerchantExpired = false
			}
		}
		// 所有商品裡面只要有一個商品沒有超時，一張單固定就是扣五百手續費
		if (isAnyMerchantExpired) {
			return 0
		} else {
			return 500
		}
	}, [subTableData])

	const value = { subTableData, setSubTableData }
	return (
		<subStockReturnContext.Provider value={value}>
			<StyledTransitionBg>
				<AddSaleSheetHead />
				<AddSaleSheet />
				<StyledForm autoComplete="off">
					<Box d="flex" justifyContent="flex-end" mt="3em">
						<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em">
							寄賣總額
							<Box d="flex" alignItems="flex-end" ml="1.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
								$ {subTableTotalPrice()}
							</Box>
						</Box>
						<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em" mx="3rem">
							手續費
							<Box d="flex" alignItems="flex-end" ml="1.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
								$ {subTableTotalReturnFee()}
							</Box>
						</Box>
					</Box>

					<Box textAlign="center" py="1em" d="flex" justifyContent="center">
						<StyledButtonBox mx=".5em" onClick={goBackToList}>
							取消
						</StyledButtonBox>
						<SubmitAlert handleSubmit={handleSubmit} submitHandler={submitHandler} loadingState={isLoading} />
					</Box>
				</StyledForm>
			</StyledTransitionBg>
		</subStockReturnContext.Provider>
	)
}
