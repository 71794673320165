import { Box } from '@chakra-ui/react'
import useGetLocationSelect from 'hook/saleTable/useGetLocationSelect'
import React, { useMemo } from 'react'
// import { useState } from 'react'
import { getCookie } from 'utils/cookies'
import StyledFormLabel from 'utils/StyledFormLabel'

export default React.memo(function LocationSelect({ setValue }) {
	const [locationData] = useGetLocationSelect()
	// const [locationSelect, setLocationSelect] = useState('')

	const memoLocationName = useMemo(() => {
		let name = ''
		if (locationData.length > 0) {
			for (const item of locationData) {
				if (item.id === parseInt(getCookie('_l'))) {
					setValue('location_id', item.id)
					name = item.name
				}
			}
		}
		return name
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationData])

	return (
		<Box>
			<StyledFormLabel tag="銷售通路" isRequired />
			{/* 地點原來是可以select，但被取消，改成綁定登入地點 */}
			<Box minH="2.5rem" display="flex" alignItems="center" borderBottom="1px solid" minW="100px">
				{memoLocationName || '預設登入位置'}
			</Box>

			{/* <Select isRequired onChange={(e) => setLocationSelect(e.target.value)} {...register('location_id')} value={locationSelect} w="180px" disabled>
				<option value="" disabled>
					銷售通路
				</option>
				{locationData.length > 0
					? locationData.map((item) =>
							item.is_on_sale_order === 1 ? (
								<option key={item.name} value={item.id}>
									{item.name}
								</option>
							) : null
					  )
					: null}
			</Select> */}
		</Box>
	)
})
