import { Box } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { MerchantDataProvider, UpdateModalProvider } from './useContext/createContext'
import { useState } from 'react'
import { FormSubmitButton } from 'utils/StyledButton'
import SingleItem from './components/singleItem/SingleItem'
import UpdateModal from './components/singleItem/UpdateModal'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { useEffect } from 'react'
import { getOneProduct, ProductService } from 'Api/product/productService'
import VendorModalPortal from './components/VendorModal'
import { useQuery } from '@tanstack/react-query'
import { useParams, useHistory } from 'react-router-dom'
import { getCookie } from 'utils/cookies'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
`

const StyledForm = styled.form`
	width: 100%;
`

export default function SubContent({ handleRefetchList }) {
	const { id } = useParams()
	const history = useHistory()
	const { data } = useQuery({ queryKey: ['getOneProduct'], queryFn: getOneProduct(id) })
	const hookFormMethods = useForm()
	const { handleSubmit } = hookFormMethods
	const [updateModalInfo, setUpdateModalInfo] = useState({ type: '' })
	const dispatch = useDispatch()
	const [merchantData, setMerchantData] = useState()

	const productDetailData = data?.data?.response

	useEffect(() => {
		setMerchantData((pre) => (pre = productDetailData))
	}, [productDetailData])

	// 防止ENTER送出表單
	const checkKeyDown = (e) => {
		if (e.key === 'Enter') e.preventDefault()
	}

	const submitHandler = async (formData) => {
		const storedLocationId = getCookie('_l')
		if (!storedLocationId) {
			return
		}

		formData.location_id = storedLocationId
		const productService = new ProductService()
		const result = await productService.updateProduct(id, formData)
		const { status, data } = result
		switch (status) {
			case 200:
				dispatch(success({ message: data.message, status: status }))
				handleRefetchList && handleRefetchList()
				goBackList()
				break
			case 400:
				dispatch(warning({ message: data.message, status: status, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, status: status, code: data.code }))
				break
		}
	}

	function updateData(name, value, order) {
		let array = merchantData.map((item, i) => {
			if (i === order) {
				return { ...item, [name]: value }
			} else {
				return item
			}
		})
		setMerchantData(array)
	}

	const goBackList = () => {
		history.push(`/admin/stock/merchant?from=${id}`)
	}

	const merchantDataValue = {
		merchantData,
		updateData,
		setMerchantData,
	}

	const updateModalValue = { updateModalInfo, setUpdateModalInfo }
	return (
		<StyledTransitionBg>
			<MerchantDataProvider value={merchantDataValue}>
				<UpdateModalProvider value={updateModalValue}>
					<UpdateModal />
					<FormProvider {...hookFormMethods}>
						{merchantData && (
							<StyledForm onSubmit={handleSubmit(submitHandler)} autoComplete="off" onKeyDown={(e) => checkKeyDown(e)}>
								<SingleItem order={merchantData} />
								<Box justifyContent="center" py="1em" position="relative" d="flex" alignItems="center">
									<FormSubmitButton onClick={goBackList} mx=".5em">
										取消
									</FormSubmitButton>
									<FormSubmitButton mx=".5em" type="submit">
										儲存
									</FormSubmitButton>
									<Box d="inline-block" position="absolute" right="4%">
										<VendorModalPortal vendorId={merchantData.vendor_id || null} />
									</Box>
								</Box>
							</StyledForm>
						)}
					</FormProvider>
				</UpdateModalProvider>
			</MerchantDataProvider>
		</StyledTransitionBg>
	)
}
