import { Box } from '@chakra-ui/layout'
import React from 'react'
import styled from 'styled-components'
const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`
export default function SubtypeTable({ subtypeData }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>子類別名稱</td>
						<td>子類別備註</td>
						<td>子類別數量</td>
					</tr>
				</thead>
				<tbody>
					{subtypeData.map((item, i) => (
						<tr key={i}>
							<td>{item.subtype}</td>
							<td>{item.notes}</td>
							<td>{item.count}</td>
						</tr>
					))}
				</tbody>
			</table>
		</StyledTable>
	)
}
