import React, { useContext } from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { transferContext } from './useContext/createContext'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdPrint } from 'react-icons/md'
import { useForm } from 'react-hook-form'
const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;
	@media print {
		display: none;
	}
	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
	margin-right: 2.5em;
`

export default function TableTopBar() {
	const { setEditState, editState } = useContext(transferContext)
	const { handleSubmit, register } = useForm()

	function onSubmit(formData) {
		setEditState((pre) => (pre = { ...pre, date: formData }))
	}
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				調撥管理
				{editState.editType === 'create' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						新增調撥單
					</Box>
				) : null}
				{editState.editType === 'print' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						列印調撥單
					</Box>
				) : null}
			</Box>
			{editState.editType === 'viewList' ? (
				<Box d="flex">
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Input maxW="160px" type="date" {...register('date.startDate')} isRequired />
						<Box mx=".5em">到</Box>
						<Input mr="1em" maxW="160px" type="date" {...register('date.endDate')} isRequired />
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">搜尋</Text>
						</FormSubmitButton>
					</StyledForm>
					<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'create' }))}>
						<HiPlusSm />
						新增調撥單
					</StyledButtonBox>
				</Box>
			) : null}
			{editState.editType === 'print' ? (
				<StyledButtonBox onClick={() => window.print()}>
					<MdPrint /> 列印
				</StyledButtonBox>
			) : null}
		</StyledTopbar>
	)
}
