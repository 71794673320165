import { ProductService } from 'Api/product/productService'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

// 有兩個地方使用 ReusedModal.js & SubContent.js

export default function useGetOneProduct(productState) {
	const [productData, setProductData] = useState({ data: {}, isFetching: false })
	const [priceLog, setPriceLog] = useState([])
	const dispatch = useDispatch()

	useEffect(() => {
		async function fetchData() {
			setProductData((pre) => (pre = { ...pre, isFetching: true }))
			const productService = new ProductService()
			const result = await productService.getOneProduct(productState.id)
			const { status, data } = result
			switch (status) {
				case 200:
					setProductData({ data: { ...data.response }, isFetching: false })
					dispatch(success({ message: data.message, status: status, code: data.code }))
					break
				case 400:
					setProductData({ data: {}, isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setProductData({ data: {}, isFetching: false })
					break
			}
		}
		if (productState.id && productState.editType === 'edit') fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productState])

	useEffect(() => {
		async function fetchData() {
			const productService = new ProductService()
			let result = ''
			if (productState.type === '定價') {
				result = await productService.getPriceLog(productState.id)
			}
			if (productState.type === '進價') {
				result = await productService.getPurchasePriceLog(productState.id)
			}

			const { status, data } = result
			switch (status) {
				case 200:
					setPriceLog([...data.response])
					dispatch(success({ message: data.message, status: status, code: data.code }))
					break
				case 400:
					setPriceLog([])
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setPriceLog([])
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}
		if ((productState.id && productState.type === '定價') || (productState.id && productState.type === '進價')) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productState])

	return [productData, priceLog]
}
