import React, { useContext } from 'react'
import { Box } from '@chakra-ui/react'
import styled from 'styled-components'
import { tempPurchaseContext } from './useContext/createContext'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight, MdPrint } from 'react-icons/md'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}

	@media print {
		display: none;
	}
`

export default function TableTopBar() {
	const { editState, setPrintType } = useContext(tempPurchaseContext)

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex" whiteSpace="nowrap">
				暫存進貨單管理
				{editState.editType !== 'viewList' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{/* {editState.editType === 'create' ? '新增暫存進貨單' : null} */}
						{editState.editType === 'print' ? '列印暫存進貨單' : null}
					</Box>
				) : null}
			</Box>
			<Box d="flex">
				{editState.editType === 'print' ? (
					<>
						<StyledButtonBox
							onClick={() => {
								setPrintType('table')
								// window.print()
							}}
							mr="1rem"
						>
							<MdPrint /> 列印
						</StyledButtonBox>
						<StyledButtonBox
							onClick={() => {
								setPrintType('precautions')
								// window.print()
							}}
						>
							<MdPrint /> 列印簽單
						</StyledButtonBox>
					</>
				) : null}
			</Box>
		</StyledTopbar>
	)
}
