import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormLabel, Input } from '@chakra-ui/react'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { ColorService } from 'Api/color/colorService'

export default function EditModal() {
	const { isOpen, onClose, editState, onToggle, setIsNeedFetchList } = useContext(otherTableContext)
	const { register, handleSubmit } = useForm()
	const dispatch = useDispatch()

	//這個MODAL處理兩個狀態
	//一個是編輯表單，editState有值
	//一個是新增表單，editState沒有值
	useEffect(() => {
		if (editState.id) {
			onToggle()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])

	async function onSubmit(formData) {
		const colorService = new ColorService()
		let result
		if (editState.editType === 'edit') {
			result = await colorService.updateColor(editState.id, formData)
		}

		if (editState.editType === 'create') {
			result = await colorService.createColor(formData)
		}

		if (editState.editType === 'delete') {
			result = await colorService.deleteColor(editState.id)
		}

		const { status, data } = result
		switch (status) {
			case 200:
				setIsNeedFetchList(true)
				dispatch(success({ message: data.message, code: data.code, status: status }))
				onToggle()
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent py="1em" borderRadius="15px">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalHeader fontSize="2.5em" textAlign="center">
						{editState.editType === 'edit' ? '編輯顏色' : null}
						{editState.editType === 'create' ? '新增顏色' : null}
						{editState.editType === 'delete' ? '刪除顏色' : null}
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody d="flex" justifyContent="center" px="2em" mb="1em">
						<Box mr="1em">
							<FormLabel>顏色名稱</FormLabel>
							<Input {...register('name', { value: editState.name })} required disabled={editState.editType === 'delete' ? true : false} />
						</Box>
						<Box>
							<FormLabel>說明</FormLabel>
							<Input {...register('notes', { value: editState.notes })} disabled={editState.editType === 'delete' ? true : false} />
						</Box>
					</ModalBody>

					<ModalFooter justifyContent="center">
						<StyledButtonBox mr="1em" onClick={onClose}>
							取消
						</StyledButtonBox>
						<FormSubmitButton bg="white" type="submit">
							確定
						</FormSubmitButton>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
