import { Box, FormLabel } from '@chakra-ui/react'
import React from 'react'
import AddProductForm from './AddProductForm'
import styled from 'styled-components'
import { getCookie } from 'utils/cookies'

const StyledBox = styled(Box)`
	border-bottom: 1px solid;
	height: 1.8em;
	min-width: 160px;
	padding: 0 1em;
	border-color: black;
`

export default function AddSaleSheetHead() {
	return (
		<Box d="flex" justifyContent="space-between" mb="1.5em" mt="4em">
			<AddProductForm />
			<Box d="flex">
				<Box mr="2em">
					<FormLabel>銷退單號</FormLabel>
					<StyledBox color="gray.600">(結束後自動產生)</StyledBox>
				</Box>
				<Box mr="2em">
					<FormLabel>員工編號</FormLabel>
					<StyledBox>{getCookie('no')}</StyledBox>
				</Box>
			</Box>
		</Box>
	)
}
