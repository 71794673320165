import Axios from 'Api/interceptors'

export default class PurchaseOrdersService {
	async getOrderList(vendorParams, currentPage) {
		const { phone, date } = vendorParams
		let startDate = date.startDate ? date.startDate.replace(/-/gi, '/') : ''
		let endDate = date.endDate ? date.endDate.replace(/-/gi, '/') : ''
		let result
		try {
			if (startDate && endDate)
				result = await Axios.get(`/purchaseOrders?keyword=${phone}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}`)
			if (!startDate || !endDate) result = await Axios.get('/purchaseOrders?keyword=' + phone + '&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOrderDetail(orderId) {
		let result
		try {
			result = await Axios.get('/purchaseOrders/' + orderId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSelectOptionsData() {
		let result
		try {
			result = await Axios.get('/purchaseOrderOption')
		} catch (error) {
			result = error
		}
		return result
	}

	async createPurchaseOrder(payload) {
		let result
		try {
			result = await Axios.post('/purchaseOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}
}

export const getPurchaseOrderList =
	(vendorParams = {}) =>
	() => {
		const newParams = {
			keyword: vendorParams.phone,
			start_date: vendorParams.startDate?.replaceAll('-', '/'),
			end_date: vendorParams.endDate?.replaceAll('-', '/'),
			page: vendorParams.page,
		}

		Object.keys(newParams).forEach((key) => {
			if (!newParams[key]) delete newParams[key]
		})

		// turn params to query string
		const params = new URLSearchParams(newParams).toString()

		return Axios.get(`/purchaseOrders?${decodeURIComponent(params)}`)
	}

export const getOrderDetail = (orderId) => () => {
	return Axios.get('/purchaseOrders/' + orderId)
}

export async function getSelectOptionsData() {
	return await Axios.get('/purchaseOrderOption')
}
