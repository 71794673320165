import { Box, Input } from '@chakra-ui/react'
import React from 'react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineSearch } from 'react-icons/ai'
import { FormSubmitButton } from 'utils/StyledButton'
import { purchaseContext } from '../useContext/createContext'

export default function TopbarForm() {
	const { setParams } = useContext(purchaseContext)
	const { handleSubmit, register } = useForm()
	function onSubmit({ phone, startDate, endDate }) {
		setParams({ page: 1, phone, startDate, endDate })
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Box d="flex" mr="1em">
				<Box d="flex" alignItems="center" mr="1em">
					<Input type="date" w="160px" {...register('startDate')} />
					<Box mx=".5em">到</Box>
					<Input type="date" w="160px" {...register('endDate')} />
				</Box>

				<Input w="220px" mr="1em" placeholder="請輸入廠商電話或進貨單編號" {...register('phone')} />
				<FormSubmitButton type="submit">
					<AiOutlineSearch />
					搜尋
				</FormSubmitButton>
			</Box>
		</form>
	)
}
