import { Box, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import StyledFormLabel from 'utils/StyledFormLabel'

// 為什麼要獨立把這些radio和input做成獨立的component
// 因為和form同一層的話，setState導致的component re-render會使form data變成undefined
export default function CashOrRemitInput({ register, setValue }) {
	// radio切換要用現金(cash)或轉帳(remit)，如果是remit，轉帳代碼和帳號就必填
	const [paymentMethod, setPaymentMethod] = useState('')

	useEffect(() => {
		setValue('payment', paymentMethod)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentMethod])

	return (
		<Box className="radioBox">
			<StyledFormLabel tag="付款方式" isRequired />
			<Box d="flex" alignItems="center">
				<RadioGroup onChange={setPaymentMethod} value={paymentMethod} mr="1em">
					<Stack direction="row" spacing={3}>
						<Radio value="cash" isRequired>
							<Box isTruncated>現金</Box>
						</Radio>
						<Radio value="remit" isRequired>
							<Box isTruncated>匯款</Box>
						</Radio>
					</Stack>
				</RadioGroup>
				<Box d="flex">
					<Input
						w="90px"
						placeholder="銀行代碼"
						mr="1em"
						type="text"
						maxLength="7"
						minLength="3"
						{...register('code')}
						required={paymentMethod === 'remit' ? true : false}
						disabled={paymentMethod === 'remit' ? false : true}
					/>
					<Input
						w="160px"
						placeholder="請輸入銀行帳戶"
						type="text"
						maxLength="14"
						{...register('account')}
						required={paymentMethod === 'remit' ? true : false}
						disabled={paymentMethod === 'remit' ? false : true}
					/>
				</Box>
			</Box>
		</Box>
	)
}
