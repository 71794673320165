import { Route, Redirect } from 'react-router-dom'
import Layout from 'layout/Layout'

// hr table -------- start
import StaffTable from 'components/table/hr/staffTable/StyledTable'
// hr table -------- end

import PurchaseSheetTable from 'components/table/purchase/purchaseTable/StyledTable'
import TempPurchaseSheetTable from 'components/table/purchase/tempPurchaseTable/StyledTable'
import AttendanceTable from 'components/table/hr/attendanceTable/StyledTable'
import VendorManagementTable from 'components/table/vendor/vendorTable/StyledTable'
import SaleOrderTable from 'components/table/sale/saleOrderTable/StyledTable'
import MerchantTable from 'components/table/stock/merchantTable/StyledTable'
import TransferTable from 'components/table/stock/transferTable/StyledTable'
import RePurchaseTable from 'components/table/stock/rePurchaseTable'
import PaymentTable from 'components/table/payment/paymentTable/StyledTable'
import StoreSaleChart from 'components/table/report/storeSalesChart/StyledTable'
import StockAndSalesChart from 'components/table/report/stockAndSalesChart/StyledTable'
import StockCategoryChart from 'components/table/report/stockCategoryChart/StyledTable'
import BrandsSalesChart from 'components/table/report/brandsSalesChart/StyledTable'
import StockReturnTable from 'components/table/return/stockReturnTable/StyledTable'
import SaleReturnTable from 'components/table/return/saleReturnTable/StyledTable'
import DailySalesChart from 'components/table/report/dailySalesChart/StyledTable'
// 其他設定 other table --------- start
// import AuthTable from 'components/table/other/authTable/StyledTable'
// import TypeTable from 'components/table/other/typeTable/StyledTable'
// import SizeTable from 'components/table/other/sizeTable/StyledTable'
// import ColorTable from 'components/table/other/colorTable/StyledTable'
// import ConditionTable from 'components/table/other/conditionTable/StyledTable'
// import BrandTable from 'components/table/other/brandTable/StyledTable'
// import PositionTable from 'components/table/other/positionTable/StyledTable'
// import { Provider as OtherTableProvider } from 'context/otherTableContext'
import OtherTableRoute from './OtherTableRoute'
import { useSelector } from 'react-redux'
import InventoryTable from 'components/table/inventory/inventoryTable'
// 其他設定 other table --------- end

const AdminRoute = () => {
	const { isAuth } = useSelector((state) => state.authState)

	if (!isAuth) {
		return <Redirect to="/login" />
	}

	return (
		<Route
			render={() => {
				return (
					<Layout>
						<Route exact path="/admin/hr/users" component={StaffTable} />
						<Route exact path="/admin/hr/attendance" component={AttendanceTable} />
						<Route exact path="/admin/purchase/purchaseSheet" component={PurchaseSheetTable} />
						<Route exact path="/admin/purchase/purchaseSheet/:action" component={PurchaseSheetTable} />
						<Route exact path="/admin/purchase/purchaseSheet/order/:id" component={PurchaseSheetTable} />
						<Route exact path="/admin/purchase/tempPurchaseSheet" component={TempPurchaseSheetTable} />
						<Route exact path="/admin/stock/merchant" component={MerchantTable} />
						<Route exact path="/admin/stock/merchant/:id" component={MerchantTable} />
						<Route exact path="/admin/stock/transfer" component={TransferTable} />
						<Route exact path="/admin/stock/rePurchase" component={RePurchaseTable} />
						<Route exact path="/admin/stock/rePurchase/:action" component={RePurchaseTable} />
						<Route exact path="/admin/vendor/vendorManagement" component={VendorManagementTable} />
						<Route exact path="/admin/sale/saleOrder" component={SaleOrderTable} />
						<Route exact path="/admin/payment/paymentList" component={PaymentTable} />
						<Route exact path="/admin/reportAndChart/storeSales" component={StoreSaleChart} />
						<Route exact path="/admin/reportAndChart/stockAndSales" component={StockAndSalesChart} />
						<Route exact path="/admin/reportAndChart/stockCategory" component={StockCategoryChart} />
						<Route exact path="/admin/reportAndChart/brandsSales" component={BrandsSalesChart} />
						<Route exact path="/admin/reportAndChart/dailySales" component={DailySalesChart} />
						<Route exact path="/admin/return/stockReturn" component={StockReturnTable} />
						<Route exact path="/admin/return/stockReturn/:action" component={StockReturnTable} />
						<Route exact path="/admin/return/stockReturn/order/:id" component={StockReturnTable} />
						<Route exact path="/admin/return/saleReturn" component={SaleReturnTable} />
						<Route exact path="/admin/inventory/inventorySheet" component={InventoryTable} />
						<Route exact path="/admin/inventory/inventorySheet/detail/:id" component={InventoryTable} />
						<Route exact path="/admin/inventory/inventorySheet/edit/:id" component={InventoryTable} />
						<Route path="/admin/other">
							<OtherTableRoute />
						</Route>
						<Route>
							<Redirect to="/admin" />
						</Route>
					</Layout>
				)
			}}
		/>
	)
}

export default AdminRoute
