import { Image, Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import SideBarItem from './SideBarItem'
import styled from 'styled-components'
import logoIcon from 'assets/images/logo.svg'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

const StyledSideBar = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #2b64d0;
	padding-top: 3rem;
	overflow: hidden;
`

const IconBox = styled(Box)`
	display: flex;
	align-items: center;
	font-size: 1.25em;
	background: white;
	border-radius: 7px;
	width: 75%;
	margin: 0 auto 3em;
	overflow: hidden;
`

const ROUTES = [
	{
		title: '人事管理',
		base: 'hr',
		children: [
			{
				title: '用戶管理',
				route: 'users',
			},
			{
				title: '出勤管理',
				route: 'attendance',
			},
		],
	},
	{
		title: '進貨管理',
		base: 'purchase',
		children: [
			{ title: '進貨單', route: 'purchaseSheet' },
			{ title: '暫存進貨單', route: 'tempPurchaseSheet' },
		],
	},
	{
		title: '庫存管理',
		base: 'stock',
		children: [
			{ title: '商品管理', route: 'merchant' },
			{ title: '調撥管理', route: 'transfer' },
			{ title: '寄賣轉買斷', route: 'rePurchase' },
		],
	},
	{ title: '廠商管理', base: 'vendor', children: [{ title: '廠商管理', route: 'vendorManagement' }] },
	{
		title: '銷貨管理',
		base: 'sale',
		children: [{ title: '銷貨單', route: 'saleOrder' }],
	},
	{
		title: '付款管理',
		base: 'payment',
		children: [{ title: '付款單', route: 'paymentList' }],
	},
	{
		title: '退貨管理',
		base: 'return',
		children: [
			{ title: '寄賣退貨', route: 'stockReturn' },
			{ title: '銷貨退貨', route: 'saleReturn' },
		],
	},
	{
		title: '報表管理',
		base: 'reportAndChart',
		children: [
			{ title: '通路銷貨報表', route: 'storeSales' },
			{ title: '庫存類別報表', route: 'stockCategory' },
			{ title: '進貨銷貨報表', route: 'stockAndSales' },
			{ title: '品牌銷售報表', route: 'brandsSales' },
			{ title: '當日銷售報表', route: 'dailySales' },
		],
	},
	{
		title: '盤點管理',
		base: 'inventory',
		children: [{ title: '盤點管理報表', route: 'inventorySheet' }],
	},
	{
		title: '其他管理',
		base: 'other',
		children: [
			{
				title: '權限角色設定',
				route: 'roles',
			},
			{
				title: '類別設定',
				route: 'types',
			},
			{
				title: '尺寸設定',
				route: 'sizes',
			},
			{
				title: '顏色設定',
				route: 'colors',
			},
			{
				title: '狀況設定',
				route: 'conditions',
			},
			{
				title: '品牌設定',
				route: 'brands',
			},
			{
				title: '位置設定',
				route: 'locations',
			},
		],
	},
]

export default function SideBar() {
	const history = useHistory()
	const location = useLocation()
	const [activeRoute, setActiveRoute] = React.useState('')
	const [activeSubitem, setActiveSubitem] = React.useState('')

	useEffect(() => {
		const path = location.pathname.split('/')
		const [base, route] = path.slice(2)
		const activeRoute = ROUTES.find((item) => item.base === base)
		if (!activeRoute) return

		setActiveRoute(activeRoute.title)
		setActiveSubitem(route)
	}, [location])

	function handleOnClickItem(itemTitle) {
		setActiveRoute(itemTitle)
	}

	function handleOnClickSubitem(title, subitemTitle) {
		const [activeRoute] = ROUTES.filter((route) => route.title === title)
		if (!activeRoute) return

		const activeSubitem = activeRoute.children.find((item) => item.title === subitemTitle)
		if (!activeSubitem) return

		history.push(`/admin/${activeRoute.base}/${activeSubitem.route}`)
	}

	return (
		<StyledSideBar>
			<IconBox>
				<Image src={logoIcon} />
			</IconBox>
			{ROUTES.map((item, i) => (
				<SideBarItem
					title={item.title}
					children={item.children}
					isToggle={item.title === activeRoute}
					key={i}
					onClickItem={handleOnClickItem}
					onClickSubitem={handleOnClickSubitem}
					activeSubitem={activeSubitem}
				/>
			))}
		</StyledSideBar>
	)
}
