import Axios from 'Api/interceptors'

export class SizeService {
	async createSize(payload) {
		let result
		try {
			result = await Axios.post('/sizes', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateSize(id, payload) {
		let result
		try {
			result = await Axios.put('/sizes/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteSize(id) {
		let result
		try {
			result = await Axios.delete('/sizes/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}
