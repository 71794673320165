import { LocationService } from 'Api/location/locationService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetLocationSelect() {
	const [locationData, setLocationData] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const locationService = new LocationService()
			const result = await locationService.getLocation()
			const { status, data } = result
			switch (status) {
				case 200:
					setLocationData((pre) => (pre = [...data.response.data]))
					// dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return [locationData]
}
