import { error } from '@chakra-ui/utils'
import { VendorPageService } from 'Api/vendorPage/vendorPageService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { success, warning } from 'reducers/toast'

export default function useGetVendorDetail(isOpen, token) {
	const [vendorDetail, setVendorDetail] = useState({})
	const [isFetching, setIsFetching] = useState(true)
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const vendorPageService = new VendorPageService()
			const result = await vendorPageService.getVendorDetail(token)
			const { data, status } = result
			switch (status) {
				case 200:
					setVendorDetail((pre) => (pre = { ...data.response }))
					dispatch(success({ message: data.message, code: data.code, status: status }))
					break
				case 400:
					setVendorDetail({})
					dispatch(warning({ message: data.message, code: data.code, status: status }))
					break
				default:
					setVendorDetail({})
					dispatch(error({ message: data.message, code: data.code, status: status }))
					break
			}
			setIsFetching(false)
		}
		if (isOpen && token) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])
	return [vendorDetail, isFetching]
}
