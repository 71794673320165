import React from 'react'
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Checkbox, Input } from '@chakra-ui/react'
import { useState } from 'react'
import SingleCheckboxGroup from './SingleCheckboxGroup'
import { useEffect } from 'react'
import { checkboxContext } from '../useContext/createContext'
import { useContext } from 'react'
export default function SingleAccordionItem({ item }) {
	const { register, roleData } = useContext(checkboxContext)
	const [checkList, setCheckList] = useState({})

	// 這個useEffect是在讓資料注入的時候把下列資料傳換格式
	// 丟進 dataArrayToCheckObejct 這個function的item.permission會是下面這個格式(array)
	// item.permission = [{id: 1, name: "清單"}, {id: 2, name: "新增"},{id: 3, name: "個別資料"},{id: 4, name: "編輯"},{id: 5, name: "刪除"}]
	// 會把這個array 轉換成 {1:false,2:false,3:false,4:false}，obj的key就是array中每一個element的id
	// 另外 id是unique的
	useEffect(() => {
		function dataArrayToCheckObejct(dataArray = []) {
			const obj = {}
			for (let i = 0; i < dataArray.length; i++) {
				obj[dataArray[i]['id']] = false
			}
			return obj
		}
		setCheckList((pre) => (pre = dataArrayToCheckObejct(item.permission)))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// 如果是編輯個人權限資料，就會拿到roleData，這邊在做roleData跟default permission list的mapping
	// 把屬於該用戶資料的checkbox變成勾選狀態
	useEffect(() => {
		function checkboxStateMapping() {
			let permission_ids_array = roleData.data.permission_ids ? roleData.data.permission_ids.split(',') : []
			let obj = {}
			for (let item in checkList) {
				if (permission_ids_array.indexOf(item) > -1) {
					obj[item] = true
				}
			}
			return obj
		}

		if (roleData.data.id && !roleData.isFetching) {
			setCheckList((pre) => (pre = { ...pre, ...checkboxStateMapping() }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleData.isFetching])

	// 這個function是會依照負責all checked/cancelled的checkbox的state去改變底下所有子checkbox的state
	function setAllCheckState(state) {
		if (state) {
			setCheckList((pre) => {
				for (let i in pre) {
					pre[i] = true
				}
				return { ...pre }
			})
		}
		if (!state) {
			setCheckList((pre) => {
				for (let i in pre) {
					pre[i] = false
				}
				return { ...pre }
			})
		}
	}

	// checkList會是這樣的物件 {15:false,16:false,17:true,18:true}
	// 這個function會去判斷只要checklist裡面有一個值是true，則讓負責all check/cancel的 checkbox是不是checked state
	function isAllChecked() {
		let defaultState = true

		if (Object.keys(checkList).length > 0) {
			for (let i in checkList) {
				if (!defaultState) {
					break
				}
				if (!checkList[i]) defaultState = false
			}
		}
		if (Object.keys(checkList).length === 0) defaultState = false
		return defaultState
	}

	return (
		<AccordionItem border="none" d="flex" alignItems="center" flexWrap="wrap">
			<Input {...register(`checkboxGroup.${item.name}`, { value: checkList })} d="none" />
			<Checkbox borderColor="gray.600" isChecked={isAllChecked()} onChange={(e) => setAllCheckState(e.target.checked)} />
			<AccordionButton flexShrink="0" w="100px" p="0.5em 0" _active={{ outline: 'none', boxShadow: 'none' }} _focus={{ outline: 'none', boxShadow: 'none' }}>
				<Box ml=".5em" textAlign="left" isTruncated>
					{item.name}
				</Box>
				<AccordionIcon />
			</AccordionButton>

			<AccordionPanel pb={4} w="100%" d="flex" flexWrap="wrap">
				<SingleCheckboxGroup sub_permission={item} checklist={checkList} setchecklist={setCheckList} />
			</AccordionPanel>
		</AccordionItem>
	)
}
