import ReportService from 'Api/report/reportService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetBrandsData(editState) {
	const [tableData, setTableData] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const reportService = new ReportService()
			const result = await reportService.getBrandSalesData(editState.brandIds, editState.date)
			const { status, data } = result
			switch (status) {
				case 200:
					setTableData((pre) => (pre = [...data.response[0]]))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}
		if (editState.date.startDate && editState.date.endDate && editState.brandIds) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])
	return [tableData]
}
