import { ReturnService } from 'Api/return/returnService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetStockReturnDetail(id) {
	const [orderData, setOrderData] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setOrderData((pre) => (pre = { ...pre, isFetching: true }))
			const returnService = new ReturnService()
			const result = await returnService.getStockReturnDetail(id)
			const { data, status } = result
			switch (status) {
				case 200:
					setOrderData((pre) => (pre = { data: { ...data.response }, isFetching: false }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					setOrderData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setOrderData((pre) => (pre = { data: {}, isFetching: false }))
					dispatch(error({ message: data.message, code: data.code }))
					break
			}
		}

		fetchData()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return [orderData]
}
