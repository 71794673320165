import React, { useContext } from 'react'
import { Box, Text } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { staffContext } from './useContext/createContext'
import { StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'
import TopbarForm from './TopbarForm'
const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

export default function TableTopBar() {
	const { setEditState, editState } = useContext(staffContext)

	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				用戶管理
				{editState.editType !== 'viewList' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						{editState.editType === 'edit' ? '編輯員工' : null}
						{editState.editType === 'create' ? '新增員工' : null}
					</Box>
				) : null}
			</Box>
			<Box d="flex">
				{editState.editType === 'viewList' ? (
					<>
						{/* <Input mr="1em" maxW="160px" placeholder="請輸入員編或姓名" /> */}
						<TopbarForm />
						<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'create' }))}>
							<HiPlusSm />
							<Text ml="5px">新增員工</Text>
						</StyledButtonBox>
					</>
				) : null}
			</Box>
		</StyledTopbar>
	)
}
