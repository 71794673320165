import React, { useState } from 'react'
import { Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import styled from 'styled-components'
import { FaUserClock } from 'react-icons/fa'
import { FormSubmitButton } from 'utils/StyledButton'
import { getCookie } from 'utils/cookies'
import { AttendanceService } from 'Api/attendance/attendanceService'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { AiFillClockCircle } from 'react-icons/ai'

const StyledMenuItem = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1.5em;
	cursor: pointer;
	position: relative;

	&:after {
		content: '';
		height: 1px;
		width: 80%;
		background: #d7d7d7;
		position: absolute;
		bottom: -1px;
	}
	&:last-child {
		&:after {
			display: none;
		}
	}

	&:hover {
		background: #2250a6;
		color: white;
	}
`

export default function Punch({ parentOnClose }) {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const [timeString, setTimeString] = useState(new Date().toLocaleTimeString())
	const [isLoadingCode, setIsLoadingCode] = useState(false)
	const dispatch = useDispatch()
	setInterval(() => {
		setTimeString(new Date().toLocaleTimeString())
	}, 1000)

	function punch() {
		setIsLoadingCode(true)
		navigator.geolocation.getCurrentPosition(async (position) => {
			const attendanceService = new AttendanceService()
			const payload = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
				store_id: getCookie('_l'),
			}
			const result = await attendanceService.punch(payload)
			const { data, status } = result

			switch (status) {
				case 200:
					dispatch(success({ message: `打卡成功 ${new Date().toLocaleTimeString()}`, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, status: status, code: result.code }))
					break
				case 500:
					dispatch(error({ message: data.message, status: status }))
					break
				default:
					dispatch(error({ message: data.message, status: status }))
					break
			}
			setIsLoadingCode(false)
		})
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose()
					parentOnClose()
				}}
				size="sm"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody textAlign="center" py="2rem">
						<Box fontSize="3rem">打卡時間</Box>
						<Box fontSize="2rem" my="2rem" display="flex" alignItems="center" justifyContent="center">
							<AiFillClockCircle />
							<Box mx="0.5rem" w="60%">
								{timeString}
							</Box>
						</Box>
						<Box display="flex" justifyContent="center">
							<FormSubmitButton onClick={punch} isLoading={isLoadingCode}>
								打卡
							</FormSubmitButton>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
			<StyledMenuItem onClick={onOpen}>
				<Box mr=".5em">
					<FaUserClock />
				</Box>
				打卡
			</StyledMenuItem>
		</>
	)
}
