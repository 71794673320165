import { Box, Spinner } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { attendanceContext } from './useContext/createContext'
import GeneralPagination from 'components/pagination'

export default function TableContent() {
	const { tableData, currentPage, maxPage, gotoPage } = useContext(attendanceContext)

	const data = React.useMemo(() => {
		return tableData.data || [{ date: '', user_no: '', name: '', work_time: '', location: '', off_time: '', off_location: '' }]
	}, [tableData.data])

	const columns = React.useMemo(
		() => [
			{
				Header: '日期',
				accessor: 'date',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '員工編號',
				accessor: 'user_no',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '姓名',
				accessor: 'name',
			},
			{
				Header: '上班打卡',
				accessor: 'work_time',
			},
			{
				Header: '上班地點',
				accessor: 'location',
			},
			{
				Header: '下班打卡',
				accessor: 'off_time',
			},
			{
				Header: '下班地點',
				accessor: 'off_location',
			},
			// {
			// 	Header: '打卡狀態',
			// 	accessor: 'management',
			// },
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},

		useSortBy,
		usePagination
	)
	return (
		<>
			{tableData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{tableData.data.length > 0 ? (
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map((headerGroup, g) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={g}>
										{headerGroup.headers.map((column, k) => {
											return (
												<th key={k}>
													<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
														{
															// Render the header
															column.render('Header')
														}
													</Box>
												</th>
											)
										})}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									page.map((row, i) => {
										// Prepare the row for display
										prepareRow(row)
										return (
											// Apply the row props
											<tr {...row.getRowProps()} key={i}>
												{
													// Loop over the rows cells
													row.cells.map((cell, j) => {
														// Apply the cell props
														return (
															<td {...cell.getCellProps()} key={j}>
																{
																	// Render the cell contents
																	cell.render('Cell')
																}
															</td>
														)
													})
												}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}

					<GeneralPagination defaultCurrent={currentPage} total={maxPage * 10} onChange={(page) => gotoPage(page)} />
				</>
			)}
		</>
	)
}
