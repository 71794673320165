import { Box, Input, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import styled from 'styled-components'
import StyledFormLabel from 'utils/StyledFormLabel'
import { FormSubmitButton } from 'utils/StyledButton'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import VendorService from 'Api/vendor/vendorService'
import { useContext } from 'react'
import { vendorContext } from '../useContext/createContext'
import ImageUploader from './ImageUploader'
import CashOrRemitInput from './CashOrRemitInput'

// const fadeInRight = keyframes`
//   0% {
//     transform:translate(100%)
//   }
//   100% {
// 	transform:translate(0%)
//   }
// `

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 0.7em 0.5em;
	border-radius: 10px;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0.7em 0;
`

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`

const GridBox = styled(Box)`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;

	${
		'' /* .photoBox {
		grid-column-start: 4;
		grid-row-start: 2;
		grid-row-end: 4;
	} */
	}
	.noteBox {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 2;
	}
`

export default function CreateVendorForm({ setEditState, editState, vendorImage }) {
	const { register, handleSubmit, reset, setValue } = useForm()
	const dispatch = useDispatch()
	const { setIsNeedFetchList } = useContext(vendorContext)

	useEffect(() => {
		if (editState.editType === 'create') {
			// 確保每次modal打開都是被reset過data
			reset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function onSubmit(data) {
		// payment如果是現金就給0
		// 如果是匯款就給1
		if (data.payment === 'cash') data.payment = 0
		if (data.payment === 'remit') data.payment = 1

		const venderService = new VendorService()
		let result
		if (editState.editType === 'create') result = await venderService.createVendor(data)
		if (editState.editType === 'edit') result = await venderService.updateVendor(editState.data.id, data)

		switch (result.status) {
			case 200:
				dispatch(success({ message: result.data.message || '新增成功', status: result.status, code: result.data.code }))
				setEditState({ editType: 'viewList' }) // 切換回列表
				setIsNeedFetchList(true) // 再fetchData一次
				break
			case 201:
				dispatch(success({ message: result.data.message || '新增成功', status: result.status, code: result.data.code }))
				break
			case 400:
				dispatch(warning({ message: result.data.message, status: result.restatus }))
				break
			default:
				dispatch(error({ message: result.data.message, status: result.restatus }))
				break
		}
	}

	const isCreateVendorForm = editState.editType === 'create'

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<StyledBg position="relative" transition="all .5s ease" minH="3em" overflow="hidden">
				<Box d="flex" w="100%" justifyContent="space-between" flexWrap="wrap" padding="3px">
					<GridBox>
						<Box>
							<Box>
								<StyledFormLabel tag="廠商電話" isRequired />
								<StyledInput {...register('phone', { value: editState.data.phone })} placeholder="0987654321" required />
							</Box>
							<Box>
								{/* API欄位沒變，一樣是line id，但改成紀錄instagram */}
								<StyledFormLabel tag="Instagram" />
								<StyledInput {...register('line', { value: editState.data.line })} placeholder="Instagram 帳號" />
							</Box>
						</Box>

						<Box>
							<Box>
								<StyledFormLabel tag="姓名" isRequired />
								<StyledInput {...register('name', { value: editState.data.name })} placeholder="王大明" required />
							</Box>
							<Box>
								<StyledFormLabel tag="性別" />
								<Select width="130px" defaultValue="" {...register('gender', { value: editState.data.gender })}>
									<option disabled value="">
										性別
									</option>
									<option value="">不提供</option>
									<option value="male">男性</option>
									<option value="female">女性</option>
								</Select>
							</Box>
						</Box>

						<ImageUploader register={register} setValue={setValue} vendorImage={vendorImage} />
						<CashOrRemitInput register={register} setValue={setValue} editState={editState} />

						<Box className="noteBox" w="100%">
							<StyledFormLabel tag="備註" />
							<Input w="90%" {...register('notes', { value: editState.data.notes })} />
						</Box>
					</GridBox>
				</Box>
			</StyledBg>
			<Box textAlign="center" py="1em">
				<FormSubmitButton mx=".5em" onClick={() => setEditState((pre) => (pre = { editType: 'viewList' }))}>
					取消
				</FormSubmitButton>
				<FormSubmitButton mx=".5em" type="submit">
					{isCreateVendorForm ? '新增廠商' : '儲存'}
				</FormSubmitButton>
			</Box>
		</form>
	)
}
