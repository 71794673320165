import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { postInventory } from 'Api/inventory'
import { getLocations } from 'Api/location/locationService'
import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { error, success, warning } from 'reducers/toast'

function CreateInventoryModal({ isOpen = false, onClose, refetchList }) {
	const dispatch = useDispatch()
	const { data } = useQuery(['getLocations'], { queryFn: getLocations })
	const [selectedLocation, setSelectedLocation] = React.useState(null)
	const [submitError, setSubmitError] = React.useState(null)

	const locationOptions =
		data?.data?.response?.data?.map((location) => ({
			value: location.id,
			label: location.name,
		})) || []

	const createInventoryMutation = useMutation({
		mutationFn: postInventory,
		onSuccess: () => {
			dispatch(success({ message: '新增盤點單成功！' }))
			refetchList()
			onClose()
		},
		onError: (err) => {
			if (err?.status === 400) {
				dispatch(warning({ message: err?.data?.message }))
			} else {
				dispatch(error({ message: err?.data?.message }))
			}
		},
	})

	const createInventory = () => {
		if (!selectedLocation) {
			setSubmitError('請至少選擇一間盤點店家')
			return
		}

		createInventoryMutation.mutate(selectedLocation.value)
	}

	const handleOnChange = (selectedOption) => {
		setSelectedLocation(selectedOption)
		setSubmitError(null)
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>選擇盤點店家</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Select placeholder="請選擇盤點店家" options={locationOptions} value={selectedLocation} onChange={handleOnChange} />
						{submitError && <div style={{ color: 'red' }}>{submitError}</div>}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
							取消
						</Button>
						<Button isLoading={createInventoryMutation.isLoading} onClick={createInventory} colorScheme="green">
							建立
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default CreateInventoryModal
