import { Box, Spinner } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import { FaPen, FaTrashAlt } from 'react-icons/fa'
import GeneralPagination from 'components/pagination'

export default function TableContent() {
	const { tableData, setEditState, currentPage, maxPage, gotoPage } = useContext(otherTableContext)
	const data = React.useMemo(() => {
		return tableData.data || [{ id: '', name: '', notes: '' }]
	}, [tableData.data])

	const columns = React.useMemo(
		() => [
			{
				Header: '序號',
				accessor: 'id',
				Cell: (row) => {
					return Number(row.row.id) + 1
				},

				disableFilters: true,
			},
			{
				Header: '顏色名稱',
				accessor: 'name',
				Cell: (row) => {
					return row.value
				},
			},
			{
				Header: '說明',
				accessor: 'notes',
				disableFilters: true,
			},
			{
				Header: '管理',
				accessor: 'management',
				disableFilters: true,
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box
								color="#91B0EB"
								cursor="pointer"
								onClick={() =>
									setEditState(
										(pre) =>
											(pre = { editType: 'edit', id: row.row.original.id, name: row.row.original.name, notes: row.row.original.notes })
									)
								}
							>
								<FaPen />
							</Box>
							<Box
								color="#EB9191"
								cursor="pointer"
								onClick={() =>
									setEditState(
										(pre) =>
											(pre = { editType: 'delete', id: row.row.original.id, name: row.row.original.name, notes: row.row.original.notes })
									)
								}
							>
								<FaTrashAlt />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,

		// pagination
		// canPreviousPage,
		// canNextPage,
		// pageOptions,
		// pageCount,
		// gotoPage,
		// nextPage,
		// previousPage,
		// state: { pageIndex },
	} = useTable(
		{
			columns,
			data,

			initialState: { pageIndex: 0, pageSize: 25 },
		},
		useFilters,
		useSortBy,
		usePagination
	)
	return (
		<>
			{tableData.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{tableData.data.length > 0 ? (
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map((headerGroup, g) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={g}>
										{headerGroup.headers.map((column, k) => {
											return (
												<th key={k}>
													<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
														{
															// Render the header
															column.render('Header')
														}
													</Box>
												</th>
											)
										})}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									page.map((row, i) => {
										// Prepare the row for display
										prepareRow(row)
										return (
											// Apply the row props
											<tr {...row.getRowProps()} key={i}>
												{
													// Loop over the rows cells
													row.cells.map((cell, j) => {
														// Apply the cell props
														return (
															<td {...cell.getCellProps()} key={j}>
																{
																	// Render the cell contents
																	cell.render('Cell')
																}
															</td>
														)
													})
												}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}

					<GeneralPagination defaultCurrent={currentPage} total={maxPage * 10} onChange={(page) => gotoPage(page)} />
				</>
			)}
		</>
	)
}
