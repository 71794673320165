import Cookies from 'js-cookie'

export function resetCookies() {
	Cookies.remove('token')
	Cookies.remove('userName')
	Cookies.remove('data')
	Cookies.remove('no')
	Cookies.remove('rid')
	Cookies.remove('_l')
}

export function setLoginInfo(token, userName, data, useNo) {
	Cookies.set('token', token)
	Cookies.set('userName', userName)
	Cookies.set('data', data)
	Cookies.set('no', useNo)
}

export function getCookie(name) {
	const value = Cookies.get(name)
	return value
}

export function setToken(value) {
	Cookies.set('token', value)
}

export function setRequestId(id) {
	// request_id 每5分鐘更新一次
	Cookies.set('rid', id, { expires: 5 / (24 * 60) })
}

export function setLocationId(id) {
	Cookies.set('_l', id)
}
