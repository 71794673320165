import React, { useCallback, useContext, useState } from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import Select from 'react-select'
import styled from 'styled-components'
import { storeSalesContext } from './useContext/createContext'
import { FormSubmitButton } from 'utils/StyledButton'
import { AiOutlineSearch } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import useGetLocationSelect from 'hook/saleTable/useGetLocationSelect'
const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0 1em 0 0;
	border-radius: 5px;
	align-items: flex-stretch;
	margin-bottom: 1em;
	position: relative;
`

const StyledTitle = styled(Box)`
	position: relative;
	padding-left: 0.8em;
	font-size: 1.25em;
	font-weight: 700;
	overflow: hidden;
	border-radius: 5px;
	display: flex;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
		top: 0;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
`

export default function TableTopBar() {
	const { setEditState, editState } = useContext(storeSalesContext)
	const [locationIds, setLocationIds] = useState([])
	const { handleSubmit, register } = useForm()
	const [locationData] = useGetLocationSelect()

	function onSubmit(formData) {
		if (locationIds.length > 0) {
			let locationIdsStr = locationIds.map((item) => item.value).join(',')
			setEditState((pre) => (pre = { ...pre, date: formData.date, locationIds: locationIdsStr }))
		} else {
			// fire a toast which reports no ids selected
		}
	}

	const optionArray = useCallback(() => ArrayFilter(locationData), [locationData])

	function ArrayFilter(arr) {
		let filteredArr = []
		arr.forEach((item) => {
			if (item.is_on_sale_order === 1) {
				filteredArr.push({ value: item.id, label: item.name })
			}
		})
		return filteredArr
	}
	return (
		<StyledTopbar className="topbar">
			<StyledTitle>通路銷貨報表</StyledTitle>

			{editState.editType === 'viewList' ? (
				<Box d="flex" my="0.5rem">
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Box mr="1em">
							<Select
								styles={{ container: (provided) => ({ ...provided, minWidth: 200 }) }}
								options={optionArray()}
								closeMenuOnSelect={false}
								isMulti
								value={locationIds}
								placeholder="請選擇通路"
								onChange={(value) => {
									//最多選三個location id
									setLocationIds((pre) => {
										if (value.length > 3) {
											value.shift()
										}
										return value
									})
								}}
							/>
						</Box>
						<Input w="170px" type="date" {...register('date.startDate')} isRequired />
						<Box mx=".5em">到</Box>
						<Input mr="1em" w="170px" type="date" {...register('date.endDate')} isRequired />
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">搜尋</Text>
						</FormSubmitButton>
					</StyledForm>
				</Box>
			) : null}
			{/* {editState.editType === 'print' ? (
				<StyledButtonBox onClick={() => window.print()}>
					<MdPrint /> 列印
				</StyledButtonBox>
			) : null} */}
		</StyledTopbar>
	)
}
