import { PaymentService } from 'Api/payment/paymentService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { success, warning } from 'reducers/toast'

export default function useGetCreateOption(editState) {
	const [optionData, setOptionData] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()

	async function fetchData() {
		const paymentService = new PaymentService()
		const getListData = await paymentService.getPurchaseOptionByDateAndPhone(editState.date, editState.vendorPhone)
		const { status, data } = getListData

		switch (status) {
			case 200:
				setOptionData({ data: data.response, isFetching: false })
				dispatch(success({ message: data.message, status: status, code: data.code }))
				break
			case 400:
				setOptionData({ data: {}, isFetching: false })
				dispatch(warning({ message: data.message, code: data.code }))
				break
			default:
				setOptionData({ data: {}, isFetching: false })
				break
		}
	}
	useEffect(() => {
		if (editState.editType === 'viewList') setOptionData({ data: {}, isFetching: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])

	useEffect(() => {
		if (optionData.isFetching && editState.editType === 'create') {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionData.isFetching, editState])

	return [optionData, setOptionData]
}
