import { Box, Input } from '@chakra-ui/react'
import React from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'
import { FormSubmitButton } from 'utils/StyledButton'
import StyledFormLabel from 'utils/StyledFormLabel'
import PropTypes from 'prop-types'
import { ReturnService } from 'Api/return/returnService'

AddProductForm.propTypes = {
	fetchProductCallback: PropTypes.func.isRequired,
}

export default function AddProductForm({ fetchProductCallback }) {
	const { handleSubmit, register } = useForm()
	const dispatch = useDispatch()
	const focusRef = useRef()

	async function onSubmit(formData) {
		const returnService = new ReturnService()
		const result = await returnService.getStockReturnProduct(formData.product_id)

		const { data, status } = result

		switch (status) {
			case 200:
				fetchProductCallback(data?.response)
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code }))
				break
			default:
				dispatch(error({ message: data.message, code: data.code }))
				break
		}

		focusRef.current.focus()
		focusRef.current.value = ''
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<StyledFormLabel tag="調撥商品" />
			<Box d="flex">
				<Input id="increaseInput" placeholder="請刷條碼" maxW="170px" mr="1em" {...register('product_id')} isRequired ref={focusRef} />
				<FormSubmitButton type="submit">下一筆</FormSubmitButton>
			</Box>
		</form>
	)
}
