import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import { FaCashRegister } from 'react-icons/fa'
import User from './User'
import { StyledButtonBox } from 'utils/StyledButton'
import { useDispatch, useSelector } from 'react-redux'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { toggle } from 'reducers/sidebarShrink'
import { useHistory } from 'react-router-dom'

const StyledTopBar = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0.5em 1em;
	box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.25);
	align-items: center;
	min-width: calc(100vw - 150px);
`

export default function TopBar() {
	const dispatch = useDispatch()
	const history = useHistory()
	const isShrink = useSelector((state) => state.sidebarShrink)

	function createSaleOrder() {
		const { pathname } = window.location
		// eslint-disable-next-line no-unused-vars
		const [_, tableName] = pathname.split('/admin/sale/')
		const isSaleOrder = tableName === 'saleOrder'

		if (!isSaleOrder) {
			history.push('/admin/sale/saleOrder')
		}

		// 跳轉到saleOrder頁面之後自動點擊新增銷貨單按鈕
		setTimeout(function () {
			let createButton = document.querySelector('.createSaleOrder')
			if (createButton) {
				createButton.click()
			}
		}, 500)
	}
	return (
		<StyledTopBar id="topbar">
			<Box fontSize="1.5rem" onClick={() => dispatch(toggle())}>
				{isShrink ? <IoIosArrowForward /> : <IoIosArrowBack />}
			</Box>
			<Box display="flex">
				<StyledButtonBox mr="1em" onClick={createSaleOrder}>
					<FaCashRegister />
					<Text ml="5px">結帳</Text>
				</StyledButtonBox>
				<User />
			</Box>
		</StyledTopBar>
	)
}
