import { createSlice } from '@reduxjs/toolkit'

export const deleteDataSlice = createSlice({
	name: 'deleteData',
	initialState: {
		id: '',
		callback: '',
		payload: '',
		isOpen: false,
	},
	reducers: {
		deleteData: (state, action) => {
			const { payload } = action

			if (payload.id) {
				return { ...state, id: payload.id, payload: payload.payload, callback: payload.callback }
			}

			if (!payload.id) {
				return {
					id: '',
					callback: '',
					payload: '',
				}
			}
		},
	},
})

export const { deleteData } = deleteDataSlice.actions
export default deleteDataSlice.reducer
