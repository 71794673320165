import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

export default function DisplayTable({ products }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>商品編號</td>
						<td>品名</td>
						<td>類別</td>
						<td>品牌名</td>
						<td>顏色</td>
						<td>尺寸</td>
						<td>狀況</td>
						<td>售價</td>
					</tr>
				</thead>
				<tbody>
					{products.length > 0
						? products.map((item, i) => (
								<tr key={i}>
									<td>{item.product_no}</td>
									<td>{item.product_name}</td>
									<td>{item.product_type}</td>
									<td>{item.product_brand}</td>
									<td>{item.product_color}</td>
									<td>{item.product_size}</td>
									<td>{item.product_condition}</td>
									<td>{item.product_sale_price}</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
