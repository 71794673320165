import TempPurchaseOrdersService from 'Api/tempPurchaseOrder/tempPurchaseOrderService'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'

export default function useGetTempDetailPurchaseOrder(orderId, isForPrint) {
	const [detailList, setDetailList] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setDetailList((pre) => (pre = { ...pre, isFetching: true }))
			const tempPurchaseService = new TempPurchaseOrdersService()
			const result = await tempPurchaseService.getOrderDetail(orderId, isForPrint)
			const { data, status } = result
			switch (status) {
				case 200:
					setDetailList((pre) => (pre = { ...pre, data: data.response }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, status: status, code: data.code }))
					break
				default:
					dispatch(error({ message: data.message, status: status, code: data.code }))
					break
			}
			setDetailList((pre) => (pre = { ...pre, isFetching: false }))
		}

		if (orderId) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId])
	return [detailList]
}
