import React, { useState } from 'react'
import { Modal, Input, Box, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import VendorService from 'Api/vendor/vendorService'
import StyledFormLabel from 'utils/StyledFormLabel'
import CashOrRemitInput from './CashOrRemitInput'
import VendorImageUploader from './VendorImageUploader'

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`

export default function CreateVendorModal({ isopen, onclose }) {
	const { register, handleSubmit, reset, setValue } = useForm()
	const dispatch = useDispatch()

	const [imageSrc, setImageSrc] = useState('')

	useEffect(() => {
		// 確保每次modal打開都是被reset過data
		reset()
		setImageSrc('')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isopen])

	async function onSubmit(data) {
		data.image = imageSrc

		// payment如果是現金就給0
		// 如果是匯款就給1
		if (data.payment === 'cash') data.payment = 0
		if (data.payment === 'remit') data.payment = 1

		const venderService = new VendorService()
		const result = await venderService.createVendor(data)
		switch (result.status) {
			case 200:
				dispatch(success({ message: result.data.message, status: result.status, code: result.data.code }))
				break
			case 400:
				dispatch(warning({ message: result.data.message, status: result.status, code: result.data.code }))
				break
			default:
				dispatch(error({ message: result.data.message, status: result.status }))
				break
		}
		reset()
		onclose()
	}

	return (
		<Modal isOpen={isopen} onClose={onclose}>
			<ModalOverlay />
			<ModalContent maxW="700px">
				<ModalHeader textAlign="center">新增廠商資料</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalBody px="6%">
						<Box d="flex" justifyContent="space-between" flexWrap="wrap">
							<Box>
								<StyledFormLabel tag="廠商電話" isRequired />
								<StyledInput {...register('phone')} placeholder="0987654321" required />
							</Box>
							<Box>
								<StyledFormLabel tag="姓名" isRequired />
								<StyledInput {...register('name')} placeholder="王大明" required />
							</Box>
							<Box>
								{/* API欄位沒變，一樣是line id，但改成紀錄instagram */}
								<StyledFormLabel tag="Instagram" />
								<StyledInput {...register('line')} placeholder="Instagram 帳號" />
							</Box>
						</Box>
						<Box d="flex" justifyContent="space-between">
							<CashOrRemitInput register={register} setValue={setValue} />
							<VendorImageUploader setImageSrc={setImageSrc} imageSrc={imageSrc} />
						</Box>
					</ModalBody>

					<ModalFooter justifyContent="center">
						<StyledButtonBox mr={3} onClick={onclose}>
							取消
						</StyledButtonBox>
						<FormSubmitButton type="submit" bg="white">
							送出
						</FormSubmitButton>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
