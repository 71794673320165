import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure, Button } from '@chakra-ui/react'
import { StyledButtonBox } from 'utils/StyledButton'

export default function SubmitAlert({ submitHandler, handleSubmit, loadingState }) {
	const { isOpen, onClose, onOpen } = useDisclosure()
	return (
		<>
			<StyledButtonBox ml=".5em" onClick={onOpen}>
				建立寄退單
			</StyledButtonBox>
			<AlertDialog isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							系統提醒
						</AlertDialogHeader>

						<AlertDialogBody>確定建單內容嗎?</AlertDialogBody>

						<AlertDialogFooter>
							<Button onClick={onClose}>取消</Button>
							<Button isLoading={loadingState} colorScheme="green" onClick={handleSubmit(submitHandler)} ml={3}>
								確定
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
