import { Box, Input, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useContext } from 'react'
import { saleTableContext } from '../useContext/createContext'
import styled from 'styled-components'
import StyledFormLabel from 'utils/StyledFormLabel'
import { FaRegEdit } from 'react-icons/fa'
const StyledInput = styled(Input)`
	border: none !important;
	padding: 0 !important;
	height: auto !important;
	max-width: 110px !important;
	font-size: 1em !important;
	margin-left: 0.5em;
	line-height: 1em;
	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
`

const AnimatedBox = styled(Box)`
	color: #eb9191;
	cursor: pointer;
	font-size: 1.3rem;
`

export default function Subtotal() {
	const { tableData, discountPrice, setDiscountPrice, setDiscountReason, discountReason } = useContext(saleTableContext)

	function subtotalPriceCalculator(tableDataArray = []) {
		let subtotalPrice = 0
		if (tableDataArray.length > 0) {
			for (let i = 0; i < tableDataArray.length; i++) {
				if (tableDataArray[i].sale_price === '未訂價') {
					subtotalPrice += 0
				}
				if (tableDataArray[i].sale_price !== '未訂價') {
					subtotalPrice += tableDataArray[i].sale_price
				}
			}
		}
		return subtotalPrice
	}

	const subtotal = useMemo(() => {
		return subtotalPriceCalculator(tableData)
	}, [tableData])

	const isShowOriginalPrice = subtotal > 0 && discountPrice > 0

	return (
		<Box display="flex">
			{isShowOriginalPrice && (
				<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em" mr="1.5em">
					原價
					<Box d="flex" alignItems="center" ml="0.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
						$ {subtotal}
					</Box>
				</Box>
			)}
			<Box d="flex" alignItems="flex-end" borderBottom="1px solid" pb=".5em">
				小計
				<Box d="flex" alignItems="center" ml="0.5em" fontSize="2em" color="gray.500" lineHeight="1.3em">
					${' '}
					<StyledInput
						placeholder={subtotal}
						type="number"
						defaultValue={discountPrice}
						onChange={(e) => {
							const value = e.target.value ? parseInt(e.target.value) : undefined
							setDiscountPrice(value)
						}}
						maxLength="7"
					/>
				</Box>
				{discountPrice ? (
					<Popover>
						<PopoverTrigger>
							<AnimatedBox>
								<FaRegEdit />
							</AnimatedBox>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverBody>
								<StyledFormLabel isRequired tag="折扣原因" />
								<Input placeholder="請輸入折扣原因" onChange={(e) => setDiscountReason(e.target.value)} value={discountReason} />
							</PopoverBody>
						</PopoverContent>
					</Popover>
				) : null}
			</Box>
		</Box>
	)
}
