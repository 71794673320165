import { Box, Input, FormLabel, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import styled from 'styled-components'

import ImageUploader from './ImageUploader'
import { merchantContext, selectOptionsContext } from '../../useContext/createContext'
import SingleItemInput from './SingleItemInput'
import TypeSelect from './TypeSelect'
import { useFormContext } from 'react-hook-form'

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 0.7em 0.5em;
	border-radius: 10px;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0.7em 0;
`

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`

const GridBox = styled(Box)`
	width: 100%;
	display: grid;

	grid-template-columns: repeat(4, 25%);

	.photoBox {
		grid-column-start: 4;
		grid-row-start: 2;
		grid-row-end: 4;
	}
	.noteBox {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 3;
	}
`

export default function SingleItem({ order }) {
	const { selectOptionsData } = useContext(selectOptionsContext)
	const { updateData } = useContext(merchantContext)
	const context = useFormContext()
	const { register, setValue } = context

	return (
		<>
			<StyledBg position="relative" overflow="visible">
				<Box d="flex" w="100%" justifyContent="space-between" flexWrap="wrap" padding="3px">
					<GridBox>
						<Box>
							<FormLabel d="flex">商品編號</FormLabel>
							<StyledInput defaultValue={order.no} disabled />
						</Box>
						<Box>
							<FormLabel d="flex">
								品名<Text color="red">*</Text>
							</FormLabel>
							<StyledInput placeholder="必填欄位" {...register('name', { value: order.name })} />
						</Box>
						<Box>
							<FormLabel d="flex">
								類別<Text color="red">*</Text>
							</FormLabel>
							<TypeSelect typeData={selectOptionsData?.types || []} order={order} />
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								品牌名<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput type="brand_id" typeData={selectOptionsData?.brands || []} order={order} />
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								顏色<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput type="color_id" typeData={selectOptionsData?.colors || []} order={order} />
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								尺寸<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput type="size_id" typeData={selectOptionsData?.sizes || []} order={order} />
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								狀況<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput type="condition_id" typeData={selectOptionsData?.conditions || []} order={order} />
						</Box>
						<Box className="photoBox">
							<FormLabel d="flex">
								照片<Text color="red">*</Text>
							</FormLabel>
							<ImageUploader updateData={updateData} register={register} merchantListData={order} setValue={setValue} />
						</Box>
						<Box>
							<FormLabel d="flex">
								進價<Text color="red">*</Text>
							</FormLabel>
							<StyledInput defaultValue={order.price} disabled />
							{/* <StyledInput
								placeholder="必填欄位"
								{...register('price', { value: order.price })}
								onChange={(e) => setMerchantData((pre) => (pre = { ...pre, price: e.target.value }))}
							/> */}
						</Box>
						<Box>
							<FormLabel d="flex">
								售價<Text color="red">*</Text>
							</FormLabel>
							<StyledInput placeholder="必填欄位" defaultValue={order.sale_price} disabled />
						</Box>
						<Box>
							<FormLabel d="flex">備註</FormLabel>
							<Input w="80%" {...register('notes', { value: order.notes })} />
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								商品狀態<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="status"
								typeData={[
									{ id: 0, name: '在庫' },
									{ id: 1, name: '售出' },
								]}
							/>
						</Box>
						<Box>
							<FormLabel d="flex">
								付款狀態<Text color="red">*</Text>
							</FormLabel>
							<StyledInput placeholder="必填欄位" defaultValue={order.payment_status} disabled />
						</Box>
					</GridBox>
				</Box>
			</StyledBg>
		</>
	)
}
