import { createSlice } from '@reduxjs/toolkit'

export const punchSlice = createSlice({
	name: 'isPunched',
	initialState: '',
	reducers: {
		punchIn: (state, action) => {
			return 'punchIn'
		},
	},
})

export const { punchIn } = punchSlice.actions
export default punchSlice.reducer
