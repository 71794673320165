import LoginPage from 'pages/LoginPage'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AdminRoute from 'authRoute/AdminRoute'
import ToastManager from 'components/toastManager/ToastManager'
import DeleteDataAlert from 'utils/DeleteDataAlert'
import VendorPage from 'pages/VendorPage'
import NotFoundPage from 'pages/NotFoundPage'

function App() {
	// 這段code是用來handle 廠商QRCode的舊版hash router，要導轉到新版browser router
	const isIndexRoute = window.location.pathname === '/'
	const isVendorQrCodeLegacyHashRoute = window.location.hash.includes('#/vendor?code=')
	const vendorToken = window.location.hash.replace('#/vendor?code=', '')

	if (isIndexRoute && isVendorQrCodeLegacyHashRoute) {
		window.location.assign(`/vendor?code=${vendorToken}`)
		return ''
	}

	return (
		<>
			<Router>
				<ToastManager />
				<DeleteDataAlert />
				<Switch>
					<Route path="/admin">
						<AdminRoute />
					</Route>
					<Route exact path="/login" component={LoginPage} />
					<Route exact path="/vendor" component={VendorPage} />

					{/* index route 要自動導到login頁面 */}
					<Redirect exact from="/" to="/login" />
					<Route path="*" component={NotFoundPage} />
				</Switch>
			</Router>
		</>
	)
}

export default App
