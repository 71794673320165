import Axios from 'Api/interceptors'

export class ReturnService {
	async getStockReturnList(currentPage) {
		let result
		try {
			result = await Axios.get('/inStoreReturnOrders?page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getStockReturnListByDate(startDate, endDate, currentPage) {
		let newStartDate = startDate.split('-').join('/')
		let newEndDate = endDate.split('-').join('/')
		let result
		try {
			result = await Axios.get('/inStoreReturnOrders?start_date=' + newStartDate + '&end_date=' + newEndDate + '&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}
	async getStockReturnProduct(productId) {
		let result
		try {
			result = await Axios.get(`/inStoreReturnOrders/${productId}/product`)
		} catch (error) {
			result = error
		}
		return result
	}
	async getSaleReturnProduct(productId) {
		let result
		try {
			result = await Axios.get(`/saleReturnOrders/${productId}/product`)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSaleReturnList(currentPage) {
		let result
		try {
			result = await Axios.get('/saleReturnOrders?page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSaleReturnListByDate(startDate, endDate, currentPage) {
		let newStartDate = startDate.split('-').join('/')
		let newEndDate = endDate.split('-').join('/')
		let result
		try {
			result = await Axios.get('/saleReturnOrders?start_date=' + newStartDate + '&end_date=' + newEndDate + '&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async createStockReturnSheet(payload) {
		let result
		try {
			result = await Axios.post('/inStoreReturnOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async createSaleReturnSheet(payload) {
		let result
		try {
			result = await Axios.post('/saleReturnOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSaleReturnDetail(id) {
		let result
		try {
			result = await Axios.get('/saleReturnOrders/' + id)
		} catch (error) {
			result = error
		}
		return result
	}

	async getStockReturnDetail(id) {
		let result
		try {
			result = await Axios.get('/inStoreReturnOrders/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}

export const getStockReturnList = (params) => () => {
	const newParams = new URLSearchParams(params).toString()

	return Axios.get(`/inStoreReturnOrders?${decodeURIComponent(newParams)}`)
}
