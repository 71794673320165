import { FormLabel, Input, Radio, RadioGroup } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export default function StoreOrStockRadioGroup({ register, editState, setValue, onOpen }) {
	const [radioValue, setRadioValue] = useState('倉庫')

	//這個MODAL處理兩個狀態
	//一個是編輯表單，editState有值
	//一個是新增表單，editState沒有值
	useEffect(() => {
		// reset表單為預設的倉庫
		// 以防從編輯完種類為通路的表單，直接過來新增表單，預設會變成通路
		if (!editState.is_on_sale_order) setRadioValue('倉庫')
		if (editState.id) {
			onOpen()

			if (editState.is_on_sale_order === 0) setRadioValue('倉庫')
			if (editState.is_on_sale_order === 1) setRadioValue('通路')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])

	return (
		<>
			<FormLabel>種類</FormLabel>
			<Input {...register('is_on_sale_order', { value: radioValue })} d="none" />
			<RadioGroup
				onChange={(value) => {
					setValue('is_on_sale_order', value)
					setRadioValue(value)
				}}
				value={radioValue}
				required
			>
				<Radio value="倉庫" mr="1em" isDisabled={editState.editType === 'delete' ? true : false}>
					倉庫
				</Radio>
				<Radio value="通路" isDisabled={editState.editType === 'delete' ? true : false}>
					通路
				</Radio>
			</RadioGroup>
		</>
	)
}
