import Axios from 'Api/interceptors'

export default class SaleService {
	async getSaleOrderList(currentPage) {
		let result
		try {
			result = await Axios.get('/saleOrders?page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSaleOrderListByDate(startDate, endDate, currentPage) {
		let newStartDate = startDate.split('-').join('/')
		let newEndDate = endDate.split('-').join('/')
		let result
		try {
			result = await Axios.get('/saleOrders?start_date=' + newStartDate + '&end_date=' + newEndDate + '&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async createSaleOrder(payload) {
		let result
		try {
			result = await Axios.post('/saleOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}
	async getOrderDetail(id) {
		let result
		try {
			result = await Axios.get('/saleOrders/' + id)
		} catch (error) {
			result = error
		}
		return result
	}
}
