import Axios from 'Api/interceptors'

export class TypeService {
	async createType(payload) {
		let result
		try {
			result = await Axios.post('/types', payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async updateType(id, payload) {
		let result
		try {
			result = await Axios.put('/types/' + id, payload)
		} catch (error) {
			result = error
		}
		return result
	}

	async deleteType(id) {
		let result
		try {
			result = await Axios.delete('/types/' + id)
		} catch (error) {
			result = error
		}
		return result
	}

	async getSubtypeList(mainTypeId) {
		let result
		try {
			result = await Axios.get('/subtypes/' + mainTypeId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getOneType(mainTypeId) {
		let result
		try {
			result = await Axios.get('/types/' + mainTypeId)
		} catch (error) {
			result = error
		}
		return result
	}
}
