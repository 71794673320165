import Axios from 'Api/interceptors'

export default class TransferService {
	async getTransferOrders(currentPage) {
		let result
		try {
			result = await Axios.get('/transferOrders?&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async getTransferOrderById(orderId) {
		let result
		try {
			result = await Axios.get('/transferOrders/' + orderId)
		} catch (error) {
			result = error
		}
		return result
	}

	async getTransferOrdersByDate(startDate, endDate, currentPage) {
		let newStartDate = startDate.split('-').join('/')
		let newEndDate = endDate.split('-').join('/')
		let result
		try {
			result = await Axios.get('/transferOrders?start_date=' + newStartDate + '&end_date=' + newEndDate + '&page=' + currentPage)
		} catch (error) {
			result = error
		}
		return result
	}

	async createTransferSheet(payload) {
		let result
		try {
			result = await Axios.post('/transferOrders', payload)
		} catch (error) {
			result = error
		}
		return result
	}
}
