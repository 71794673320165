import { Box, Input, FormLabel, Text } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { FiChevronDown } from 'react-icons/fi'
import { BiNoEntry } from 'react-icons/bi'
import ImageUploader from './ImageUploader'
import { selectOptionsContext } from '../../useContext/createContext'
import SingleItemInput from './SingleItemInput'
import TypeSelect from './TypeSelect'

const StyledBg = styled(Box)`
	width: 100%;
	display: flex;
	padding: 0.7em 0.5em;
	border-radius: 10px;
	${'' /* background-color: #f9f9f9; */}
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0.7em 0;
	background: #f0f5ff;
`

const StyledInput = styled(Input)`
	width: 180px !important;
	margin-bottom: 2.8em;
`

const GridBox = styled(Box)`
	width: 100%;
	display: grid;

	grid-template-columns: repeat(4, 25%);

	.photoBox {
		grid-column-start: 4;
		grid-row-start: 2;
		grid-row-end: 4;
	}
	.noteBox {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 3;
	}
`

export default function SingleItem({ order, setMerchantListData, merchantListData }) {
	const [toggleState, setToggleState] = useState(false)
	const { selectOptionsData, updateData } = useContext(selectOptionsContext)

	return (
		<>
			<StyledBg
				position="relative"
				maxH={merchantListData.length - 1 > order && !toggleState ? '0' : '2000px'}
				// transition="all 5s ease"
				minH="3em"
				overflow={merchantListData.length - 1 > order && !toggleState ? 'hidden' : 'visible'}
			>
				<Box
					d={merchantListData.length - 1 > order ? 'flex' : 'none'}
					justifyContent="space-between"
					alignItems="center"
					position="absolute"
					// bgColor="gray.600"
					bgColor="#2b64d0"
					left="0"
					right="0"
					top="0"
					minH="3em"
					borderRadius="10px"
					px=".5em"
					cursor="pointer"
					// _hover={{ bgColor: 'gray.700' }}
					_hover={{ bgColor: '#2250a6' }}
					transition="all .2s ease"
					opacity="0.9"
					pl="2em"
				>
					<Box
						position="absolute"
						zIndex="10"
						color="red"
						onClick={() => {
							if (merchantListData.length > 0) {
								setToggleState((pre) => (pre = false))
								setMerchantListData((pre) => (pre = pre.filter((item, i) => i !== order)))
							}
						}}
						fontSize="2em"
						left=".2em"
					>
						<BiNoEntry />
					</Box>
					<Box
						d="flex"
						justifyContent="space-between"
						alignItems="center"
						w="100%"
						h="3em"
						onClick={() => {
							setToggleState((pre) => !pre)
						}}
					>
						<Box color="white" d="flex" alignItems="center">
							<Box mx="1em">{order + 1}</Box>
							<Box mr="1em">{merchantListData[order].name ? merchantListData[order].name : '商品名稱'}</Box>
							<Box>{merchantListData[order].price ? merchantListData[order].price : '商品價格'}</Box>
						</Box>
						<Box color="white" w="20%" d="flex" justifyContent="flex-end" fontSize="1.5em">
							<FiChevronDown />
						</Box>
					</Box>
				</Box>
				<Box
					d="flex"
					w="100%"
					justifyContent="space-between"
					flexWrap="wrap"
					mt={merchantListData.length - 1 > order ? '3em' : null}
					padding="3px"
				>
					<GridBox>
						<Box>
							<FormLabel d="flex">商品編號</FormLabel>
							<StyledInput
								placeholder="系統預設"
								name="merchantID"
								onChange={(e) => {
									updateData(e.target.name, e.target.value, order)
								}}
								value={merchantListData[order].merchantID || ''}
								disabled
							/>
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								品牌名<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="brand_id"
								tempDataId={merchantListData[order].brand_id}
								typeData={selectOptionsData.data.brands || []}
								order={order}
							/>
						</Box>
						<Box>
							<FormLabel d="flex">
								品名<Text color="red">*</Text>
							</FormLabel>
							<StyledInput
								placeholder="必填欄位"
								name="name"
								onChange={(e) => {
									updateData(e.target.name, e.target.value, order)
								}}
								value={merchantListData[order].name || ''}
							/>
						</Box>
						<Box>
							<FormLabel d="flex">
								類別<Text color="red">*</Text>
							</FormLabel>
							<TypeSelect tempTypeValue={merchantListData[order].type_id || ''} tempSubTypeValue={merchantListData[order].subtype_id || ''} typeData={selectOptionsData.data.types || []} order={order} />
						</Box>

						<Box pb="2.8em">
							<FormLabel d="flex">
								顏色<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="color_id"
								tempDataId={merchantListData[order].color_id}
								typeData={selectOptionsData.data.colors || []}
								order={order}
							/>
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								尺寸<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="size_id"
								tempDataId={merchantListData[order].size_id}
								typeData={selectOptionsData.data.sizes || []}
								order={order}
							/>
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								狀況<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="condition_id"
								tempDataId={merchantListData[order].condition_id}
								typeData={selectOptionsData.data.conditions || []}
								order={order}
							/>
						</Box>
						<Box className="photoBox">
							<FormLabel d="flex">照片</FormLabel>
							<ImageUploader tempPhoto={merchantListData[order].image} updateData={updateData} order={order} />
						</Box>
						<Box>
							<FormLabel d="flex">
								進價<Text color="red">*</Text>
							</FormLabel>
							<StyledInput
								placeholder="必填欄位"
								value={merchantListData[order].price || ''}
								onChange={(e) => updateData('price', e.target.value, order)}
							/>
						</Box>
						<Box pb="2.8em">
							<FormLabel d="flex">
								類型<Text color="red">*</Text>
							</FormLabel>
							<SingleItemInput
								type="type"
								tempDataId={merchantListData[order].type}
								typeData={[
									{ id: 0, name: '寄賣' },
									{ id: 1, name: '買斷' },
								]}
								order={order}
							/>
						</Box>

						<Box className="noteBox" w="100%">
							<FormLabel d="flex">備註</FormLabel>
							<Input w="80%" value={merchantListData[order].notes || ''} onChange={(e) => updateData('notes', e.target.value, order)} />
						</Box>
					</GridBox>
				</Box>
			</StyledBg>
		</>
	)
}
