import { Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { getRePurchaseOrderList } from 'Api/rePurchaseOrder'
import BasicTable from 'components/basicTable'
import GeneralPagination from 'components/pagination'
import React from 'react'
import { useHistory } from 'react-router-dom'

function ListPage() {
	const history = useHistory()
	const [page, setPage] = React.useState(1)
	const { data, isFetching } = useQuery({ queryKey: ['getRePurchaseOrderList', page], queryFn: getRePurchaseOrderList(page) })
	const listData = data?.data?.response?.data || []
	const maxPage = data?.data?.response?.total_page || 0

	const columns = React.useMemo(
		() => [
			{
				Header: '經辦人員',
				accessor: 'user_name',
			},
			{
				Header: '建立時間',
				accessor: 'date',
			},
			{
				Header: '退貨單號',
				accessor: 'in_store_return_order',
				Cell: ({ value }) => {
					return (
						<Box
							cursor="pointer"
							textDecoration="underline"
							onClick={() => {
								history.push('/admin/return/stockReturn/order/' + value?.id)
							}}
						>
							{value?.order_no}
						</Box>
					)
				},
			},
			{
				Header: '進貨單號',
				accessor: 'purchase_order',
				Cell: ({ value }) => {
					return (
						<Box
							cursor="pointer"
							textDecoration="underline"
							onClick={() => {
								history.push('/admin/purchase/purchaseSheet/order/' + value?.id)
							}}
						>
							{value?.purchase_order_no}
						</Box>
					)
				},
			},
		],
		[history]
	)

	return (
		<>
			<BasicTable tableData={listData} columns={columns} isLoading={isFetching} />
			<GeneralPagination
				defaultCurrent={page}
				total={maxPage * 10}
				onChange={(page) => {
					setPage(() => page)
				}}
			/>
		</>
	)
}

export default ListPage
