import React, { useState } from 'react'
import { Box, Input, FormControl, FormLabel, Spinner, Button, Stack, Image, Select } from '@chakra-ui/react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import logoIcon from 'assets/images/logo.svg'
import { login } from 'reducers/auth'
import { useDispatch, useSelector } from 'react-redux'
import loginBg from 'assets/images/loginBg.png'
import { error, success, warning } from 'reducers/toast'
import AuthService from 'Api/authService'
import useGetLoginLocation from 'hook/useGetLoginLocation'
import { setLocationId } from 'utils/cookies'
import { Redirect } from 'react-router-dom'

const StyledLoginPage = styled(Box)`
	width: 100vw;
	min-height: 100vh;
	padding: 2em 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`

const StyledForm = styled.form`
	max-width: 95%;
	text-align: center;
	background-color: white;
	padding: 2em 5% 3em;
	box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
`

const StyledStack = styled(Stack)`
	padding: 1em 0em;
`

const StyledFormControl = styled(FormControl)`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #76a5d1;
	padding: 0 1em;
	color: #76a5d1;
	margin-bottom: 3em !important;
	position: relative;
`

const StyledInput = styled(Input)`
	border: none !important;
	outline: none !important;

	&:focus {
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
`

export default function LoginPage() {
	const { isAuth } = useSelector((state) => state.authState)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [locationOptions] = useGetLoginLocation()

	const onSubmit = async (formData) => {
		setIsLoading((pre) => true)
		const authService = new AuthService()
		const result = await authService.login(formData)
		setIsLoading((pre) => false)
		switch (result.status) {
			case 200:
				let userName = result.data.response.user.name
				dispatch(success({ message: userName + '歡迎回來', code: result.data.code }))
				dispatch(login(result))
				setLocationId(formData.location_id)
				break
			case 400:
				dispatch(warning({ message: result?.data?.message || '帳號或密碼錯誤', code: result.data.code }))
				break
			default:
				dispatch(error({ message: result.data.message, status: result.status }))
				break
		}
	}

	if (isAuth) {
		return <Redirect to="/admin" />
	}

	return (
		<StyledLoginPage backgroundImage={`url(${loginBg})`}>
			<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Image w="80%" maxW="200px" src={logoIcon} d="inline-block" mb="3em" />
				<StyledStack alignItems="center">
					<StyledFormControl id="number">
						<FormLabel whiteSpace="nowrap" my="auto">
							請輸入帳號
						</FormLabel>
						<StyledInput type="text" placeholder="員工編號" {...register('user_no', { required: true })} />
						<Box w="100%" color="red" left={0} position="absolute" top="100%">
							{errors.number?.type === 'required' && '請輸入你的帳號'}
						</Box>
					</StyledFormControl>

					<StyledFormControl id="password">
						<FormLabel whiteSpace="nowrap" my="auto">
							請輸入密碼
						</FormLabel>
						<StyledInput type="password" placeholder="密碼" {...register('password', { required: true })} />
						<Box w="100%" color="red" left={0} position="absolute" top="100%">
							{errors.password?.type === 'required' && '請輸入你的密碼'}
						</Box>
					</StyledFormControl>
					<Box mb="3em !important" position="relative">
						<Select placeholder="請選擇登入位置" {...register('location_id', { required: true })}>
							{locationOptions.map((item, i) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>
						<Box w="100%" color="red" left={0} position="absolute" top="100%">
							{errors.location?.type === 'required' && '請選擇您的登入位置'}
						</Box>
					</Box>

					<Button
						type="submit"
						bgColor="#94BADD"
						color="white"
						_hover={{ bgColor: '#3c75ac' }}
						_active={{ bgColor: '#3c75ac' }}
						padding="0 4.2em"
						height="3.2em"
					>
						{isLoading ? <Spinner /> : '登入'}
					</Button>
				</StyledStack>
			</StyledForm>
		</StyledLoginPage>
	)
}
