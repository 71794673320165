import { configureStore } from '@reduxjs/toolkit'
import authStateReducer from 'reducers/auth'
import toastReducer from 'reducers/toast'
import deleteDataReducer from 'reducers/deleteData'
import fullScreen from 'reducers/fullScreen'
import punch from 'reducers/punch'
import sidebarShrink from 'reducers/sidebarShrink'

const sidebarMiddleware =
	({ getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case 'sidebar/setRoute':
				next(action)
				break
			case 'sidebar/toggle':
				next(action)
				break
			case 'auth/login':
				next(action)
				break
			default:
				next(action)
				break
		}
	}

const store = configureStore({
	reducer: {
		//管理login/logout
		authState: authStateReducer,
		//統一管理狀態更新的toast
		toastManager: toastReducer,
		//統一管理刪除ALERT的PAYLOAD
		deleteData: deleteDataReducer,
		//管理layout ui是否要為全螢幕
		fullState: fullScreen,
		punch: punch,
		// 管理sidebar是否shrink
		sidebarShrink: sidebarShrink,
	},
	middleware: [sidebarMiddleware],
})

export default store
