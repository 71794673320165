import { Box } from '@chakra-ui/layout'
import React from 'react'
import styled from 'styled-components'
const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`
export default function MaintypeTable({ pieData }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>主類別名稱</td>
						<td>主類別備註</td>
						<td>主類別數量</td>
					</tr>
				</thead>
				<tbody>
					{pieData.map((item, i) => (
						<tr key={i}>
							<td>{item.type}</td>
							<td>{item.notes}</td>
							<td>{item.count}</td>
						</tr>
					))}
				</tbody>
			</table>
		</StyledTable>
	)
}
