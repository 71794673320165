import React from 'react'
import { Box, Text, Input } from '@chakra-ui/react'
import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdPrint } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const StyledTopbar = styled(Box)`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	padding: 0.5em 1em;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 1em;
	position: relative;
	overflow: hidden;
	@media print {
		display: none;
	}
	&::before {
		content: '';
		position: absolute;
		left: 0%;
		width: 1px;
		height: 100%;
		border-left: 3px solid #b6d3ff;
		border-radius: 20 px;
	}
`

const StyledForm = styled.form`
	display: flex;
	align-items: center;
	margin-right: 2.5em;
`

TableTopBar.propTypes = {
	pageState: PropTypes.oneOf(['viewList', 'create', 'print']),
	setDate: PropTypes.func,
}

export default function TableTopBar({ pageState, setDate }) {
	const history = useHistory()

	const { handleSubmit, register } = useForm()

	function onSubmit(formData) {
		const { startDate, endDate } = formData
		setDate(startDate.replaceAll('-', '/'), endDate.replaceAll('-', '/'))
	}
	return (
		<StyledTopbar className="topbar">
			<Box fontSize="1.25em" fontWeight="700" d="flex">
				寄賣退貨
				{pageState === 'create' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						新增寄退單
					</Box>
				) : null}
				{pageState === 'print' ? (
					<Box d="flex" alignItems="center">
						<Box mx=".5em">
							<MdKeyboardArrowRight size="1.5em" />
						</Box>
						寄退單詳情
					</Box>
				) : null}
			</Box>
			{pageState === 'viewList' ? (
				<Box d="flex">
					<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Input minW="160px" type="date" {...register('startDate')} isRequired />
						<Box mx=".5em">到</Box>
						<Input mr="1em" minW="160px" type="date" {...register('endDate')} isRequired />
						<FormSubmitButton flexShrink="0" type="submit">
							<AiOutlineSearch />
							<Text ml="5px">搜尋</Text>
						</FormSubmitButton>
					</StyledForm>
					<StyledButtonBox
						className="createSaleOrder"
						onClick={() => {
							history.push('/admin/return/stockReturn/create')
						}}
					>
						<HiPlusSm />
						新增寄退單
					</StyledButtonBox>
				</Box>
			) : null}
			{pageState === 'print' ? (
				<StyledButtonBox onClick={() => window.print()}>
					<MdPrint /> 列印
				</StyledButtonBox>
			) : null}
		</StyledTopbar>
	)
}
