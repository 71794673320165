import React, { useContext } from 'react'
import { otherTableContext } from 'context/otherTableContext'
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormLabel, Input } from '@chakra-ui/react'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { LocationService } from 'Api/location/locationService'
import styled from 'styled-components'
import StoreOrStockRadioGroup from './StoreOrStockRadioGroup'

const StyledBox = styled(Box)`
	width: 80%;
	margin: 0.5em 0;
`

export default function EditModal() {
	const { isOpen, onClose, editState, onOpen, onToggle, setIsNeedFetchList } = useContext(otherTableContext)
	const { register, handleSubmit, setValue } = useForm()
	const dispatch = useDispatch()

	useEffect(() => {
		if (editState.id) {
			onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editState])

	async function onSubmit(formData) {
		// is_on_sale_order:0 不會顯示在銷貨單選項 => 為倉庫，因為倉庫眾多，不希望客戶在銷售的時候選太多多餘的選項
		// is_on_sale_order:1 會顯示在銷貨單選項 => 為通路
		if (formData.is_on_sale_order === '倉庫') formData.is_on_sale_order = 0
		if (formData.is_on_sale_order === '通路') formData.is_on_sale_order = 1

		const locationService = new LocationService()
		let result
		if (editState.editType === 'edit') {
			result = await locationService.updateLocation(editState.id, formData)
		}

		if (editState.editType === 'create') {
			result = await locationService.createLocation(formData)
		}

		if (editState.editType === 'delete') {
			result = await locationService.deleteLocation(editState.id)
		}

		const { status, data } = result
		switch (status) {
			case 200:
				setIsNeedFetchList(true)
				dispatch(success({ message: data.message, code: data.code, status: status }))
				onToggle()
				break
			case 400:
				dispatch(warning({ message: data.message, code: data.code, status: status }))
				break
			default:
				dispatch(error({ message: data.message, status: status }))
				break
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent py="1em" borderRadius="15px">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<ModalHeader fontSize="2.5em" textAlign="center">
						{editState.editType === 'edit' ? '編輯位置' : null}
						{editState.editType === 'create' ? '新增位置' : null}
						{editState.editType === 'delete' ? '刪除位置' : null}
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody d="flex" justifyContent="center" flexWrap="wrap" px="2em" mb="1em">
						<StyledBox>
							<FormLabel>位置名稱</FormLabel>
							<Input {...register('name', { value: editState.name })} required disabled={editState.editType === 'delete' ? true : false} />
						</StyledBox>

						<StyledBox>
							<FormLabel>地址</FormLabel>
							<Input {...register('address', { value: editState.address })} required disabled={editState.editType === 'delete' ? true : false} />
						</StyledBox>
						<StyledBox>
							<FormLabel>電話</FormLabel>
							<Input {...register('phone', { value: editState.phone })} disabled={editState.editType === 'delete' ? true : false} />
						</StyledBox>

						<StyledBox>
							<FormLabel>說明</FormLabel>
							<Input {...register('notes', { value: editState.notes })} disabled={editState.editType === 'delete' ? true : false} />
						</StyledBox>
						<StyledBox>
							<StoreOrStockRadioGroup register={register} setValue={setValue} editState={editState} onOpen={onOpen} />
						</StyledBox>
					</ModalBody>

					<ModalFooter justifyContent="center">
						<StyledButtonBox mr="1em" onClick={onClose}>
							取消
						</StyledButtonBox>
						<FormSubmitButton bg="white" type="submit">
							確定
						</FormSubmitButton>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
