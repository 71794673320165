import { Box } from '@chakra-ui/react'
import React from 'react'
import { HiDocumentText } from 'react-icons/hi'
import GeneralPagination from 'components/pagination'
import BasicTable from 'components/basicTable'
import { useHistory } from 'react-router-dom'

export default function TableContent({ listData = [], isFetching, goToPage, maxPage, currentPage }) {
	const history = useHistory()

	const columns = React.useMemo(
		() => [
			{
				Header: '日期時間',
				accessor: 'date',
			},
			{
				Header: '寄退單號',
				accessor: 'order_no',
			},
			{
				Header: '寄退地點',
				accessor: 'location',
			},
			{
				Header: '寄退總額',
				accessor: 'price',
			},
			{
				Header: '手續費用',
				accessor: 'fee',
			},
			{
				Header: '管理',
				accessor: 'management',
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box
								color="#91B0EB"
								cursor="pointer"
								onClick={() => {
									history.push(`/admin/return/stockReturn/order/${row.row.original.id}`)
								}}
							>
								<HiDocumentText />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<>
			<BasicTable columns={columns} tableData={listData} isLoading={isFetching} />
			<GeneralPagination defaultCurrent={currentPage} total={maxPage * 10} onChange={goToPage} />
		</>
	)
}
