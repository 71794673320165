import { VendorPageService } from 'Api/vendorPage/vendorPageService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'reducers/toast'

export default function useGetVendorPageData(token) {
	const [vendorData, setVendorData] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setVendorData((pre) => (pre = { ...pre, isFetching: true }))
			const vendorPageService = new VendorPageService()
			const result = await vendorPageService.getVendorPageData(token)
			const { data, status } = result

			switch (status) {
				case 200:
					setVendorData((pre) => (pre = { ...data.response , isFetching: false }))
					break
				case 400:
					setVendorData({ data: {}, isFetching: false })
					dispatch(warning({ message: data.message, code: data.code, status: status }))
					break
				default:
					setVendorData({ data: {}, isFetching: false })
					dispatch(error({ message: data.message, code: data.code, status: status }))
					break
			}
		}
		if (token) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])
	return [vendorData]
}
