import { Box } from '@chakra-ui/react'
import PictureModal from 'components/modals/PictureModal'
import React from 'react'
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import GeneralPagination from 'components/pagination'

export default function VendorProductTable({ tableData }) {
	const data = React.useMemo(() => tableData, [tableData])

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'product_no',
			},
			{
				Header: '品牌',
				accessor: 'brand',
			},
			{
				Header: '品名',
				accessor: 'name',
				Cell: (row) => {
					return <PictureModal imageUrl={row.row.original.image} text={row.value} />
				},
			},
			{
				Header: '位置',
				accessor: 'location_id',
			},
			{
				Header: '商品狀態',
				accessor: 'status',
				Cell: (row) => {
					return (
						<Box style={{ color: row.value === '已售出' ? '#FF0000' : 'black' }}>
							<Box>{row.value}</Box>
							<Box>{row.row.original.sale_date}</Box>
						</Box>
					)
				},
			},
			{
				Header: '商品來源',
				accessor: 'type',
			},
			{
				Header: '進價',
				accessor: 'price',
			},
			{
				Header: '售價',
				accessor: 'sale_price',
				Cell: (row) => {
					return <Box style={{ color: !row.value ? '#FF0000' : 'black' }}>{row.value || '未定價'}</Box>
				},
			},
			{
				Header: '付款狀態',
				accessor: 'payment',
				Cell: (row) => {
					return (
						<Box style={{ color: row.value === '已付款' ? '#FF0000' : 'black' }}>
							<Box>{row.value}</Box>
							<Box>{row.row.original.payment_date}</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		pageCount,
		gotoPage,

		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	)
	return (
		<>
			{tableData.length > 0 ? (
				<>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup, g) => (
								<tr {...headerGroup.getHeaderGroupProps()} key={g}>
									{headerGroup.headers.map((column, k) => {
										return (
											<th key={k}>
												<Box {...column.getHeaderProps(column.getSortByToggleProps())}>
													{
														// Render the header
														column.render('Header')
													}
												</Box>
											</th>
										)
									})}
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()}>
							{
								// Loop over the table rows
								page.map((row, i) => {
									// Prepare the row for display
									prepareRow(row)
									return (
										// Apply the row props
										<tr {...row.getRowProps()} key={i}>
											{
												// Loop over the rows cells
												row.cells.map((cell, j) => {
													// Apply the cell props
													return (
														<td {...cell.getCellProps()} key={j}>
															{
																// Render the cell contents
																cell.render('Cell')
															}
														</td>
													)
												})
											}
										</tr>
									)
								})
							}
						</tbody>
					</table>
					<GeneralPagination defaultCurrent={0} current={pageIndex + 1} total={pageCount * 10} onChange={(page) => gotoPage(page - 1)} />
				</>
			) : (
				<Box fontSize="1.2em" textAlign="center" my="3em">
					目前沒有任何資料載入
				</Box>
			)}
		</>
	)
}
