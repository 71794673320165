import React, { useCallback, useContext } from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import styled, { keyframes } from 'styled-components'
import DisplayTable from './component/DisplayTable'
import { StyledButtonBox } from 'utils/StyledButton'
import { paymentListContext } from './useContext/createContext'
import useGetPaymentDetail from 'hook/paymentTable/useGetPaymentDetail'

const fadeInRight = keyframes`
  0% {
    transform:translate(100%)
  }
  100% {
	transform:translate(0%)
  }
`

const StyledTransitionBg = styled(Box)`
	width: 100%;
	animation: ${fadeInRight} 0.5s ease;
	${'' /* padding: 0 10%; */}

	.info {
		ul {
			list-style: none;
			display: flex;
		}
	}
	@media print {
		& {
			background: white;
			font-size: 12px;

			.success-button {
				display: none !important;
			}

			.precautions {
				display: block;
				padding-bottom: 3em !important;
				width: 100%;

				ul {
					list-style: none;
				}
			}
		}
		@page {
			size: A4 portrait;
		}
	}
`

export default function DisplayContent() {
	const { setEditState, editState } = useContext(paymentListContext)
	const [detail] = useGetPaymentDetail(editState.orderId)

	const totalPrice = useCallback(() => {
		return priceSum(detail.data.vendors)
	}, [detail])

	function priceSum(arr) {
		let sum = 0
		if (arr.length > 0) {
			arr.forEach((item) => (sum += item.price))
		}
		return sum
	}

	return (
		<StyledTransitionBg>
			<Box textAlign="center">
				<Box className="title" fontSize="2em" mb=".7em">
					OPMM付款單
				</Box>
			</Box>
			{detail.isFetching ? (
				<Box py="2em" d="flex" alignItems="center" flexDir="column">
					<Box fontSize="1.5em">載入資料中...</Box>
					<Spinner size="xl" mt="2em" />
				</Box>
			) : (
				<>
					{detail.data.id ? (
						<>
							<Box className="info" mb="1em">
								<Box d="flex" w="600px" justifyContent="space-between" mx="auto">
									<Box>
										<Box>付款單號：{detail.data.payment_order_no}</Box>
										<Box>付款日期：{detail.data.date}</Box>
									</Box>
									<Box>
										<Box>經辦姓名：{detail.data.user}</Box>
										<Box>經辦電話：{detail.data.phone}</Box>
									</Box>
								</Box>
							</Box>
							<DisplayTable vendors={detail.data.vendors || []} />
							<Box d="flex" justifyContent="flex-end" my="2em">
								<Box d="flex" alignItems="flex-end" minW="110px" pb=".5em" fontSize="1.2em">
									總計：{totalPrice()}
								</Box>
							</Box>
						</>
					) : (
						<Box fontSize="1.2em" textAlign="center" my="3em">
							目前沒有任何資料載入
						</Box>
					)}
				</>
			)}

			<Box className="success-button" d="flex" justifyContent="center" my="1em">
				<StyledButtonBox onClick={() => setEditState((pre) => (pre = { ...pre, editType: 'viewList' }))}>完成</StyledButtonBox>
			</Box>

			<Box className="precautions" px="3em" display="none">
				<Box fontSize="1.5em">
					<Box mt="4rem" mb="2rem">
						<Box>現金需在店內當面點清，出店後一概不負責。</Box>
					</Box>
					<Box>簽收人：＿＿＿＿＿＿＿＿＿＿ （___/___/___） </Box>
				</Box>
			</Box>
		</StyledTransitionBg>
	)
}
