import { extendTheme } from '@chakra-ui/react'

const theme = {
	styles: {
		global: {
			'html, body': {
				fontSize: '14px',
				overflowX: 'hidden',
				// overflow: 'scroll',
			},
			'input, select': {
				backgroundColor: 'white !important',
				borderColor: '#AFAFAF !important',
			},
		},
	},
	colors: {
		gray: {
			100: '#f8f8f8',
			600: '#BFBFBF',
			700: '#737373',
		},
	},
}

export default extendTheme(theme)
