import React from 'react'
import PropTypes from 'prop-types'
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
	Button,
} from '@chakra-ui/react'
import { StyledButtonBox } from 'utils/StyledButton'

SubmitAlert.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
}

export default function SubmitAlert({ onConfirm, isLoading = false }) {
	const { isOpen, onClose, onOpen } = useDisclosure()

	const handleClickSave = () => {
		onConfirm()
		onClose()
	}

	return (
		<>
			<StyledButtonBox ml="1rem" onClick={onOpen}>
				建立寄賣轉買斷
			</StyledButtonBox>
			<AlertDialog isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							系統提醒
						</AlertDialogHeader>

						<AlertDialogBody>確定要將列表內的商品從寄賣轉為買斷嗎?</AlertDialogBody>

						<AlertDialogFooter>
							<Button onClick={onClose}>取消</Button>
							<Button isLoading={isLoading} colorScheme="green" onClick={handleClickSave} ml={3}>
								確定
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
