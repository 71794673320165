import React, { useState } from 'react'
import { Modal, Box, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, RadioGroup, Stack, Radio, Image } from '@chakra-ui/react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { error, success, warning } from 'reducers/toast'
import { FormSubmitButton, StyledButtonBox } from 'utils/StyledButton'
import { useEffect } from 'react'
import VendorService from 'Api/vendor/vendorService'
import StyledFormLabel from 'utils/StyledFormLabel'
import { FaRegAddressCard } from 'react-icons/fa'

const StyledBox = styled(Box)`
	width: 180px !important;
	margin-bottom: 2.8em;
	border-bottom: 1px solid gray;
	padding-bottom: 3px;
	min-height: 26px;
	padding-left: 0.5em;
`

export default function VendorModalPortal({ vendorId }) {
	const { isOpen, onClose, onOpen } = useDisclosure()

	return (
		<>
			<StyledButtonBox onClick={onOpen}>廠商資訊</StyledButtonBox>
			{isOpen && <VendorModal isOpen={isOpen} onClose={onClose} vendorId={vendorId} />}
		</>
	)
}

function VendorModal({ isOpen, onClose, vendorId }) {
	const [vendorData, setVendorData] = useState({})
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			const vendorService = new VendorService()
			const result = await vendorService.getSingleVendor(vendorId)
			const { data, status } = result
			switch (status) {
				case 200:
					setVendorData((pre) => (pre = { ...data.response }))
					dispatch(success({ message: data.message, status: status }))
					break
				case 400:
					dispatch(warning({ message: data.message, status: status }))
					break
				default:
					dispatch(error({ message: data.message, status: status }))
					break
			}
		}
		if (vendorId) fetchData()

		return () => setVendorData({})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW="700px">
				<ModalHeader textAlign="center">廠商資料</ModalHeader>
				<ModalCloseButton />
				<ModalBody px="6%">
					<Box d="flex" justifyContent="space-between" flexWrap="wrap">
						<Box>
							<StyledFormLabel tag="廠商電話" />
							<StyledBox placeholder="0987654321">{vendorData.phone || '未提供'}</StyledBox>
						</Box>
						<Box>
							<StyledFormLabel tag="姓名" />
							<StyledBox placeholder="王大明">{vendorData.name || '未提供'}</StyledBox>
						</Box>
						<Box>
							{/* API欄位沒變，一樣是line id，但改成紀錄instagram */}
							<StyledFormLabel tag="Instagram" />
							<StyledBox>{vendorData.line || '未提供'}</StyledBox>
						</Box>
					</Box>
					<Box d="flex" justifyContent="space-between">
						<Box>
							<StyledFormLabel tag="付款方式" />
							<Box d="flex" alignItems="center">
								<RadioGroup value={vendorData.payment} mr="1em" readOnly>
									<Stack direction="row" spacing={3}>
										<Radio value={0}>
											<Box isTruncated>現金</Box>
										</Radio>
										<Radio value={1}>
											<Box isTruncated>匯款</Box>
										</Radio>
									</Stack>
								</RadioGroup>

								<StyledBox w="250px !important" mb="0">
									{!vendorData.code || !vendorData.account ? '未提供帳戶資訊' : `${vendorData.code}-${vendorData.account}`}
								</StyledBox>
							</Box>
						</Box>

						<Box w="180px">
							<Box w="180px" h="100px" borderRadius="15px" position="relative">
								<Box position="absolute" w="100%" h="100%" d="flex" justifyContent="center" alignItems="center" flexDirection="column" color="gray.600">
									{vendorData.image ? (
										<>
											<Image src={vendorData.image} maxH="100%" />
										</>
									) : (
										<>
											<Box fontSize="3em">
												<FaRegAddressCard />
											</Box>
											<Box>未提供照片/身分證</Box>
										</>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</ModalBody>

				<ModalFooter justifyContent="center">
					<FormSubmitButton bg="white" onClick={onClose}>
						完成
					</FormSubmitButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
