import { Box, FormLabel, Input } from '@chakra-ui/react'
import React, { useContext } from 'react'
import AddProductForm from './AddProductForm'
import styled from 'styled-components'
import StyledFormLabel from 'utils/StyledFormLabel'
import { saleTableContext } from '../useContext/createContext'

const StyledBox = styled(Box)`
	border-bottom: 1px solid;
	height: 1.8em;
	min-width: 160px;
	padding: 0 1em;
	border-color: black;
`

const StyledInput = styled(Input)`
	border: none !important;
	padding: 0 !important;
	height: auto !important;
	max-width: 140px !important;
	font-size: 1em !important;
	line-height: 1em;
	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
`

export default function AddSaleSheetHead() {
	const { employeeNo, setEmployeeNo } = useContext(saleTableContext)
	return (
		<Box d="flex" justifyContent="space-between" mb="1.5em" mt="4em">
			<AddProductForm />
			<Box d="flex">
				<Box mr="2em">
					<FormLabel>銷貨單號</FormLabel>
					<StyledBox color="gray.600" fontSize="1.3em">
						(結束後自動產生)
					</StyledBox>
				</Box>
				<Box mr="2em">
					<StyledFormLabel isRequired tag="員工編號" />
					<StyledBox d="flex" alignItems="center" fontSize="1.3em" color="gray.500" lineHeight="1.3em">
						<StyledInput
							placeholder="請輸入員工編號"
							defaultValue={employeeNo}
							onChange={(e) => {
								setEmployeeNo(e.target.value)
							}}
						/>
					</StyledBox>
				</Box>
			</Box>
		</Box>
	)
}
