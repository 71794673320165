import { Box } from '@chakra-ui/react'
import React from 'react'
import { useTable, usePagination, useGlobalFilter } from 'react-table'
import { FaTrashAlt } from 'react-icons/fa'
import { useContext } from 'react'
import { subSaleReturnContext } from '../useContext/createContext'

export default function AddSaleSheet() {
	const { subTableData, setSubTableData } = useContext(subSaleReturnContext)
	const data = React.useMemo(() => subTableData, [subTableData])

	const columns = React.useMemo(
		() => [
			{
				Header: '商品編號',
				accessor: 'product_no',
			},
			{
				Header: '品名',
				accessor: 'name',
			},
			{
				Header: '售出日期',
				accessor: 'date',
			},

			{
				Header: '售價',
				accessor: 'price',
			},
			{
				Header: '付款狀態',
				accessor: 'status',
			},
			{
				Header: '管理',
				accessor: 'management',
				Cell: (row) => {
					return (
						<Box d="flex" justifyContent="space-around">
							<Box
								color="#EB9191"
								cursor="pointer"
								onClick={() => {
									setSubTableData((pre) => {
										let filteredData = pre.filter((item, i) => i !== parseInt(row.row.id))
										return filteredData
									})
								}}
							>
								<FaTrashAlt />
							</Box>
						</Box>
					)
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[subTableData]
	)

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useGlobalFilter,
		usePagination
	)
	return (
		<>
			{subTableData.length > 0 ? (
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup, g) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={g}>
								{headerGroup.headers.map((column, k) => (
									<th key={k}>
										<Box {...column.getHeaderProps()}>
											{
												// Render the header
												column.render('Header')
											}
										</Box>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							// Loop over the table rows
							page.map((row, i) => {
								// Prepare the row for display
								prepareRow(row)
								return (
									// Apply the row props
									<tr {...row.getRowProps()} key={i}>
										{
											// Loop over the rows cells
											row.cells.map((cell, j) => {
												// Apply the cell props
												return (
													<td {...cell.getCellProps()} key={j}>
														{
															// Render the cell contents
															cell.render('Cell')
														}
													</td>
												)
											})
										}
									</tr>
								)
							})
						}
					</tbody>
				</table>
			) : (
				<Box fontSize="1.2em" textAlign="center" my="3em">
					目前沒有任何商品
				</Box>
			)}
		</>
	)
}
