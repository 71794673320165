import { createSlice } from '@reduxjs/toolkit'

export const fullScreenSlice = createSlice({
	name: 'fullScreen',
	initialState: 'normal',
	reducers: {
		full: (state, action) => {
			return 'full'
		},
		normal: (state, action) => {
			return 'normal'
		},
	},
})

export const { full, normal } = fullScreenSlice.actions
export default fullScreenSlice.reducer
