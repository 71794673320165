import { PaymentService } from 'Api/payment/paymentService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { warning } from 'reducers/toast'

export default function useGetPaymentList(editState) {
	const [tableData, setTableData] = useState({ data: [], isFetching: false })
	const [tablePage, setTablePage] = useState({ currentPage: 1, maxPage: 1 })
	const [productListIsNeedFetch, setProductListIsNeedFetch] = useState(true)
	const { currentPage, maxPage } = tablePage
	const dispatch = useDispatch()

	useEffect(() => {
		async function fetchData() {
			setTableData((pre) => (pre = { data: [], isFetching: true }))
			const paymentService = new PaymentService()
			const getListData = await paymentService.getPaymentList(currentPage, editState.date)
			const { status, data } = getListData
			switch (status) {
				case 200:
					setTablePage((pre) => (pre = { ...pre, maxPage: data.response.total_page }))
					setTableData({ data: [...data.response.data], isFetching: false })
					break
				case 400:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setTablePage((pre) => (pre = { currentPage: 1, maxPage: 1 }))
					setTableData({ data: [], isFetching: false })
					break
			}
			setProductListIsNeedFetch(false)
		}
		if (productListIsNeedFetch) fetchData()
		// if (productListIsNeedFetch && editState.editType === 'viewList') fetchData()
		// if (productListIsNeedFetch && editState.editType === 'create') setProductListIsNeedFetch(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productListIsNeedFetch])

	useEffect(() => {
		if (!productListIsNeedFetch) setProductListIsNeedFetch(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	// useEffect(() => {
	// 	if (editState.date && editState.editType !== 'viewList') {
	// 		setProductListIsNeedFetch(true)
	// 		setTablePage({ currentPage: 1, maxPage: 1 })
	// 	}
	// }, [editState])

	const gotoPage = (page) => {
		setTablePage((pre) => (pre = { ...pre, currentPage: page }))
	}
	return [currentPage, maxPage, gotoPage, tableData, setProductListIsNeedFetch]
}
