import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

export default function DisplayTable({ products }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>商品編號</td>
						<td>廠商姓名</td>
						<td>廠商電話</td>
						<td>品名</td>
						<td>寄賣時間</td>
						<td>是否超時</td>
						<td>進價</td>
					</tr>
				</thead>
				<tbody>
					{products.length > 0
						? products.map((item) => (
								<tr key={item.product_no}>
									<td>{item.product_no}</td>
									<td>{item.vendor}</td>
									<td>{item.phone}</td>
									<td>{item.name}</td>
									<td>{item.time}</td>
									<td style={{ color: item.expire === '否' ? '#EB9191' : 'inherit' }}>{item.expire}</td>
									<td>{item.price}</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
