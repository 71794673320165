import Axios from 'Api/interceptors'

export default class ReportService {
	async getStoreSalesChartData(id, date) {
		let result
		let startDate = date.startDate ? date.startDate.replace(/-/gi, '/') : ''
		let endDate = date.endDate ? date.endDate.replace(/-/gi, '/') : ''
		try {
			if (startDate && endDate) result = await Axios.get(`/report/locationSale?location_ids=${id}&start_date=${startDate}&end_date=${endDate}`)
			if (!startDate || !endDate) throw new Error('No Date')
		} catch (error) {
			result = error
		}
		return result
	}

	async getStockSalesChartData(date) {
		let result
		let startDate = date.startDate ? date.startDate.replace(/-/gi, '/') : ''
		let endDate = date.endDate ? date.endDate.replace(/-/gi, '/') : ''
		try {
			if (startDate && endDate) result = await Axios.get(`/report/importSale?start_date=${startDate}&end_date=${endDate}`)
			if (!startDate || !endDate) throw new Error('No Date')
		} catch (error) {
			result = error
		}
		return result
	}

	async getStockCategoryChartData() {
		let result
		try {
			result = await Axios.get(`/report/productType`)
		} catch (error) {
			result = error
		}
		return result
	}

	async getDefaultBrandsSalesData() {
		let result
		try {
			result = await Axios.get('/report/brandOverview')
		} catch (error) {
			result = error
		}
		return result
	}

	async getBrandSalesData(id, date) {
		let result
		let startDate = date.startDate ? date.startDate.replace(/-/gi, '/') : ''
		let endDate = date.endDate ? date.endDate.replace(/-/gi, '/') : ''
		try {
			if (startDate && endDate) result = await Axios.get(`/report/brandSales?brand_ids=${id}&start_date=${startDate}&end_date=${endDate}`)
			if (!startDate || !endDate) throw new Error('No Date')
		} catch (error) {
			result = error
		}
		return result
	}
}
