import React from 'react'
import { Accordion, Box } from '@chakra-ui/react'

import SingleAccordionItem from './SingleAccordionItem'

export default function SingleAccordion({ singlepermission }) {
	return (
		<Box w="25%" minW="290px" mb="1em">
			<Box fontSize="1.43em" color="#8F8F8F">
				{singlepermission.name}
			</Box>
			<Accordion allowToggle>
				{singlepermission.permission_type.map((item, j) => (
					<SingleAccordionItem item={item} key={j} />
				))}
			</Accordion>
		</Box>
	)
}
// onChange={(e) => setpermissionids((pre) => (pre = checkStateToId([...pre], e.target.checked, el.id)))}
// {...register(`permission_ids.checkbox${el.id}`)}
