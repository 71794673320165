import { Box, Image, Text } from '@chakra-ui/react'
import React from 'react'
import logoIcon from 'assets/images/logo.svg'

function NotFoundPage() {
	return (
		<Box w="100vw" h="100vh" display="grid" placeItems="center">
			<Box textAlign="center">
				<Image src={logoIcon} mb="3em" />
				<Text fontSize="2em" fontStyle="oblique" fontWeight="bold">
					404 Not Found
				</Text>
			</Box>
		</Box>
	)
}

export default NotFoundPage
