import { Box } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { storeSalesContext } from './useContext/createContext'
import Chart from 'chart.js/auto'
import TabData from './components/TabData'

export default function ChartContent() {
	const { defaultData, tableData } = useContext(storeSalesContext)

	useEffect(() => {
		// if (pieData.length > 0) {
		// 	maintypePieRenderer(pieData)
		// }
		if (defaultData.count) {
			bestAmountBarRenderer(defaultData.sales_sort)
			bestPriceBarRenderer(defaultData.price_sort)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultData])

	function bestAmountBarRenderer(barData) {
		if (window['bestAmountBar_chart']) {
			window['bestAmountBar_chart'].destroy()
		}

		let tempData = {}
		tempData.label = '銷售數量'
		tempData.backgroundColor = ['rgba(169, 188, 208, .7)', 'rgba(155, 173, 193, .7)', 'rgba(141, 157, 177, .7)', 'rgba(127, 142, 161, .7)', 'rgba(112, 126, 145, .7)']
		tempData.borderColor = ['rgba(169, 188, 208, 1)', 'rgba(155, 173, 193, 1)', 'rgba(141, 157, 177, 1)', 'rgba(127, 142, 161, 1)', 'rgba(112, 126, 145, 1)']
		tempData['data'] = barData['sales']
		window['bestAmountBar_chart'] = new Chart(document.getElementById('bestAmountBar'), {
			type: 'bar',
			data: {
				labels: barData['brand'],
				datasets: [tempData],
			},
			options: {
				indexAxis: 'y',
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: 'Top 5 銷售數量品牌',
					},
				},
			},
		})
	}

	function bestPriceBarRenderer(barData) {
		if (window['bestPriceBar_chart']) {
			window['bestPriceBar_chart'].destroy()
		}

		let tempData = {}
		tempData.label = '銷售總額'
		tempData.backgroundColor = ['rgba(169, 188, 208, .7)', 'rgba(155, 173, 193, .7)', 'rgba(141, 157, 177, .7)', 'rgba(127, 142, 161, .7)', 'rgba(112, 126, 145, .7)']
		tempData.borderColor = ['rgba(169, 188, 208, 1)', 'rgba(155, 173, 193, 1)', 'rgba(141, 157, 177, 1)', 'rgba(127, 142, 161, 1)', 'rgba(112, 126, 145, 1)']
		tempData['data'] = barData['sale_price']
		window['bestPriceBar_chart'] = new Chart(document.getElementById('bestPriceBar'), {
			type: 'bar',
			data: {
				labels: barData['brand'],
				datasets: [tempData],
			},
			options: {
				indexAxis: 'y',
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: 'Top 5 銷售總額品牌',
					},
				},
			},
		})
	}

	return (
		<Box>
			<Box display="flex" justifyContent="center" flexWrap="wrap" mb="2em">
				<Box w="100%" minW="150px" bg="#f9f9f9" p="1rem" borderRadius="10px" mb="1rem">
					<Box w="100%" fontSize="1.5rem">
						目前品牌總數: {defaultData.count}
					</Box>
				</Box>
				<Box minW="400px" maxW="100%" bg="#f9f9f9" p="1rem" mx="1rem" mb="1rem" borderRadius="10px">
					<Box>
						<canvas id="bestAmountBar"></canvas>
					</Box>
				</Box>
				<Box minW="400px" maxW="100%" bg="#f9f9f9" p="1rem" mb="1rem" borderRadius="10px">
					<Box>
						<canvas id="bestPriceBar"></canvas>
					</Box>
				</Box>
			</Box>
			<Box>{tableData.length > 0 ? <TabData tableData={tableData} /> : null}</Box>
		</Box>
	)
}
