import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Box)`
	th,
	td {
		min-width: auto !important;
	}
`

export default function DisplayTable({ products }) {
	return (
		<StyledTable>
			<table>
				<thead>
					<tr>
						<td>商品編號</td>
						<td>品名</td>
						<td>售出時間</td>
						<td>售價</td>
						<td>商品狀態</td>
					</tr>
				</thead>
				<tbody>
					{products.length > 0
						? products.map((item, i) => (
								<tr key={i}>
									<td>{item.product_no}</td>
									<td>{item.name}</td>
									<td>{item.date}</td>
									<td>{item.price}</td>
									<td>{item.status}</td>
								</tr>
						  ))
						: null}
				</tbody>
			</table>
		</StyledTable>
	)
}
