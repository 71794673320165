import { PaymentService } from 'Api/payment/paymentService'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { success, warning } from 'reducers/toast'

export default function useGetPaymentDetail(paymentId) {
	const [detail, setDetailData] = useState({ data: {}, isFetching: false })
	const dispatch = useDispatch()
	useEffect(() => {
		async function fetchData() {
			setDetailData((pre) => (pre = { data: {}, isFetching: true }))
			const paymentService = new PaymentService()
			const getListData = await paymentService.getPaymentDetail(paymentId)
			const { status, data } = getListData

			switch (status) {
				case 200:
					setDetailData({ data: data.response, isFetching: false })
					dispatch(success({ message: data.message, status: status, code: data.code }))
					break
				case 400:
					setDetailData({ data: {}, isFetching: false })
					dispatch(warning({ message: data.message, code: data.code }))
					break
				default:
					setDetailData({ data: {}, isFetching: false })
					break
			}
		}

		if (paymentId) fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentId])

	return [detail]
}
