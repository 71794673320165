import { Box, FormControl, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { priceCommaInserter } from 'utils/priceInsertComma'
import { StyledButtonBox } from 'utils/StyledButton'
import StyledFormLabel from 'utils/StyledFormLabel'

const StyledItem = styled(Box)`
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 0.5em 0;
	margin: 1em;
	margin-top: 0;
	color: #839bbe;
	transition: all 0.2s ease;
	cursor: pointer;

	&:hover {
		background: #e2e8f0;
	}
`

const InfoBox = styled(Box)`
	border-bottom: 1px solid;
	padding-bottom: 0.5rem;
	margin-bottom: 0.5rem;
`

export default function ProductItem({ productInfo }) {
	const { onOpen, isOpen, onClose } = useDisclosure()
	const memoPrice = useMemo(() => {
		if (productInfo.product_price.toString().length >= 4) return priceCommaInserter(productInfo.product_price)
		else {
			return productInfo.product_price.toString()
		}
	}, [productInfo])

	return (
		<>
			<StyledItem onClick={onOpen} textAlign="center">
				<Box w="20%" whiteSpace="nowrap" color={productInfo.product_status === '已售出' ? '#F6ACA7' : 'inherit'}>
					{productInfo.product_status}
					<Box color="red" fontWeight={900}>
						{productInfo.has_payment && '已結款'}
					</Box>
				</Box>
				<Box flexShrink="0" w="25%">
					<Image src={productInfo.product_image ? productInfo.product_image : 'https://fakeimg.pl/100x100/?text=Image&font=lobster'} w="70px" height="70px" />
				</Box>
				<Box w="35%" textAlign="left">
					<Box>品名：</Box>
					<Box color="black"> {productInfo.product_name}</Box>
					{/* <Box mt="0.5rem"> {productInfo.date}</Box> */}
				</Box>
				<Box w="20%">
					<Box>${memoPrice}</Box>
				</Box>
			</StyledItem>
			{isOpen && (
				<Modal isOpen={isOpen} onClose={onClose} isCentered>
					<ModalOverlay />

					<ModalContent>
						<ModalHeader textAlign="center" fontSize="1.5rem" my=".5em" className="modal-header">
							商品資訊
						</ModalHeader>
						<ModalCloseButton className="modal-close" />

						<ModalBody>
							<Stack spacing="5">
								<FormControl>
									<StyledFormLabel tag="進貨時間" />
									<InfoBox>{productInfo.date}</InfoBox>
								</FormControl>
								{productInfo.has_payment && (
									<FormControl>
										<StyledFormLabel tag="結款時間" />
										<InfoBox>{productInfo.payment_date}</InfoBox>
									</FormControl>
								)}
							</Stack>
						</ModalBody>

						<ModalFooter justifyContent="center" mb="1em" mt="1em">
							<StyledButtonBox onClick={onClose} mr="1em">
								確定
							</StyledButtonBox>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	)
}
